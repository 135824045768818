import React from 'react';
import { NavLink } from 'react-router-dom';
const noAction = e => e.preventDefault();

export const AccordianFour = () => {
    return (
        <div className="col-lg-6 offset-lg-1">
            <div className="accordion accordion_four" id="accordion_four">
                <div className="accordion-single">
                    <div className="accordion-heading" id="accordion_four_heading1">
                        <h6 className="mb-0">
                            <NavLink to="/" className="bg-gray-light" data-toggle="collapse" data-target="#accordion_four_collapse1" aria-expanded="true" aria-controls="accordion_four_collapse1">
                                <i className="la la-cog"></i> Our Standards of Business Conduct
                            </NavLink>
                        </h6>
                    </div>
                    <div id="accordion_four_collapse1" className="collapse show" aria-labelledby="accordion_four_heading1" data-parent="#accordion_four">
                        <div className="accordion-contents">
                            <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                                ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
                        </div>
                    </div>
                </div>{/*<!-- Ends: .accordion-single -->*/}
                <div className="accordion-single">
                    <div className="accordion-heading" id="accordion_four_heading2">
                        <h6 className="mb-0">
                            <NavLink to="/" className="collapsed bg-gray-light" data-toggle="collapse" data-target="#accordion_four_collapse2" aria-expanded="false" aria-controls="accordion_four_collapse2">
                                <i className="la la-bar-chart"></i> Our Supplier Code of Conduct
                            </NavLink>
                        </h6>
                    </div>
                    <div id="accordion_four_collapse2" className="collapse" aria-labelledby="accordion_four_heading2" data-parent="#accordion_four">
                        <div className="accordion-contents">
                            <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                                ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
                        </div>
                    </div>{/*<!-- Ends: .collapse -->*/}
                </div>{/*<!-- Ends: .accordion-single -->*/}
                <div className="accordion-single">
                    <div className="accordion-heading" id="accordion_four_heading3">
                        <h6 className="mb-0">
                            <NavLink to="/" className="collapsed bg-gray-light" data-toggle="collapse" data-target="#accordion_four_collapse3" aria-expanded="false" aria-controls="accordion_four_collapse3">
                                <i className="la la-briefcase"></i> Our Business Conduct Office
                            </NavLink>
                        </h6>
                    </div>
                    <div id="accordion_four_collapse3" className="collapse" aria-labelledby="accordion_four_heading3" data-parent="#accordion_four">
                        <div className="accordion-contents">
                            <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                                ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
                        </div>
                    </div>{/*<!-- End: .collapse -->*/}
                </div>{/*<!-- Ends: .accordion-single -->*/}
                <div className="accordion-single">
                    <div className="accordion-heading" id="accordion_four_heading4">
                        <h6 className="mb-0">
                            <NavLink to="/" className="collapsed bg-gray-light" data-toggle="collapse" data-target="#accordion_four_collapse4" aria-expanded="false" aria-controls="accordion_four_collapse4">
                                <i className="la la-headphones"></i> Supporting Our People
                            </NavLink>
                        </h6>
                    </div>
                    <div id="accordion_four_collapse4" className="collapse" aria-labelledby="accordion_four_heading4" data-parent="#accordion_four">
                        <div className="accordion-contents">
                            <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                                ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
                        </div>
                    </div>{/*<!-- End: .collapse -->*/}
                </div>{/*<!-- Ends: .accordion-single -->*/}
            </div>{/*<!-- Ends: #accordion_four -->*/}
        </div>
    )
}

export const AccordianFour2nd = () => {
    return (

        <div className="accordion accordion_four" id="accordion_4_2">
          <div className="accordion-single">
            <div className="accordion-heading" id="accordion_4_2_heading1">
              <h6 className="mb-0">
                <NavLink to="/" onClick={noAction} className="bg-gray-light" data-toggle="collapse" data-target="#accordion_4_2_collapse1" aria-expanded="true" aria-controls="accordion_4_2_collapse1">
                  <i className="la la-cog" /> Our Standards of Business Conduct
                </NavLink>
              </h6>
            </div>
            <div id="accordion_4_2_collapse1" className="collapse show" aria-labelledby="accordion_4_2_heading1" data-parent="#accordion_4_2">
              <div className="accordion-contents">
                <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                  ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
              </div>
            </div>
          </div>{/* Ends: .accordion-single */}
          <div className="accordion-single">
            <div className="accordion-heading" id="accordion_4_2_heading2">
              <h6 className="mb-0">
                <NavLink to="/" onClick={noAction} className="collapsed bg-gray-light" data-toggle="collapse" data-target="#accordion_4_2_collapse2" aria-expanded="false" aria-controls="accordion_4_2_collapse2">
                  <i className="la la-bar-chart" /> Our Supplier Code of Conduct
                </NavLink>
              </h6>
            </div>
            <div id="accordion_4_2_collapse2" className="collapse" aria-labelledby="accordion_4_2_heading2" data-parent="#accordion_4_2">
              <div className="accordion-contents">
                <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                  ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
              </div>
            </div>{/* Ends: .collapse */}
          </div>{/* Ends: .accordion-single */}
          <div className="accordion-single">
            <div className="accordion-heading" id="accordion_4_2_heading3">
              <h6 className="mb-0">
                <NavLink to="/" onClick={noAction} className="collapsed bg-gray-light" data-toggle="collapse" data-target="#accordion_4_2_collapse3" aria-expanded="false" aria-controls="accordion_4_2_collapse3">
                  <i className="la la-briefcase" /> Our Business Conduct Office
                </NavLink>
              </h6>
            </div>
            <div id="accordion_4_2_collapse3" className="collapse" aria-labelledby="accordion_4_2_heading3" data-parent="#accordion_4_2">
              <div className="accordion-contents">
                <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                  ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
              </div>
            </div>{/* End: .collapse */}
          </div>{/* Ends: .accordion-single */}
          <div className="accordion-single">
            <div className="accordion-heading" id="accordion_4_2_heading4">
              <h6 className="mb-0">
                <NavLink to="/" onClick={noAction} className="collapsed bg-gray-light" data-toggle="collapse" data-target="#accordion_4_2_collapse4" aria-expanded="false" aria-controls="accordion_4_2_collapse4">
                  <i className="la la-headphones" /> Supporting Our People
                </NavLink>
              </h6>
            </div>
            <div id="accordion_4_2_collapse4" className="collapse" aria-labelledby="accordion_4_2_heading4" data-parent="#accordion_4_2">
              <div className="accordion-contents">
                <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                  ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
              </div>
            </div>{/* End: .collapse */}
          </div>{/* Ends: .accordion-single */}
        </div>
      )
}

export const AccordianOne = () => {
    return (
        <div className="accordion-styles accordion--one">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="accordion accordion_one" id="accordion_one">
                            <div className="accordion-single">
                                <div className="accordion-heading" id="headingOne">
                                    <h6 className="mb-0">
                                        <NavLink to="#" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Employee & Time Management System
                                        </NavLink>
                                    </h6>
                                </div>
                                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion_one">
                                    <div className="accordion-contents">
                                        <p>Do you struggle to manage employee's attendance and leaves? Dealing with time, attendance, leave management is made easy with our Employee & Time Management System.</p>
                                        <p>Tracking staff time and attendance is a major part of running a business. Employee & Time Management System supports variety of clocking in terminals to suit your company setting such as Biometric clocking, PC based clocking, and Mobile phone clocking. </p>
                                        <figure>
                                            <img src="./assets/img/c12-etms1.jpg" alt="" />
                                        </figure>
                                    </div>
                                </div>{/*<!-- Ends: .collapseOne -->*/}
                            </div>{/*<!-- Ends: .accordion-single -->*/}
                            {/*<div className="accordion-single">
                                <div className="accordion-heading" id="headingTwo">
                                    <h6 className="mb-0">
                                        <NavLink to="#" className="collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Digitalization and Regulation Drive Ratri Cloud Agenda
                                        </NavLink>
                                    </h6>
                                </div>
                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion_one">
                                    <div className="accordion-contents">
                                        <p>Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est
                                            etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr
                                            averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus.
                                            Sequitur mutatin onem consuetudium.</p>
                                    </div>
                                </div>
                            </div>*/}{/*<!-- Ends: .accordion-single -->*/}
                            {/*<div className="accordion-single">
                                <div className="accordion-heading" id="headingThree">
                                    <h6 className="mb-0">
                                        <NavLink to="#" className="collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Insurance IT Transformation – Enabling Digital Change
                                        </NavLink>
                                    </h6>
                                </div>
                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion_one">
                                    <div className="accordion-contents">
                                        <p>Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est
                                            etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr
                                            averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus.
                                            Sequitur mutatin onem consuetudium.</p>
                                    </div>
                                </div><!-- Ends: .collapseOne -->
                            </div>*/}{/*<!-- Ends: .accordion-single -->*/}
                            {/*<div className="accordion-single">
                                <div className="accordion-heading" id="headingFour">
                                    <h6 className="mb-0">
                                        <NavLink to="#" className="collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            The Power of an Omnichannel
                                        </NavLink>
                                    </h6>
                                </div>
                                <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion_one">
                                    <div className="accordion-contents">
                                        <p>Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est
                                            etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr
                                            averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus.
                                            Sequitur mutatin onem consuetudium.</p>
                                    </div>
                                </div><!-- Ends: .collapseOne -->
                            </div>*/}{/*<!-- Ends: .accordion-single -->*/}
                            {/*<div className="accordion-single">
                                <div className="accordion-heading" id="headingFive">
                                    <h6 className="mb-0">
                                        <NavLink to="#" className="collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            Insurance IT Transformation – Enabling Digital Change
                                        </NavLink>
                                    </h6>
                                </div>
                                <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion_one">
                                    <div className="accordion-contents">
                                        <p>Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est
                                            etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr
                                            averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus.
                                            Sequitur mutatin onem consuetudium.</p>
                                    </div>
                                </div><!-- Ends: .collapseOne -->
                            </div>*/}{/*<!-- Ends: .accordion-single -->*/}
                        </div>{/*<!-- Ends: #accordion_one -->*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export const AccordianTwo = () => {
    return (
        <div className="col-lg-7">
            <div className="accordion accordion_two" id="accordion_two">
                <div className="accordion-single">
                    <div className="accordion-heading" id="heading2_1">
                        <h6 className="mb-0">
                        <NavLink to="/" onClick={noAction} data-toggle="collapse" data-target="#collapse2_1" aria-expanded="true" aria-controls="collapse2_1">
                            1. What industries do you specialize in?
                        </NavLink>
                        </h6>
                    </div>
                    <div id="collapse2_1" className="collapse show" aria-labelledby="heading2_1" data-parent="#accordion_two">
                        <div className="accordion-contents">
                        <p>We work across the board in any industry, but here are examples of some to date: <b>hospitality, oil and gas, technology, financial, business services and engineering.</b></p>
                        </div>
                    </div>{/* Ends: .collapseOne */}
                    </div>{/* Ends: .accordion-single */}
                    <div className="accordion-single">
                    <div className="accordion-heading" id="heading2_2">
                        <h6 className="mb-0">
                        <NavLink to="/" onClick={noAction} className="collapsed" data-toggle="collapse" data-target="#collapse2_2" aria-expanded="false" aria-controls="collapse2_2">
                            2. In which countries do you do this business?
                        </NavLink>
                        </h6>
                    </div>
                    <div id="collapse2_2" className="collapse" aria-labelledby="heading2_2" data-parent="#accordion_two">
                        <div className="accordion-contents">
                        <p>For now we are concentrating in <b>Malaysia (East & West)</b> and planning to spread our wings to other countries soon.</p>
                        </div>
                    </div>{/* Ends: .collapseOne */}
                    </div>{/* Ends: .accordion-single */}
                    <div className="accordion-single">
                    <div className="accordion-heading" id="heading2_3">
                        <h6 className="mb-0">
                        <NavLink to="/" onClick={noAction} className="collapsed" data-toggle="collapse" data-target="#collapse2_3" aria-expanded="false" aria-controls="collapse2_3">
                            3. Are your products suitable for small and medium sized businesses?
                        </NavLink>
                        </h6>
                    </div>
                    <div id="collapse2_3" className="collapse" aria-labelledby="heading2_3" data-parent="#accordion_two">
                        <div className="accordion-contents">
                            <p><b>Yes.</b> We have products for all range of businesses especially small and medium sized businesses tailored to their needs.</p>
                        </div>
                    </div>{/* Ends: .collapse */}
                    </div>{/* Ends: .accordion-single */}
                    <div className="accordion-single">
                    {/*<div className="accordion-heading" id="heading2_4">
                        <h6 className="mb-0">
                        <NavLink to="/" onClick={noAction} className="collapsed" data-toggle="collapse" data-target="#collapse2_4" aria-expanded="false" aria-controls="collapse2_4">
                            4. What industries do you specialize in?
                        </NavLink>
                        </h6>
                    </div>
                    <div id="collapse2_4" className="collapse" aria-labelledby="heading2_4" data-parent="#accordion_two">
                        <div className="accordion-contents">
                        <p>Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est
                            etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr
                            averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus.
                            Sequitur mutatin onem consuetudium.</p>
                        </div>
                    </div>*/}{/* Ends: .collapse */}
                </div>{/* Ends: .accordion-single */}
            </div>{/* Ends: #accordion_two */}
        </div>
    )
}

export const AccordianThree = () => {
    return (
        <div className="accordion accordion_three" id="accordion_three">
          <div className="accordion-single">
            <div className="accordion-heading" id="accordion_three_heading1">
              <h6 className="mb-0">
                <NavLink to="/" onClick={noAction} data-toggle="collapse" data-target="#accordion_threecollapse1" aria-expanded="true" aria-controls="accordion_threecollapse1">
                  Management and Technology Consulting
                </NavLink>
              </h6>
            </div>
            <div id="accordion_threecollapse1" className="collapse show" aria-labelledby="accordion_three_heading1" data-parent="#accordion_three">
              <div className="accordion-contents">
                <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                  ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
              </div>
            </div>
          </div>{/* Ends: .accordion-single */}
          <div className="accordion-single">
            <div className="accordion-heading" id="accordion_three_heading2">
              <h6 className="mb-0">
                <NavLink to="/" onClick={noAction} className="collapsed " data-toggle="collapse" data-target="#accordion_threecollapse2" aria-expanded="false" aria-controls="accordion_threecollapse2">
                  Software Solutions
                </NavLink>
              </h6>
            </div>
            <div id="accordion_threecollapse2" className="collapse" aria-labelledby="accordion_three_heading2" data-parent="#accordion_three">
              <div className="accordion-contents">
                <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                  ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
              </div>
            </div>
          </div>{/* Ends: .accordion-single */}
          <div className="accordion-single">
            <div className="accordion-heading" id="accordion_three_heading3">
              <h6 className="mb-0">
                <NavLink to="/" onClick={noAction} className="collapsed " data-toggle="collapse" data-target="#accordion_threecollapse3" aria-expanded="false" aria-controls="accordion_threecollapse3">
                  Support Services
                </NavLink>
              </h6>
            </div>
            <div id="accordion_threecollapse3" className="collapse" aria-labelledby="accordion_three_heading3" data-parent="#accordion_three">
              <div className="accordion-contents">
                <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                  ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
              </div>
            </div>{/* Ends: .collapse */}
          </div>{/* Ends: .accordion-single */}
          <div className="accordion-single">
            <div className="accordion-heading" id="accordion_three_heading4">
              <h6 className="mb-0">
                <NavLink to="/" onClick={noAction} className="collapsed " data-toggle="collapse" data-target="#accordion_threecollapse4" aria-expanded="false" aria-controls="accordion_threecollapse4">
                  Friendly and Engaging Team
                </NavLink>
              </h6>
            </div>
            <div id="accordion_threecollapse4" className="collapse" aria-labelledby="accordion_three_heading4" data-parent="#accordion_three">
              <div className="accordion-contents">
                <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                  ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
              </div>
            </div>{/* Ends: .collapse */}
          </div>{/* Ends: .accordion-single */}
        </div>
      );
}

export const AccordianThree2nd = () => {
    return (

        <div className="accordion accordion_three" id="accordion_3_2">
          <div className="accordion-single">
            <div className="accordion-heading" id="accordion_3_2_heading1">
              <h6 className="mb-0">
                <NavLink to="/" onClick={noAction} className="bg-gray-light" data-toggle="collapse" data-target="#accordion_3_2collapse1" aria-expanded="true" aria-controls="accordion_3_2collapse1">
                  Management and Technology Consulting
                </NavLink>
              </h6>
            </div>
            <div id="accordion_3_2collapse1" className="collapse show" aria-labelledby="accordion_3_2_heading1" data-parent="#accordion_3_2">
              <div className="accordion-contents">
                <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                  ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
              </div>
            </div>
          </div>{/* Ends: .accordion-single */}
          <div className="accordion-single">
            <div className="accordion-heading" id="accordion_3_2_heading2">
              <h6 className="mb-0">
                <NavLink to="/" onClick={noAction} className="collapsed bg-gray-light" data-toggle="collapse" data-target="#accordion_3_2collapse2" aria-expanded="false" aria-controls="accordion_3_2collapse2">
                  Software Solutions
                </NavLink>
              </h6>
            </div>
            <div id="accordion_3_2collapse2" className="collapse" aria-labelledby="accordion_3_2_heading2" data-parent="#accordion_3_2">
              <div className="accordion-contents">
                <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                  ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
              </div>
            </div>
          </div>{/* Ends: .accordion-single */}
          <div className="accordion-single">
            <div className="accordion-heading" id="accordion_3_2_heading3">
              <h6 className="mb-0">
                <NavLink to="/" onClick={noAction} className="collapsed bg-gray-light" data-toggle="collapse" data-target="#accordion_3_2collapse3" aria-expanded="false" aria-controls="accordion_3_2collapse3">
                  Support Services
                </NavLink>
              </h6>
            </div>
            <div id="accordion_3_2collapse3" className="collapse" aria-labelledby="accordion_3_2_heading3" data-parent="#accordion_3_2">
              <div className="accordion-contents">
                <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                  ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
              </div>
            </div>{/* Ends: .collapse */}
          </div>{/* Ends: .accordion-single */}
          <div className="accordion-single">
            <div className="accordion-heading" id="accordion_3_2_heading4">
              <h6 className="mb-0">
                <NavLink to="/" onClick={noAction} className="collapsed bg-gray-light" data-toggle="collapse" data-target="#accordion_3_2collapse4" aria-expanded="false" aria-controls="accordion_3_2collapse4">
                  Friendly and Engaging Team
                </NavLink>
              </h6>
            </div>
            <div id="accordion_3_2collapse4" className="collapse" aria-labelledby="accordion_3_2_heading4" data-parent="#accordion_3_2">
              <div className="accordion-contents">
                <p>Investig ationes demons trave runt letoes legere lius quod waunt saepius claritas Investig
                  ationes demon trave rungt saepius clari kestas Investig avaina ationes demon.</p>
              </div>
            </div>{/* Ends: .collapse */}
          </div>{/* Ends: .accordion-single */}
        </div>
      )
}

export const AccordianPriceOILD = () => {
    return (
        <div className="accordion-styles accordion--one">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="accordion accordion_one" id="accordion_one">
                            <div className="accordion-single">
                                <div className="accordion-heading" id="headingOne">
                                    <h6 className="mb-0">
                                        <NavLink to="#" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Refund Policy
                                        </NavLink>
                                    </h6>
                                </div>
                                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion_one">
                                    <div className="accordion-contents">
                                        <p>Thanks for subscribing to our services at moonosys.com operated by Moonosys Sdn.Bhd.</p>
                                        <p>We offer a full money-back guarantee for all subscription made on our website. If you are not satisfied with the product that you have subscribed from us, you can get your money back no questions asked. </p>
                                        <p>You are eligible for a full reimbursement within 7 calendar days of your subscription.</p>
                                        <p>After the 7th-day period you will no longer be eligible and won't be able to receive a refund. We encourage our customers to try the services in the first one (1) week after the subscription to ensure it fits their needs.</p>
                                        <p>If you have any additional questions or would like to request a refund, feel free to contact us. </p>
                                        <figure>
                                            {/*<img src="./assets/img/c12-etms1.jpg" alt="" />*/}
                                        </figure>
                                    </div>
                                </div>{/*<!-- Ends: .collapseOne -->*/}
                            </div>{/*<!-- Ends: .accordion-single -->*/}
                            <div className="accordion-single">
                                <div className="accordion-heading" id="headingTwo">
                                    <h6 className="mb-0">
                                        <NavLink to="#" className="collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Personal Data Protection Notice
                                        </NavLink>
                                    </h6>
                                </div>
                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion_one">
                                    <div className="accordion-contents">
                                        <p>The Personal Data Protection Act 2010 ("PDPA") which regulates the processing of personal data in commercial transactions has come into force on the 15th day of November 2013. In line with the PDPA, this Personal Data Protection Notice ("Notice") is to inform you of how we collect and process your hotel guest's data in the course of our appointment and engagement by you in hotel software services as well as your rights under the PDPA. We treat and view your hotel guest's Personal Data seriously. </p>
                                        <p><b>1. COLLECTION OF PERSONAL DATA</b><br />
                                            In compliance with the Personal Data Protection Act 2010, we wish to give you notice that your hotel(s) guest's Personal Data will be collected, recorded, held, stored and processed by us, Moonosys Sdn Bhd, including our employees, to be utilized and dealt with in accordance and in compliance with the Personal Data Protection Act 2010. </p>

                                        <p><b>2. SOURCE OF PERSONAL DATA</b><br />
                                            We collect Personal Data through RATRI PMS which include but are not limited to: -
                                            <br />a. Information or comments you and your staff have provided to us by entering your hotel guest's information using RATRI PMS. </p>

                                        <p><b>3. NATURE OF PERSONAL DATA</b><br />
                                            The Personal Data processed by RATRI may include, but not is limited to, information concerning your hotel's guest name, age, identity card number, passport number, correspondence address, gender, date of birth, race, contact information and nationality. </p>

                                        <p><b>4. PURPOSE OF COLLECTING PERSONAL DATA</b><br />
                                            The collection and processing of your hotel(s) guest's Personal Data is done for, amongst others, the following purposes:
                                            <br />a. Creating bills including Guest Folio, Booking, Receipt & Tax Invoice from the RATRI Property Management System (PMS)
                                            <br /> b. For your hotel record keeping and statistic for Tourism Malaysia, which required by you to submit to them upon request.
                                            <br /> c. Provide your hotel guest's data to the relevant authority including compliance with any laws and/or orders of Court and disclosure under the requirements of any applicable law.
                                            <br /> d. Conducting background checks of your hotel guest's as may be necessary or appropriate.
                                            <br /> e. You may also save or download your hotel guest's data to your personal computer/laptop or tablet, which beyond our control and within this Notice. </p>


                                        <p><b>5. DISCLOSURE</b><br />
                                            We shall endeavour to safeguard and maintain the confidentiality of your hotel guest's Personal Data. This is further fortified by legal profession privilege and Section 126 of the Evidence Act 1950 which prohibit us from disclosing any of your hotel guest's Personal Data except with your express consent.
                                            <br />Kindly take note that you may require to disclose certain hotel guest's Personal Data to third parties, including but not limited to the following: -
                                            <br /> a. Any person to whom we are compelled or required to do so under the law or in relation to any order or judgment of a court, or in response to a competent or government agency;
                                            <br /> b. Any party involved in or related to a legal proceeding (or prospective legal proceeding), for purposes of the legal proceedings;
                                            <br /> c. Government agencies, statutory authorities, industry regulators and other similar authorities;
                                            <br /> d. Our auditors, consultants, accountants, or other financial or professional advisers;
                                            <br /> e. Your contractors or third-party service or product providers as may be necessary or appropriate; and
                                            <br /> f. As otherwise permitted under Malaysian law, including without limitation, the Personal Data Protection Act 2010.
                                            <br />In this regard, you as the hotel owner shall endeavour to take all necessary steps to only disclose relevant Personal Data of your hotel guest to the aforesaid said third parties. Further, kindly note that this disclose of your hotel guest's Personal Data to said third parties is under your supervision and decision and Moonosys Sdn Bhd will not be responsible of any mishandling of your hotel guest's Personal Data, which beyond our control. </p>

                                        <p><b>6. SAFEGUARDS</b><br />
                                            We shall keep and process your hotel guest's data in a secure manner. We endeavour, where practicable, to implement the appropriate administrative and security safeguards and procedures in accordance with the applicable laws and regulations to prevent any unauthorized or unlawful processing of your hotel guest's Personal Data and any accidental loss or destruction of, or damage to your hotel guest's Personal Data. Whenever you as the hotel owner provide any of your hotel guest's Personal Data to any Third Parties you shall take reasonable steps to ensure that these parties are contractually bound not to use your hotel guest's personal information for any reason other than to provide the service they are contracted to provide and to adequately safeguard your hotel guest's personal information. </p>

                                        <p><b>7. YOUR RIGHTS OF ACCESS AND CORRECTION</b><br />
                                            We will retain your hotel guest's Personal Data for the period your subscription of our services using RATRI PMS to fulfil the purposes outlined in this Personal Data Protection Notice unless a longer retention period is permitted or required by law and thereafter the Personal Data shall only be retained for statistics and record keeping purposes. We endeavour to take reasonable precautions to ensure that the hotel guest's Personal Data that we collect and/or process is accurately reflected in our RATRI PMS system in accordance with the details provided by you. Therefore, the accuracy of the Personal Data depends to a large extent on the information you (hotel owner and its staffs) provide.  Any change to the information contained in your hotel guest's Personal Data entered in RATRI PMS entirely depends on you as the hotel owner or your staffs and not within the control of Moonosys Sdn Bhd. After the end of subscription of service with Moonosys Sdn Bhd, we shall provide the hotel their guest's folio, receipt and tax invoice export from RATRI PMS to PDF format and hotel guest's information inclusive of name, ic number, passport number, address, number of times stayed and contact number in CSV format save into hotel owner's computer. RATRI PMS will no longer provide any access to the system after the end of subscription or support. In the event you would like to make a request to have the hotel guest's Personal Data as per defined above after maximum of 1 year from the date of subscription cancelation, please send an official letter address to below contact to retrieve them.
                                            <br />Post: Moonosys Sdn. Bhd.
                                            <br />1-23-5, Menara Bangkok Bank, Berjaya Central Park, 50450 Kuala Lumpur.
                                            <br />Contact: Phone Tel: - 03- 9212 2805
                                            <br />Email: support@moonosys.com</p>

                                        <p><b>8. MISCELLANEOUS</b><br />
                                            Nothing in this Personal Data Protection Notice shall limit the rights of Moonosys Sdn Bhd under the Personal Data Protection Act 2010. In engaging us and/or continuing to engage us and/or having engaged us and/or communicating with us with the view to engaging us, you will be deemed to have consented to allow us to collect, record, hold, store, process and deal with your hotel guest's Personal Data until and unless we receive written notice to the contrary from you. We shall have the right to modify, update or amend the terms of this Personal Data Protection Notice at any time by placing the updated Personal Data Protection Notice on RATRI PMS and website notice. Your continued communication with us and/or your continued use of our RATRI PMS after publication of such modifications, updates or amendments to this Personal Data Protection Notice, with or without notification, constitutes binding acceptance of such modifications, updates or amendments.
                                            If you have any questions or concerns regarding your privacy while using this RATRI PMS, please direct them to support@moonosys.com</p>

                                    </div>
                                </div>
                            </div>{/*<!-- Ends: .accordion-single -->*/}
                            <div className="accordion-single">
                                <div className="accordion-heading" id="headingThree">
                                    <h6 className="mb-0">
                                        <NavLink to="#" className="collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Terms of Service and Privacy
                                        </NavLink>
                                    </h6>
                                </div>
                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion_one">
                                    <div className="accordion-contents">
                                        <p><b>POLICY</b><br /></p>
                                        <p><b>01. PREMISE</b><br />
                                            With the following Contract, as our customer, you agree that the subscription and the use of our technology services implies the acceptance end the adhesion to these Terms and Conditions General Terms and declares to know, thanks to the information material provided by us; the service offered by Moonosys in its entirety and to find it appropriate to its needs.</p>

                                        <p><b>02. CONTRACT PROCESS</b><br />
                                            The services of Moonosys are delivered via the internet, so it is your responsibility to equip yourself properly to use them in a functional way. Accepting our terms, you certify that you have the legal right, the appropriate instrumentation and the necessary authorization to fully satisfy the conditions
                                            described in this contract. This contract is valid for the duration of the service that Moonosys will deliver. The term of service determines the termination of the contract, while the renewal of services determine the recovery of validity, except, however, the right of Moonosys to prevent the periodic renewal, by notice to the client 30 days before the expiry of the contract. Both Party may terminate the contract at any time, informing the other Party and interrupting the service, without any obligation to confirm the receipt via e-mail or trough record delivery letter. Reimbursement or
                                            compensation is not provided if you interrupt the service before the expiry of the contract. </p>

                                        <p><b>03. CREATION OF THE ACCOUNT</b><br />
                                            In order to access the services offered by Moonosys is necessary to create an account. This process takes place with the definition personal and private of your access codes with which independently administer your account, in specific password and user name. You have the opportunity to modify yours access credentials at any time. Each password is strictly personal and under your direct responsibility. Accepting this agreement, you claim to be the only responsible for the data entered in the control panel regarding the configuration of your Structure. The control panel is a web interface that allows you to configure the services subscribed and to access and monitor Your contracts and Your invoices. Through the control panel you can perform any operation relative to the service at any
                                            time, in a transparent and simple way. As a customer, you declare that the entries data are real and corresponding to Your identity and that you will keep them updated throughout the duration of the
                                            contract. The identity and all the information’s that you declare to associated to your account are necessary for the validation and activation of our services for the entire duration of the contract.
                                            Moonosys and the customer respectively undertake to maintain the confidentiality of the data and the information’s obtained in connection to the activities for the execution of this contract and to the activities for the execution of the service. The account does not have an established expiry, can be interrupted at any time, in this case, the cancellation of all your personal data. The cancellation of your account automatically implies the closing of all the contracts you have signed and the resulting loss of the services. If our service provided is free of charge free model, Moonosys reserves the right to terminate the validity of the contract and of the service at any time. </p>
                                        <p><b>04. PAYMENTS</b><br />
                                            The payment methods accepted by Moonosys are:
                                            <br />a. Payment Gateway
                                            <br />b. Bank transfer.
                                            <br />When the final payment is received, Moonosys will send you a confirmation email and will activate the services that You have chosen and paid. The Invoices of payment sent by you to Moonosys are regularly issued at the time of the confirmation of payment and they can be found online from your control panel. Once you have created your account, and your data, your movements, your invoices and your contracts will be available directly via Web, (after authentication) through the "Control Panel" on the site: http://moonosys.com/</p>

                                        <p><b>05. UPDATING CONTRACTS</b><br />
                                            You will be able to view the contracts updated directly from your online panel of control when Moonosys makes available a newer one. You agree that if after a new publication or sending of a notification concerning the changes made to this contract, you will continue to use our Services, we will consider accepted the new conditions proposed. The historic of the updates of the contracts is expressed by the number of the version included in all contracts and, the texts of all the contracts offered in Moonosys are freely available on the site (after authentication): http://moonosys.com/</p>

                                        <p><b>06. CHANGES TO THE SITE AND SERVICES</b><br />
                                            Moonosys reserves the right to edit any section and/or content of the site as well as the terms and conditions of access to its facilities, all in a view to improve and update the services to offer to its customers. If some changes will be committed, Moonosys will inform you about it through email or by posting, in a visible way, on its website. </p>

                                        <p><b>07. COMMUNICATIONS BETWEEN THE PARTS</b><br />
                                            The customer agrees that the notification and the communications concerning all the online services are carried out by Moonosys in electronic format (electronic mail) and through web service; recognizes the force and expressly renounces even now to acknowledge the contents of the declarations sent or received in electronic format. Moonosys , therefore, will use the email, that you have personally issued at the time of the creation of personal data and the account, for all the communications and notifications that will succeed, providing a clear and timely relationship, with
                                            specific reference also to the communications regarding withdrawal and/or denial to the customer to benefit of a further service. It is important, that your email address is up to date and running. You must inform Moonosys in case you are unable to use your mail service, to avoid misunderstandings. All the mail sent to Your address are otherwise considered received and read on the 5 following days.
                                            You agree to be the only responsible in the case of failure of reading an e-mail sent regularly to you in these terms. From your control panel, you can contact us directly by e-mail, getting a quickly answer in accordance to your needs. </p>


                                        <p><b>08. CHARGES AND RIGHTS OF MOONOSYS</b><br />
                                            As a provider of technology services, Moonosys ensures that: Do everything in its power to provide continuous access to the service, in accordance with the technical needs of maintenance and in case of major force. Suspend the total or partial use of the service only for a short period, engage in rapid recovery of it and, when is possible, advise you previously by e-mail to allow eventual agreements. The reasons, that may lead to a suspension of the service concerning technical interventions needed for a temporary malfunction, are routine maintenance, technical improvements in relation to the state of the art. Deliver the services with the utmost care and efficiency, to ensure high levels of
                                            technology in relation to the state art. Offer the maximum transparency so that the quality levels of the services and resources can be monitored and verified in accordance with their limitations and
                                            the technical characteristics of each service. Act quickly in the event of a technical failure to restore service to full systems. </p>

                                        <p><b>09. LIABILITY</b><br />
                                            Moonosys is not responsible in the event of delays, malfunction, suspension and/ or termination of access to services caused by: Unforeseeable circumstances or major force, as national and/or international emergencies, fires, explosions, earthquakes, volcanic eruptions, landslides, cyclones, storms, floods, hurricanes, avalanches, war, civil strife, riots, strikes and any other cause unexpected and exceptional that prevents to provide the agreed service. Manumission or actions on the services or equipment’s made by you or by anyone not authorized by Moonosys. Technical problems related to the Internet independent from Moonosys, as a physical damage or traffic congestion. Incorrect use of the services by you or anyone else. Malfunction or inadequacy of the connecting equipment and the base technology equipment that You have. In case that a malfunction of the services, have caused you an economic damage, Moonosys is required to compensate only if your problem has been promptly notified, within five days after the determination of the damage. In any case, Moonosys will compensate, if there are a real condition, with an amount that does not exceed the cost of the service whose failure was due to damage, even if the cost of the damage is superior. If our service provided is for free model, its use is at your own responsibility. The free model does not give you the right to have any warranty or protection or assistance from Moonosys. In this case Moonosys is not responsible for any compensation in any case. </p>

                                        <p><b>10. CHARGES AND RIGHTS OF THE CUSTOMER</b><br />
                                            Adhering to the following contract You agree to comply all the rules required by Moonosys for an adequate use of the all services offered, for the entire duration of the contract. Also ensures to respect the following conditions: Respect and adherence to the local and national laws in force at the time of signing, specifically to the Italian ones. Respect and acceptance of the technical limitations of our service. To trust the data (logs) produced by our system, that maintain track and history of
                                            the operations performed by you. Raise Moonosys from any contentious related to the use of our services that can occur with any third part. Retain Moonosys not responsible for any break of your equipment as, for example, your personal computer, your internet connection etc., even if it happens during the use of Moonosys. Your personal data, issued to Moonosys, are true and You will have the charge to keep confidential your account name and password, which are under your direct responsibility. Verify periodically the accuracy and the consistency of the data on your account, the proper functioning of the service provided to you and inform Moonosys of any faults that may occur.
                                            Do not use or allow the use of our services against morality and public order, in order to disturb public and/or private, of causing offense, damage directly and/or indirectly to anyone. Abstention in illegal activities as spamming, phishing, cracking and/or any other unauthorized use and/or deflected Internet, going to harm Moonosys, its image and/or its operations. Consider under your responsibility the entry and use of images and/or content that have copyright, in any area of our service. Abstention from the use of images and/or content protected by copyright or that dealing themes against the ethic of Moonosys, as racism, xenophobia, paedophilia child, against public morals, against human rights, in favour of terrorism, public order etc. Moonosys may terminate the use of your service and recede the contract, to the senses and for the effects of art. 1456 of the Civil Code, with a simple communication by e-mail, if a violation of the above paragraphs happens, without prior notice and without give you a chance to receive a formal act. You agree, as a responsible, to quickly cancel the illegal content or everything that affect the ethical principles of Moonosys, principles that are written on the site: https://Moonosys.com and that you, as our customer, ensures to follow and respect. Moonosys is not responsible for your configurations and content, as images, that you will decide to use; therefore, it will not intervene to modify not even an
                                            individual parts. So, the intervention of Moonosys will be only for the deletion from Your service. </p>


                                        <p><b>11. INTELLECTUAL PROPERTY</b><br />
                                            Is forbidden by the law to copy, transcribe, reproduce, add or modify the software, the contracts, and the related documentation, the logo and the name of Moonosys and every distinctive sign specified and used on our website, after our permission. The only party that do not follow the limits described above, are those clearly published under the GPL (General Public License). Anyone who does not follow the directives on copyright are punishable by law. </p>

                                        <p><b>12. COMPETENT COURT</b><br />
                                            This agreement shall be construed and interpreted in accordance with the Italian law. For anything not expressly covered by this contract, the provision of the Civil Code will be applied. Any dispute relating to the interpretation and/or execution of this contract shall be settled exclusively by the Court. </p>

                                        <p><b>13. PROCESSING OF PERSONAL DATA</b><br /></p>
                                        <p><b>a. PERSONAL DATA RELATED TO THE CONTRACT</b><br />
                                            The Moonosys’s services are designed to be offered to companies in the hospitality sector, regardless of their property type (hotel, bed & breakfast, hostel, ...) and of their business name. It is also included the use of Moonosys’s services by individual citizens, identified as individuals. The data processed for the management of the contract are therefore those of the hospitality Properties and Customers who sign the services. These data are excluded from the application of the Personal Data Protection Act 2010 ("PDPA"), which aims to safeguard the dignity, reputation and tranquillity of citizens, protecting them from unnecessary and potentially dangerous treatments of their data. It doesn’t mean that Moonosys doesn’t put in place adequate security measures to guarantee the security of the data of its Clients conferred upon the stipulation of contracts and during the maintenance of the same. </p>

                                        <p><b>b. PERSONAL DATAS WICH MOONOSYS IS A CONTROLLER</b><br />
                                            Moonosys is the owner of the data entered when the account was created specifically for the relevant Personal information entered when the contract was signed or entered into the contact form. The attached document https://Moonosys.com/…......  represents the updated description of the nature of the data and their processing. </p>


                                        <p><b>14. PERSONAL DATAS WHICH THE CUSTOMER IS THE CONTROLLER (AND MOONOSYS THE PROCESSOR) </b><br />
                                            Some services offered by Moonosys, in particular PMS, the booking engine service, and POS, the hotel management system, allow the Guest Accommodation Facilities to manage the data of their guests or their staff through the functionalities, standard or optional, offered by the applications. Each Accommodation facility (Customer) maintains ownership of the data in question and recognizes Moonosys as the external responsible in charge of the treatment according to the attached
                                            appointment scheme available at the URL https://Moonosys.com
                                            . </p>

                                    </div>
                                </div>{/*<!-- Ends: .collapseOne -->*/}
                            </div>{/*<!-- Ends: .accordion-single -->*/}
                            {/*<div className="accordion-single">
                                <div className="accordion-heading" id="headingFour">
                                    <h6 className="mb-0">
                                        <NavLink to="#" className="collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            The Power of an Omnichannel
                                        </NavLink>
                                    </h6>
                                </div>
                                <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordion_one">
                                    <div className="accordion-contents">
                                        <p>Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est
                                            etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr
                                            averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus.
                                            Sequitur mutatin onem consuetudium.</p>
                                    </div>
                                </div><!-- Ends: .collapseOne -->
                            </div>*/}{/*<!-- Ends: .accordion-single -->*/}
                            {/*<div className="accordion-single">
                                <div className="accordion-heading" id="headingFive">
                                    <h6 className="mb-0">
                                        <NavLink to="#" className="collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            Insurance IT Transformation – Enabling Digital Change
                                        </NavLink>
                                    </h6>
                                </div>
                                <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion_one">
                                    <div className="accordion-contents">
                                        <p>Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est
                                            etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr
                                            averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus.
                                            Sequitur mutatin onem consuetudium.</p>
                                    </div>
                                </div><!-- Ends: .collapseOne -->
                            </div>*/}{/*<!-- Ends: .accordion-single -->*/}
                        </div>{/*<!-- Ends: #accordion_one -->*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export const AccordianPrice = () => {
    return (
        <div className="col-lg-7">
            <div className="accordion accordion_two" id="accordion_two">
                <div className="accordion-single">
                    <div className="accordion-heading" id="heading2_1">
                        <h6 className="mb-0">
                            <NavLink to="/" onClick={noAction} data-toggle="collapse" data-target="#collapse2_1" aria-expanded="true" aria-controls="collapse2_1">
                                Refund Policy
                            </NavLink>
                        </h6>
                    </div>
                    <div id="collapse2_1" className="collapse show" aria-labelledby="heading2_1" data-parent="#accordion_two">
                        <div className="accordion-contents">
                            <p>Thanks for subscribing to our services at moonosys.com operated by Moonosys Sdn.Bhd.</p>
                            <p>We offer a full money-back guarantee for all subscription made on our website. If you are not satisfied with the product that you have subscribed from us, you can get your money back no questions asked. </p>
                            <p>You are eligible for a full reimbursement within 7 calendar days of your subscription.</p>
                            <p>After the 7th-day period you will no longer be eligible and won't be able to receive a refund. We encourage our customers to try the services in the first one (1) week after the subscription to ensure it fits their needs.</p>
                            <p>If you have any additional questions or would like to request a refund, feel free to contact us. </p>
                        </div>
                    </div>{/* Ends: .collapseOne */}
                </div>{/* Ends: .accordion-single */}
                <div className="accordion-single">
                    <div className="accordion-heading" id="heading2_2">
                        <h6 className="mb-0">
                            <NavLink to="/" onClick={noAction} className="collapsed" data-toggle="collapse" data-target="#collapse2_2" aria-expanded="false" aria-controls="collapse2_2">
                                Personal Data Protection Notice
                            </NavLink>
                        </h6>
                    </div>
                    <div id="collapse2_2" className="collapse" aria-labelledby="heading2_2" data-parent="#accordion_two">
                        <div className="accordion-contents">
                            <p>The Personal Data Protection Act 2010 ("PDPA") which regulates the processing of personal data in commercial transactions has come into force on the 15th day of November 2013. In line with the PDPA, this Personal Data Protection Notice ("Notice") is to inform you of how we collect and process your hotel guest's data in the course of our appointment and engagement by you in hotel software services as well as your rights under the PDPA. We treat and view your hotel guest's Personal Data seriously. </p>
                            <p><b>1. COLLECTION OF PERSONAL DATA</b><br />
                                In compliance with the Personal Data Protection Act 2010, we wish to give you notice that your hotel(s) guest's Personal Data will be collected, recorded, held, stored and processed by us, Moonosys Sdn Bhd, including our employees, to be utilized and dealt with in accordance and in compliance with the Personal Data Protection Act 2010. </p>

                            <p><b>2. SOURCE OF PERSONAL DATA</b><br />
                                We collect Personal Data through RATRI PMS which include but are not limited to: -
                                <br />a. Information or comments you and your staff have provided to us by entering your hotel guest's information using RATRI PMS. </p>

                            <p><b>3. NATURE OF PERSONAL DATA</b><br />
                                The Personal Data processed by RATRI may include, but not is limited to, information concerning your hotel's guest name, age, identity card number, passport number, correspondence address, gender, date of birth, race, contact information and nationality. </p>

                            <p><b>4. PURPOSE OF COLLECTING PERSONAL DATA</b><br />
                                The collection and processing of your hotel(s) guest's Personal Data is done for, amongst others, the following purposes:
                                <br />a. Creating bills including Guest Folio, Booking, Receipt & Tax Invoice from the RATRI Property Management System (PMS)
                                <br /> b. For your hotel record keeping and statistic for Tourism Malaysia, which required by you to submit to them upon request.
                                <br /> c. Provide your hotel guest's data to the relevant authority including compliance with any laws and/or orders of Court and disclosure under the requirements of any applicable law.
                                <br /> d. Conducting background checks of your hotel guest's as may be necessary or appropriate.
                                <br /> e. You may also save or download your hotel guest's data to your personal computer/laptop or tablet, which beyond our control and within this Notice. </p>


                            <p><b>5. DISCLOSURE</b><br />
                                We shall endeavour to safeguard and maintain the confidentiality of your hotel guest's Personal Data. This is further fortified by legal profession privilege and Section 126 of the Evidence Act 1950 which prohibit us from disclosing any of your hotel guest's Personal Data except with your express consent.
                                <br />Kindly take note that you may require to disclose certain hotel guest's Personal Data to third parties, including but not limited to the following: -
                                <br /> a. Any person to whom we are compelled or required to do so under the law or in relation to any order or judgment of a court, or in response to a competent or government agency;
                                <br /> b. Any party involved in or related to a legal proceeding (or prospective legal proceeding), for purposes of the legal proceedings;
                                <br /> c. Government agencies, statutory authorities, industry regulators and other similar authorities;
                                <br /> d. Our auditors, consultants, accountants, or other financial or professional advisers;
                                <br /> e. Your contractors or third-party service or product providers as may be necessary or appropriate; and
                                <br /> f. As otherwise permitted under Malaysian law, including without limitation, the Personal Data Protection Act 2010.
                                <br />In this regard, you as the hotel owner shall endeavour to take all necessary steps to only disclose relevant Personal Data of your hotel guest to the aforesaid said third parties. Further, kindly note that this disclose of your hotel guest's Personal Data to said third parties is under your supervision and decision and Moonosys Sdn Bhd will not be responsible of any mishandling of your hotel guest's Personal Data, which beyond our control. </p>

                            <p><b>6. SAFEGUARDS</b><br />
                                We shall keep and process your hotel guest's data in a secure manner. We endeavour, where practicable, to implement the appropriate administrative and security safeguards and procedures in accordance with the applicable laws and regulations to prevent any unauthorized or unlawful processing of your hotel guest's Personal Data and any accidental loss or destruction of, or damage to your hotel guest's Personal Data. Whenever you as the hotel owner provide any of your hotel guest's Personal Data to any Third Parties you shall take reasonable steps to ensure that these parties are contractually bound not to use your hotel guest's personal information for any reason other than to provide the service they are contracted to provide and to adequately safeguard your hotel guest's personal information. </p>

                            <p><b>7. YOUR RIGHTS OF ACCESS AND CORRECTION</b><br />
                                We will retain your hotel guest's Personal Data for the period your subscription of our services using RATRI PMS to fulfil the purposes outlined in this Personal Data Protection Notice unless a longer retention period is permitted or required by law and thereafter the Personal Data shall only be retained for statistics and record keeping purposes. We endeavour to take reasonable precautions to ensure that the hotel guest's Personal Data that we collect and/or process is accurately reflected in our RATRI PMS system in accordance with the details provided by you. Therefore, the accuracy of the Personal Data depends to a large extent on the information you (hotel owner and its staffs) provide.  Any change to the information contained in your hotel guest's Personal Data entered in RATRI PMS entirely depends on you as the hotel owner or your staffs and not within the control of Moonosys Sdn Bhd. After the end of subscription of service with Moonosys Sdn Bhd, we shall provide the hotel their guest's folio, receipt and tax invoice export from RATRI PMS to PDF format and hotel guest's information inclusive of name, ic number, passport number, address, number of times stayed and contact number in CSV format save into hotel owner's computer. RATRI PMS will no longer provide any access to the system after the end of subscription or support. In the event you would like to make a request to have the hotel guest's Personal Data as per defined above after maximum of 1 year from the date of subscription cancelation, please send an official letter address to below contact to retrieve them.
                                <br />Post: Moonosys Sdn. Bhd.
                                <br />1-23-5, Menara Bangkok Bank, Berjaya Central Park, 50450 Kuala Lumpur.
                                <br />Contact: Phone Tel: - 03- 9212 2805
                                <br />Email: support@moonosys.com</p>

                            <p><b>8. MISCELLANEOUS</b><br />
                                Nothing in this Personal Data Protection Notice shall limit the rights of Moonosys Sdn Bhd under the Personal Data Protection Act 2010. In engaging us and/or continuing to engage us and/or having engaged us and/or communicating with us with the view to engaging us, you will be deemed to have consented to allow us to collect, record, hold, store, process and deal with your hotel guest's Personal Data until and unless we receive written notice to the contrary from you. We shall have the right to modify, update or amend the terms of this Personal Data Protection Notice at any time by placing the updated Personal Data Protection Notice on RATRI PMS and website notice. Your continued communication with us and/or your continued use of our RATRI PMS after publication of such modifications, updates or amendments to this Personal Data Protection Notice, with or without notification, constitutes binding acceptance of such modifications, updates or amendments.
                                If you have any questions or concerns regarding your privacy while using this RATRI PMS, please direct them to support@moonosys.com</p>

                        </div>
                    </div>{/* Ends: .collapseOne */}
                </div>{/* Ends: .accordion-single */}
                <div className="accordion-single">
                    <div className="accordion-heading" id="heading2_3">
                        <h6 className="mb-0">
                            <NavLink to="/" onClick={noAction} className="collapsed" data-toggle="collapse" data-target="#collapse2_3" aria-expanded="false" aria-controls="collapse2_3">
                                Terms of Service and Privacy
                            </NavLink>
                        </h6>
                    </div>
                    <div id="collapse2_3" className="collapse" aria-labelledby="heading2_3" data-parent="#accordion_two">
                        <div className="accordion-contents">
                            <p><b>POLICY</b><br /></p>
                            <p><b>01. PREMISE</b><br />
                                With the following Contract, as our customer, you agree that the subscription and the use of our technology services implies the acceptance end the adhesion to these Terms and Conditions General Terms and declares to know, thanks to the information material provided by us; the service offered by Moonosys in its entirety and to find it appropriate to its needs.</p>

                            <p><b>02. CONTRACT PROCESS</b><br />
                                The services of Moonosys are delivered via the internet, so it is your responsibility to equip yourself properly to use them in a functional way. Accepting our terms, you certify that you have the legal right, the appropriate instrumentation and the necessary authorization to fully satisfy the conditions
                                described in this contract. This contract is valid for the duration of the service that Moonosys will deliver. The term of service determines the termination of the contract, while the renewal of services determine the recovery of validity, except, however, the right of Moonosys to prevent the periodic renewal, by notice to the client 30 days before the expiry of the contract. Both Party may terminate the contract at any time, informing the other Party and interrupting the service, without any obligation to confirm the receipt via e-mail or trough record delivery letter. Reimbursement or
                                compensation is not provided if you interrupt the service before the expiry of the contract. </p>

                            <p><b>03. CREATION OF THE ACCOUNT</b><br />
                                In order to access the services offered by Moonosys is necessary to create an account. This process takes place with the definition personal and private of your access codes with which independently administer your account, in specific password and user name. You have the opportunity to modify yours access credentials at any time. Each password is strictly personal and under your direct responsibility. Accepting this agreement, you claim to be the only responsible for the data entered in the control panel regarding the configuration of your Structure. The control panel is a web interface that allows you to configure the services subscribed and to access and monitor Your contracts and Your invoices. Through the control panel you can perform any operation relative to the service at any
                                time, in a transparent and simple way. As a customer, you declare that the entries data are real and corresponding to Your identity and that you will keep them updated throughout the duration of the
                                contract. The identity and all the information’s that you declare to associated to your account are necessary for the validation and activation of our services for the entire duration of the contract.
                                Moonosys and the customer respectively undertake to maintain the confidentiality of the data and the information’s obtained in connection to the activities for the execution of this contract and to the activities for the execution of the service. The account does not have an established expiry, can be interrupted at any time, in this case, the cancellation of all your personal data. The cancellation of your account automatically implies the closing of all the contracts you have signed and the resulting loss of the services. If our service provided is free of charge free model, Moonosys reserves the right to terminate the validity of the contract and of the service at any time. </p>
                            <p><b>04. PAYMENTS</b><br />
                                The payment methods accepted by Moonosys are:
                                <br />a. Payment Gateway
                                <br />b. Bank transfer.
                                <br />When the final payment is received, Moonosys will send you a confirmation email and will activate the services that You have chosen and paid. The Invoices of payment sent by you to Moonosys are regularly issued at the time of the confirmation of payment and they can be found online from your control panel. Once you have created your account, and your data, your movements, your invoices and your contracts will be available directly via Web, (after authentication) through the "Control Panel" on the site: http://moonosys.com/</p>

                            <p><b>05. UPDATING CONTRACTS</b><br />
                                You will be able to view the contracts updated directly from your online panel of control when Moonosys makes available a newer one. You agree that if after a new publication or sending of a notification concerning the changes made to this contract, you will continue to use our Services, we will consider accepted the new conditions proposed. The historic of the updates of the contracts is expressed by the number of the version included in all contracts and, the texts of all the contracts offered in Moonosys are freely available on the site (after authentication): http://moonosys.com/</p>

                            <p><b>06. CHANGES TO THE SITE AND SERVICES</b><br />
                                Moonosys reserves the right to edit any section and/or content of the site as well as the terms and conditions of access to its facilities, all in a view to improve and update the services to offer to its customers. If some changes will be committed, Moonosys will inform you about it through email or by posting, in a visible way, on its website. </p>

                            <p><b>07. COMMUNICATIONS BETWEEN THE PARTS</b><br />
                                The customer agrees that the notification and the communications concerning all the online services are carried out by Moonosys in electronic format (electronic mail) and through web service; recognizes the force and expressly renounces even now to acknowledge the contents of the declarations sent or received in electronic format. Moonosys , therefore, will use the email, that you have personally issued at the time of the creation of personal data and the account, for all the communications and notifications that will succeed, providing a clear and timely relationship, with
                                specific reference also to the communications regarding withdrawal and/or denial to the customer to benefit of a further service. It is important, that your email address is up to date and running. You must inform Moonosys in case you are unable to use your mail service, to avoid misunderstandings. All the mail sent to Your address are otherwise considered received and read on the 5 following days.
                                You agree to be the only responsible in the case of failure of reading an e-mail sent regularly to you in these terms. From your control panel, you can contact us directly by e-mail, getting a quickly answer in accordance to your needs. </p>


                            <p><b>08. CHARGES AND RIGHTS OF MOONOSYS</b><br />
                                As a provider of technology services, Moonosys ensures that: Do everything in its power to provide continuous access to the service, in accordance with the technical needs of maintenance and in case of major force. Suspend the total or partial use of the service only for a short period, engage in rapid recovery of it and, when is possible, advise you previously by e-mail to allow eventual agreements. The reasons, that may lead to a suspension of the service concerning technical interventions needed for a temporary malfunction, are routine maintenance, technical improvements in relation to the state of the art. Deliver the services with the utmost care and efficiency, to ensure high levels of
                                technology in relation to the state art. Offer the maximum transparency so that the quality levels of the services and resources can be monitored and verified in accordance with their limitations and
                                the technical characteristics of each service. Act quickly in the event of a technical failure to restore service to full systems. </p>

                            <p><b>09. LIABILITY</b><br />
                                Moonosys is not responsible in the event of delays, malfunction, suspension and/ or termination of access to services caused by: Unforeseeable circumstances or major force, as national and/or international emergencies, fires, explosions, earthquakes, volcanic eruptions, landslides, cyclones, storms, floods, hurricanes, avalanches, war, civil strife, riots, strikes and any other cause unexpected and exceptional that prevents to provide the agreed service. Manumission or actions on the services or equipment’s made by you or by anyone not authorized by Moonosys. Technical problems related to the Internet independent from Moonosys, as a physical damage or traffic congestion. Incorrect use of the services by you or anyone else. Malfunction or inadequacy of the connecting equipment and the base technology equipment that You have. In case that a malfunction of the services, have caused you an economic damage, Moonosys is required to compensate only if your problem has been promptly notified, within five days after the determination of the damage. In any case, Moonosys will compensate, if there are a real condition, with an amount that does not exceed the cost of the service whose failure was due to damage, even if the cost of the damage is superior. If our service provided is for free model, its use is at your own responsibility. The free model does not give you the right to have any warranty or protection or assistance from Moonosys. In this case Moonosys is not responsible for any compensation in any case. </p>

                            <p><b>10. CHARGES AND RIGHTS OF THE CUSTOMER</b><br />
                                Adhering to the following contract You agree to comply all the rules required by Moonosys for an adequate use of the all services offered, for the entire duration of the contract. Also ensures to respect the following conditions: Respect and adherence to the local and national laws in force at the time of signing, specifically to the Italian ones. Respect and acceptance of the technical limitations of our service. To trust the data (logs) produced by our system, that maintain track and history of
                                the operations performed by you. Raise Moonosys from any contentious related to the use of our services that can occur with any third part. Retain Moonosys not responsible for any break of your equipment as, for example, your personal computer, your internet connection etc., even if it happens during the use of Moonosys. Your personal data, issued to Moonosys, are true and You will have the charge to keep confidential your account name and password, which are under your direct responsibility. Verify periodically the accuracy and the consistency of the data on your account, the proper functioning of the service provided to you and inform Moonosys of any faults that may occur.
                                Do not use or allow the use of our services against morality and public order, in order to disturb public and/or private, of causing offense, damage directly and/or indirectly to anyone. Abstention in illegal activities as spamming, phishing, cracking and/or any other unauthorized use and/or deflected Internet, going to harm Moonosys, its image and/or its operations. Consider under your responsibility the entry and use of images and/or content that have copyright, in any area of our service. Abstention from the use of images and/or content protected by copyright or that dealing themes against the ethic of Moonosys, as racism, xenophobia, paedophilia child, against public morals, against human rights, in favour of terrorism, public order etc. Moonosys may terminate the use of your service and recede the contract, to the senses and for the effects of art. 1456 of the Civil Code, with a simple communication by e-mail, if a violation of the above paragraphs happens, without prior notice and without give you a chance to receive a formal act. You agree, as a responsible, to quickly cancel the illegal content or everything that affect the ethical principles of Moonosys, principles that are written on the site: https://Moonosys.com and that you, as our customer, ensures to follow and respect. Moonosys is not responsible for your configurations and content, as images, that you will decide to use; therefore, it will not intervene to modify not even an
                                individual parts. So, the intervention of Moonosys will be only for the deletion from Your service. </p>


                            <p><b>11. INTELLECTUAL PROPERTY</b><br />
                                Is forbidden by the law to copy, transcribe, reproduce, add or modify the software, the contracts, and the related documentation, the logo and the name of Moonosys and every distinctive sign specified and used on our website, after our permission. The only party that do not follow the limits described above, are those clearly published under the GPL (General Public License). Anyone who does not follow the directives on copyright are punishable by law. </p>

                            <p><b>12. COMPETENT COURT</b><br />
                                This agreement shall be construed and interpreted in accordance with the Italian law. For anything not expressly covered by this contract, the provision of the Civil Code will be applied. Any dispute relating to the interpretation and/or execution of this contract shall be settled exclusively by the Court. </p>

                            <p><b>13. PROCESSING OF PERSONAL DATA</b><br /></p>
                            <p><b>a. PERSONAL DATA RELATED TO THE CONTRACT</b><br />
                                The Moonosys’s services are designed to be offered to companies in the hospitality sector, regardless of their property type (hotel, bed & breakfast, hostel, ...) and of their business name. It is also included the use of Moonosys’s services by individual citizens, identified as individuals. The data processed for the management of the contract are therefore those of the hospitality Properties and Customers who sign the services. These data are excluded from the application of the Personal Data Protection Act 2010 ("PDPA"), which aims to safeguard the dignity, reputation and tranquillity of citizens, protecting them from unnecessary and potentially dangerous treatments of their data. It doesn’t mean that Moonosys doesn’t put in place adequate security measures to guarantee the security of the data of its Clients conferred upon the stipulation of contracts and during the maintenance of the same. </p>

                            <p><b>b. PERSONAL DATAS WICH MOONOSYS IS A CONTROLLER</b><br />
                                Moonosys is the owner of the data entered when the account was created specifically for the relevant Personal information entered when the contract was signed or entered into the contact form. The attached document https://Moonosys.com/…......  represents the updated description of the nature of the data and their processing. </p>


                            <p><b>14. PERSONAL DATAS WHICH THE CUSTOMER IS THE CONTROLLER (AND MOONOSYS THE PROCESSOR) </b><br />
                                Some services offered by Moonosys, in particular PMS, the booking engine service, and POS, the hotel management system, allow the Guest Accommodation Facilities to manage the data of their guests or their staff through the functionalities, standard or optional, offered by the applications. Each Accommodation facility (Customer) maintains ownership of the data in question and recognizes Moonosys as the external responsible in charge of the treatment according to the attached
                                appointment scheme available at the URL https://Moonosys.com
                                . </p>

                        </div>
                    </div>{/* Ends: .collapse */}
                </div>{/* Ends: .accordion-single */}
                <div className="accordion-single">
                    {/*<div className="accordion-heading" id="heading2_4">
                        <h6 className="mb-0">
                        <NavLink to="/" onClick={noAction} className="collapsed" data-toggle="collapse" data-target="#collapse2_4" aria-expanded="false" aria-controls="collapse2_4">
                            4. What industries do you specialize in?
                        </NavLink>
                        </h6>
                    </div>
                    <div id="collapse2_4" className="collapse" aria-labelledby="heading2_4" data-parent="#accordion_two">
                        <div className="accordion-contents">
                        <p>Investiga tiones demonstr averun lectores legere melius quod kequa legunt saepius. Claritas est
                            etiam pro cessus dynamicus qui sequitur mutatin onem consuetudium. Investiga tiones demonstr
                            averunt lectores legere me liusked quod kequa legunt saepius. Claritas est etiam pro cessus.
                            Sequitur mutatin onem consuetudium.</p>
                        </div>
                    </div>*/}{/* Ends: .collapse */}
                </div>{/* Ends: .accordion-single */}
            </div>{/* Ends: #accordion_two */}
        </div>
    )
}

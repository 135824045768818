import React, { Fragment } from 'react';
import Header from '../layout/header/singlePageHeader';
import Footer from '../layout/footer/footer-5';
import Card from '../container/element/card/card-style-10-2';
import Cta from '../content/cta/cta-sm-2';


const Service = () => {
    return (
        <Fragment>

            <Header pageTitle='Custom Software Development & Consultation'/>
            <section className="p-top-110 p-bottom-40">
               <Card from={0} to={3}/>
               <Card from={3} to={6}/>/>

            </section>
            {/*<Cta />*/}
            <Footer />
        </Fragment>
    )
}

export default Service;

import React, {Fragment} from 'react';
//import Header from '../layout/header/slider-header-17';
import Header from '../layout/header/singlePageHeader';
import { IconBoxTwo, IconBoxThree } from '../content/element/iconBox/iconBox';
import ContentBlock15 from '../content/contentBlock/contentBlock-15';
import { CountUp1 } from '../content/element/counter/count';
import Clients from '../container/clients/clients1';
import {AccordianTwo} from '../content/element/accordians/accordians';
import {AskQuestion, FormBox} from '../content/element/form/contact-form';
import Testimonial from '../container/testimonial/testimonial-carousel-one';
import CTA from '../content/cta/cta-3';
import { ImageBlock } from '../content/element/addressBlock';
import Map1 from '../content/element/map/map-1';

import Footer from '../layout/footer/footer-5';
import { NavLink } from 'react-router-dom';



const noAction = e => e.preventDefault();

const AboutUs = () => {
    return (
        <Fragment>

            <Header  pageTitle='About Us'/>
            <section className="services p-top-100 p-bottom-80">
                <div className="content-block content--block2">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 margin-md-60">
                                <div className="divider text-left">
                                    <h3 className="color-dark">In MoonoSys</h3>
                                    <p>We are having a group of proficient IT professionals with ability to inaugurate uncustomary customisation and a strong desire to excel.</p>
                                    <p>Our team is ready to deliver maximal transparency and convenience throughout superior software products and services that empowers our partners.</p>
                                </div>
                                <div className="m-top-25 m-bottom-50">
                                    <ul className="arrow--list1">
                                        <li className="list-item arrow-list1 color-dark">Our Mission</li>
                                        {/*<li className="list-item arrow-list1 color-dark">Our Strategy</li>*/}
                                        <li className="list-item arrow-list1 color-dark">Our Vision</li>
                                    </ul>
                                </div>
                                {/*<NavLink to='/at_demo'  onClick={noAction} className="btn btn-primary btn--rounded">Explore More</NavLink>*/}
                            </div>{/*<!-- ends: .col-lg-5 -->*/}
                            <div className="col-lg-6 offset-lg-1">
                               <IconBoxTwo />
                            </div>{/*<!-- ends: .col-lg-6 -->*/}
                        </div>
                    </div>
                </div>{/*<!-- ends: .content-block -->*/}
            </section>

            {/*<IconBoxThree name='Business Services' />*/}
            <ContentBlock15 />
           {/* <section className="content-block content-block--12">
                <div className="counter-wrapper">
                    <CountUp1 />
                </div>
                <div className="clients-logo">
                    <div className="container">
                        <Clients />
                    </div>
                </div>
            </section>*/}
            <section className="p-top-100 p-bottom-110 section-bg">
                <div className="m-bottom-50">
                    <div className="divider text-center">
                        <h1 className="color-dark">Frequently Asked Questions</h1>
                        <p className="mx-auto d-none"></p>
                    </div>
                </div>

                <div className="accordion-styles section-bg accordion--two">
                    <div className="container">
                        <div className="row">
                            <AccordianTwo />
                           {/* <FormBox />*/}
                           <AskQuestion/>
                        </div>
                    </div>
                </div>{/*<!-- ends: .accordion-styles2 -->*/}
            </section>
           {/* <section className="p-top-110 p-bottom-105">
                <Testimonial />
            </section>*/}
            <CTA />
            <section className="p-top-100 p-bottom-50">
                <div className="m-bottom-50">
                    <div className="divider text-center">
                        <h1 className="color-dark">Our Office</h1>
                        <p className="mx-auto d-none"></p>
                    </div>
                </div>
                <ImageBlock />
            </section>
            {/*<Map1 />*/}
            <Footer />
        </Fragment>
    )
}

export default AboutUs;

import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import { BarChart, LineChart, PieChart } from '../../content/element/chart/line-chart';


const Graphs = (props) => {

    return (
        <Fragment>

            <Header pageTitle="Graphs" />
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Graph - 1</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="col-md-6 offset-md-3">
                    <BarChart />
                </div>
            </div>
            {/* Graph - 1 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Graph - 2</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="col-md-6 offset-md-3">
                    <LineChart />
                </div>
            </div>
            {/* Graph - 2 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Graph - 3</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="col-md-6 offset-md-3">
                    <PieChart />
                </div>
            </div>
            {/* Graph - 3 */}
            <section className="p-top-30 p-bottom-100"></section>
            <Footer />
        </Fragment>
      );
}

export default Graphs
import $ from "jquery";
import React, { Component } from 'react';
import TestimonialSixCont from '../../content/testimonial/testimonial-4';
import OwlCarousel from 'react-owl-carousel2';
import {connect} from 'react-redux';

let rtlCheck = $("body").css("direction");
let falsy = false;
if(rtlCheck === "rtl"){
    falsy = true;
}


const options = {
    items: 1,
    rtl: falsy,
    nav: true,
    navText: ['<i class="la la-angle-left"></i>', '<i class="la la-angle-right"></i>'],
    navContainerClass: "nav-circle nav-circle--light",
    dots: false,
    navElement: 'button',
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 1
        },
        1000: {
            items: 1
        }
    }
}

class TestimonialFour extends Component {
    render() {
        const {testimonial} = this.props

        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                            <OwlCarousel options={options} className="testimonial-carousel-four owl-carousel">
                            {
                                Object.values(testimonial).map((value, key) => {
                                    return <TestimonialSixCont name={value.name}
                                    subtitle={value.subtitle}
                                    content={value.content.split(' ').slice(0, 28).join(' ')}
                                    img={value.imgSrc}
                                    key={key+1}
                                     />
                                })
                            }
                            </OwlCarousel>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {

    return {
        testimonial : state.testimonial
    }
}
export default connect(mapStateToProps)(TestimonialFour)
import $ from "jquery";
import React from 'react';
import { connect } from 'react-redux';
import OwlCarousel from 'react-owl-carousel2';
let rtlCheck = $("body").css("direction");
let falsy = false;
if(rtlCheck === "rtl"){
    falsy = true;
}
const options = {
    items: 2,
    margin: 30,
    rtl: falsy,
    nav: false,
    dots: true,
    dotsClass: "owl-dots dot-circle",
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 2
        },
        1000: {
            items: 2
        }
    }
}
const LogoCarousel = props => {
    const {client} = props;
    return (
        <OwlCarousel options={options} className="logo-carousel-two owl-carousel">
            {
                Object.values(client[0].clients).map((value, key) => {
                    const {id, imgSrc} = value;
                    return (
                        <div className="carousel-single" key={id}>
                            <div className="logo-box">
                                <img src={imgSrc} alt="" />
                            </div>{/*<!-- ends: .logo-box -->*/}
                            <div className="logo-box">
                                <img src={imgSrc} alt="" />
                            </div>{/*<!-- ends: .logo-box -->*/}
                        </div>
                    )
                })
            }
        </OwlCarousel>
    )
}
const mapStateToProps = state => {
    return {
        client : state.client
    }
}
export default connect(mapStateToProps)(LogoCarousel);
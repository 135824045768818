import React from 'react';
import { NavLink } from 'react-router-dom';
const noAction = e => e.preventDefault();

export const PostCard1Con = (props) => {
    const {title, img, content, id, industry, date} = props;
    return (
        <div className="col-lg-4 col-md-6">
            <div className="card post--card ">
                <figure>
                    <NavLink to={"/news-details"+id}><img src={img} alt="" /></NavLink>
                </figure>
                <div className="card-body">
                    <h6><NavLink to={"/news-details"+id}>{title}</NavLink></h6>
                    <ul className="post-meta d-flex">
                        <li>{date}</li>
                        <li>in <NavLink to="/" onClick={noAction}>{industry}</NavLink></li>
                    </ul>
                    <p>{content}</p>
                </div>
            </div>{/*<!-- End: .card -->*/}
        </div>
    )
}


export const CardStyle = (props) => {
    const {title, img, id, industry, date} = props;
    return (
        <div className="col-lg-4 col-md-6">
            <div className="card card-shadow card-two">
                <figure>
                    <img src={img} alt="" />
                </figure>
                <div className="card-body">
                    <h6><NavLink to={"/news-details"+id} >{title}</NavLink></h6>
                </div>
                <div className="card-footer">
                    <ul>
                        <li><NavLink to='/' onClick={noAction}>{industry}</NavLink></li>
                        <li>{date}</li>
                    </ul>
                </div>
            </div>{/*<!-- Ends: .card -->*/}
        </div>
    )
}

export const CardStyleTeen = (props) => {
    const {img, id, expert, speach, noDetails} = props;
    //console.log('nodetailx',noDetails)
    return (
        <div className="col-lg-4 col-md-6">
            <div className="card--seven card--ten">
                <figure>
                    <img src={img} alt="" />
                </figure>
                <div className="card-body px-0 pb-0">
                    <h6>{noDetails === 0 ? <NavLink to={"/team-details"+id}>{expert}</NavLink> : expert}</h6>
                    <p>{speach}</p>
                    {noDetails === 0 ? <NavLink to={"/team-details"+id} className="btn btn-outline-primary">See Details</NavLink> : ''}
                </div>
            </div>
        </div>
    )
}

export const CardStyleThree = (props) => {
    const {img, speach, name, designation, id} = props;
    return (
        <div className="col-lg-4 col-sm-6">

            <div className="card card--team3">
                <div className="card__thumbnail">
                    <img src={img} alt="" />
                </div>
                <div className="card-body text-center">
                    <h6><NavLink to={"/team-details"+id}>{name}</NavLink></h6>
                    <span className="subtitle">{designation}</span>
                    <p>{speach.split(' ').slice(0, 15).join(' ')}</p>
                    <div className="social-basic ">
                        <ul className="d-flex justify-content-center ">
                            <li><NavLink to="#"><span className="fab fa-facebook-f"></span></NavLink></li>
                            <li><NavLink to="#"><span className="fab fa-twitter"></span></NavLink></li>
                            <li><NavLink to="#"><span className="fab fa-linkedin-in"></span></NavLink></li>
                            <li><NavLink to="#"><span className="fab fa-google-plus-g"></span></NavLink></li>
                        </ul>
                    </div>

                </div>
            </div>{/*<!-- End: .card -->*/}
        </div>
    )
}

export const CardGrid1 = (props) => {
    const {img, subtitle, id, content, title} = props;
    return (
        <div className="col-lg-12">
            <div className="project-list">
                <div className="card card-shadow d-flex">
                    <figure>
                        <img src={img} alt="" />
                    </figure>
                    <div className="card-body">
                        <p className="card-subtitle">{title}</p>
                        <h5><NavLink to={"/project-details"+id}>{subtitle}</NavLink></h5>
                        <p className="card-text">{content} {content}</p>
                        <NavLink to={"/project-details"+id} className="btn btn-outline-secondary">Read More</NavLink>
                    </div>
                </div>{/*<!-- end: .card -->*/}
            </div>{/*<!-- end: .project-list -->*/}
        </div>
    )
}

export const CardGrid2 = (props) => {
    const {img, subtitle, id, content, title} = props;
    return (
        <div className="col-md-6">
            <div className="card card-shadow card-four">
                <figure>
                    <img src={img} alt="" style={{width: '100%'}} />
                </figure>
                <div className="card-body">
                    <p className="card-subtitle">{subtitle}</p>
                    <h5><NavLink to={"/project-details"+id}>{title}</NavLink></h5>
                    <p className="card-text">{content}</p>
                </div>
            </div>{/*<!-- End: .card -->*/}
        </div>
    )
}

export const CardGrid3 = (props) => {
    const {img, subtitle, id, title} = props;
    return (
        <div className="col-lg-4 col-sm-6">
            <div className="card card-shadow card-one">
                <figure>
                    <img src={img} alt="" />
                    <figcaption>
                        <NavLink to={"/project-details"+id}><i className="la la-link"></i></NavLink>
                    </figcaption>
                </figure>
                <div className="card-body">
                    <p className="card-subtitle color-secondary">{subtitle}</p>
                    <h6><NavLink to={"/project-details"+id}>{title}</NavLink></h6>
                </div>
            </div>{/*<!-- Ends: .card -->*/}
        </div>
    )
}

export const UpcomingEvent = (props) => {
    const {time, id, title, duration, date} = props;
    return (
        <div className="event-single d-flex flex-wrap">
            <div className="event-date">
                <h1 className="display-1">{date.split(' ')[0]}</h1>
                <span className="month">{date.split(' ')[1]}</span>
                <span className="year">{date.split(' ')[2]}</span>
            </div>{/*<!-- ends: .event-date -->*/}
            <div className="event-info">
                <div>
                    <h5><NavLink to={"/event-details"+id}>{title}</NavLink></h5>
                    <ul className="icon-list--two">
                        <li className="list-item icon-list"><span className="color-primary"><i className="la la-clock-o"></i></span> {time}</li>
                        <li className="list-item icon-list"><span className="color-primary"><i className="la la-calendar-check-o"></i></span> {duration}</li>
                    </ul>
                </div>
                <div className="view-btn">
                    <NavLink to={"/event-details"+id} className="btn btn-outline-primary btn-icon icon-right">View on Map <span className="la la-map-marker"></span></NavLink>
                </div>{/*<!-- ends: .view-btn -->*/}
            </div>{/*<!-- ends: .event-info -->*/}
        </div>
    )
}

export const TeamCard4 = (props) => {
    const {img, speach, name, designation, id} = props;
    return (
        <div className="col-lg-12 col-md-6">
            <div className="card card--team4 d-flex border-0 shadow-sm">
                <div className="team-image"><img src={img} alt="" /></div>
                <div className="card-body">
                    <div className="team-info d-flex justify-content-between">
                        <div className="m-bottom-25">
                            <h6><NavLink to={"/team-details"+id}>{name}</NavLink></h6>
                            <span>{designation}</span>
                        </div>
                        <div className="social-basic ">
                            <ul className="d-flex justify-content-center ">
                                <li><NavLink to="#"><span className="fab fa-facebook-f"></span></NavLink></li>
                                <li><NavLink to="#"><span className="fab fa-twitter"></span></NavLink></li>
                                <li><NavLink to="#"><span className="fab fa-linkedin-in"></span></NavLink></li>
                                <li><NavLink to="#"><span className="fab fa-google-plus-g"></span></NavLink></li>
                            </ul>
                        </div>
                    </div>
                    <p>{speach}</p>
                </div>
            </div>{/*<!-- ends: .card -->*/}
        </div>
    )
}
export const EventGrid13 = (props) => {
    const {id, time, img, title, duration, category} = props;
    return (
        <div className="col-lg-4 col-md-6">
            <div className="card card-shadow card-one card-thirteen">
                <figure>
                    <img src={img} alt="" />
                    <figcaption>
                        <NavLink to={"/event-details"+id}><i className="la la-link"></i></NavLink>
                    </figcaption>
                </figure>
                <div className="card-body">
                    <p className="card-subtitle color-secondary">{category}</p>
                    <h6><NavLink to={"/event-details"+id}>{title}</NavLink></h6>
                    <ul className="icon-list--two m-top-15">
                        <li className="list-item icon-list"><span className="color-primary"><i className="la la-calendar-check-o"></i></span> {duration}</li>
                        <li className="list-item icon-list"><span className="color-primary"><i className="la la-clock-o"></i></span> {time}</li>
                        <li className="list-item icon-list"><span className="color-primary"><i className="la la-map-marker"></i></span> Wst. Conference Center, San Francisco, CA</li>
                    </ul>
                </div>
            </div>
            {/* <!-- End: .card --> */}
        </div>
    )
}

import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import { AccordianOne, AccordianTwo, AccordianFour, AccordianThree, AccordianFour2nd, AccordianThree2nd } from '../../content/element/accordians/accordians';

import { FormBox } from '../../content/element/form/contact-form';
import ImageCarousel2 from '../../container/element/carousel/image-carousel-2';
const Alerts = () => {
    return (
        <Fragment>

            <Header pageTitle="Accordion" />

            {/* Basic Accordion */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Basic Accordion</h3>
                        </div>
                    </div>
                </div>
            </div>
            <AccordianOne />

            {/* Accordion With Form */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Accordion With Form</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-top-80 p-bottom-80 section-bg">
                <div className="accordion-styles section-bg accordion--two">
                    <div className="container">
                        <div className="row">
                            <AccordianTwo />
                            <FormBox />
                        </div>
                    </div>
                </div>{/*<!-- ends: .accordion-styles2 -->*/}
            </div>

            {/* Accordion With Slider */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Accordion With Slider</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-top-30 p-bottom-0">
                <section className="accordion-styles accordion--three">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <ImageCarousel2 />
                            </div>
                            <AccordianFour />
                        </div>
                    </div>
                </section>
            </div>

            {/* Accordion With Text */}
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="showcase showcase--title1 showcase--title2">
                            <h3>Accordion With Text</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="p-top-110 p-bottom-105 section-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 margin-md-60">
                            <h3>Benefits &amp; Rewards</h3>
                            <p className="mb-3 pb-3">We can offer you a wide range of opportunities to build a varied
                                and rewarding career.We offer attractive careers for talented and
                                ambitious professionals from a wide range of backgrounds, with
                                different skills, capabilities. </p>
                            <ul className="bullet--list1">
                                <li className="bullet_list"><strong className="color-dark">Professional -</strong> Delivers solutions that help drive</li>
                                <li className="bullet_list"><strong className="color-dark">Business -</strong> Human capital research analytics</li>
                                <li className="bullet_list"><strong className="color-dark">Services -</strong> Complex problems bringing an approach</li>
                                <li className="bullet_list"><strong className="color-dark">Strategy -</strong> Works with senior executives to help them</li>
                            </ul>{/* ends: .bullet--list1 */}
                        </div>{/* ends: .col-lg-6 */}
                        <div className="col-lg-6">
                            <AccordianThree />
                        </div>{/* ends: .col-lg-6 */}
                    </div>
                </div>
            </section>{/* ends: section */}

            {/* Accordion With Image */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Accordion With Image</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="accordion-styles accordion--four p-top-30 p-bottom-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 margin-md-60">
                            <AccordianFour2nd />
                        </div>{/* ends: .col-lg-5 */}
                        <div className="col-lg-5 offset-lg-1">
                            <img src="./assets/img/image1.jpg" alt="" />
                        </div>{/* ends: .col-lg-6 */}
                    </div>
                </div>
            </section>{/* ends: .accordion-styles */}

            {/* Accordion With Video */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Accordion With Video</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="accordion-styles p-bottom-110 accordion--video">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 margin-md-60">
                            <AccordianThree2nd />
                        </div>{/* ends: .col-lg-5 */}
                        <div className="col-lg-6 offset-lg-1">
                            <div className="video-single">
                                <figure>
                                    <div className="v_img">
                                        <img src="./assets/img/simg-2.jpg" alt="" className="rounded" />
                                    </div>
                                    <figcaption className="d-flex justify-content-center align-items-center shape-wrapper img-shape-two">
                                        <a href="https://www.youtube.com/watch?v=omaTcIbwt9c" className="video-iframe play-btn play-btn--lg play-btn--primary"><img src="./assets/img/svg/play-btn.svg" alt="" className="svg" /></a>
                                    </figcaption>
                                </figure>
                            </div>{/* ends: .video-single */}
                        </div>{/* ends: .col-lg-6 */}
                    </div>
                </div>
            </section>{/* ends: .accordion-styles */}
            <Footer />
        </Fragment>
    )
}

export default Alerts;
import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';

const Alerts = () => {
    return (
        <Fragment>

            <Header pageTitle="Alerts" />
            <section className="alert-types">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="showcase-box">
                                <div className="showcase-header">
                                    <h4>Basic Contextual Alerts</h4>
                                </div>
                                <div className="showcase-body">
                                    <div className="alert-group">
                                        <div className="alert alert-primary" role="alert">
                                            <p><strong>Howdy!</strong> This a simple primary alert—check it out!</p>
                                        </div>
                                        <div className="alert alert-secondary" role="alert">
                                            <p><strong>Whats up!</strong> A simple secondary alert—check it out!</p>
                                        </div>
                                        <div className="alert alert-dark" role="alert">
                                            <p><strong>Hello World!</strong> This is default alert message box style.</p>
                                        </div>
                                        <div className="alert alert-success" role="alert">
                                            <p><strong>Well done!</strong> You successfully read this important alert message.</p>
                                        </div>
                                        <div className="alert alert-info" role="alert">
                                            <p><strong>Heads Up!</strong> This alert needs your attention, but it's not super important.</p>
                                        </div>
                                        <div className="alert alert-warning" role="alert">
                                            <p><strong>Warning!</strong> Better check yourself, you're not looking too good.</p>
                                        </div>
                                        <div className="alert alert-danger" role="alert">
                                            <p><strong>Oh snap!</strong> Change a few things up and try submitting again.</p>
                                        </div>
                                    </div>
                                </div>{/* End: .showcase-body */}
                            </div>{/* Ends: .card */}
                            <div className="showcase-box">
                                <div className="showcase-header">
                                    <h4>Link Alerts</h4>
                                </div>
                                <div className="showcase-body">
                                    <div className="alert-group">
                                        <div className="alert alert-primary" role="alert">
                                            <p>Howdy! This a <a href=" ">simple primary</a> alert—check it out!</p>
                                        </div>
                                        <div className="alert alert-secondary" role="alert">
                                            <p>Whats up! <a href=" ">A simple secondary</a> alert—check it out!</p>
                                        </div>
                                        <div className="alert alert-dark" role="alert">
                                            <p>Hello World! This is <a href=" ">default alert message</a> box style.</p>
                                        </div>
                                        <div className="alert alert-success" role="alert">
                                            <p>Well done! You successfully read <a href=" ">this important alert message.</a></p>
                                        </div>
                                        <div className="alert alert-info" role="alert">
                                            <p>Heads Up! This <a href=" ">alert needs your attention</a>, but it's not super important.</p>
                                        </div>
                                        <div className="alert alert-warning" role="alert">
                                            <p>Warning! Better check yourself, you're <a href=" ">not looking too good.</a></p>
                                        </div>
                                        <div className="alert alert-danger" role="alert">
                                            <p>Oh snap! <a href=" ">Change a few things up</a> and try submitting again.</p>
                                        </div>
                                    </div>
                                </div>{/* Ends: .showcase-body */}
                            </div>{/* Ends: .card */}
                            <div className="showcase-box">
                                <div className="showcase-header">
                                    <h4>Outline Style Alerts</h4>
                                </div>
                                <div className="showcase-body">
                                    <div className="alert-group">
                                        <div className="alert alert-primary alert-outline" role="alert">
                                            <p><strong>Howdy!</strong> This a simple primary alert—check it out!</p>
                                        </div>
                                        <div className="alert alert-secondary alert-outline" role="alert">
                                            <p><strong>Whats up!</strong> A simple secondary alert—check it out!</p>
                                        </div>
                                        <div className="alert alert-dark alert-outline" role="alert">
                                            <p><strong>Hello World!</strong> This is default alert message box style.</p>
                                        </div>
                                        <div className="alert alert-success alert-outline" role="alert">
                                            <p><strong>Well done!</strong> You successfully read this important alert message.</p>
                                        </div>
                                        <div className="alert alert-info alert-outline" role="alert">
                                            <p><strong>Heads Up!</strong> This alert needs your attention, but it's not super important.</p>
                                        </div>
                                        <div className="alert alert-warning alert-outline" role="alert">
                                            <p><strong>Warning!</strong> Better check yourself, you're not looking too good.</p>
                                        </div>
                                        <div className="alert alert-danger alert-outline" role="alert">
                                            <p><strong>Oh snap!</strong> Change a few things up and try submitting again.</p>
                                        </div>
                                    </div>
                                </div>{/* Ends: .showcase-body */}
                            </div>{/* Ends: .card */}
                            <div className="showcase-box">
                                <div className="showcase-header">
                                    <h4>Icon Alerts</h4>
                                </div>
                                <div className="showcase-body">
                                    <div className="alert-group">
                                        <div className="alert alert-primary" role="alert">
                                            <p><i className="la la-user" /> <strong>Howdy!</strong> This a simple primary alert—check it out!</p>
                                        </div>
                                        <div className="alert alert-secondary" role="alert">
                                            <p><i className="la la-thumbs-up" /> <strong>Whats up!</strong> A simple secondary alert—check it out!</p>
                                        </div>
                                        <div className="alert alert-dark" role="alert">
                                            <p><i className="la la-bell" /> <strong>Hello World!</strong> This is default alert message box style.</p>
                                        </div>
                                        <div className="alert alert-success" role="alert">
                                            <p><i className="la la-check" /> <strong>Well done!</strong> You successfully read this important alert message.</p>
                                        </div>
                                        <div className="alert alert-info" role="alert">
                                            <p><i className="la la-bullhorn" /> <strong>Heads Up!</strong> This alert needs your attention, but it's not super important.</p>
                                        </div>
                                        <div className="alert alert-warning" role="alert">
                                            <p><i className="la la-info-circle" /> <strong>Warning!</strong> Better check yourself, you're not looking too good.</p>
                                        </div>
                                        <div className="alert alert-danger" role="alert">
                                            <p><i className="la la-warning" /> <strong>Oh snap!</strong> Change a few things up and try submitting again.</p>
                                        </div>
                                    </div>
                                </div>{/* Ends: .showcase-body */}
                            </div>{/* Ends: .card */}
                            <div className="showcase-box">
                                <div className="showcase-header">
                                    <h4>Dismissing Alerts</h4>
                                </div>
                                <div className="showcase-body">
                                    <div className="alert-group">
                                        <div className="alert alert-primary alert-dismissible fade show" role="alert">
                                            <p><i className="la la-user" /> <strong>Howdy!</strong> This a simple primary alert—check it out!</p>
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span className="la la-times" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <div className="alert alert-secondary alert-dismissible fade show" role="alert">
                                            <p><i className="la la-thumbs-up" /> <strong>Whats up!</strong> A simple secondary alert—check it out!</p>
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span className="la la-times" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <div className="alert alert-dark alert-dismissible fade show" role="alert">
                                            <p><i className="la la-bell" /> <strong>Hello World!</strong> This is default alert message box style.</p>
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span className="la la-times" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                                            <p><i className="la la-check" /> <strong>Well done!</strong> You successfully read this important alert message.</p>
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span className="la la-times" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <div className="alert alert-info alert-dismissible fade show" role="alert">
                                            <p><i className="la la-bullhorn" /> <strong>Heads Up!</strong> This alert needs your attention, but it's not super important.</p>
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span className="la la-times" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                            <p><i className="la la-info-circle" /> <strong>Warning!</strong> Better check yourself, you're not looking too good.</p>
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span className="la la-times" aria-hidden="true" />
                                            </button>
                                        </div>
                                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                            <p><i className="la la-warning" /> <strong>Oh snap!</strong> Change a few things up and try submitting again.</p>
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span className="la la-times" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </div>{/* Ends: .showcase-body */}
                                </div>{/* Ends: .showcase-body */}
                            </div>{/* Ends: .card */}
                        </div>
                    </div>
                </div>
            </section>{/* end: section */}
            <Footer />
        </Fragment>
    )
}

export default Alerts;
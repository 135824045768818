import React, {Fragment} from 'react';

export const CountUp = () => {
    return (
        <div className="counter counter--2 bg-primary">
            <div className="container content_above">
                <div className="row">
                    <div className="col">
                        <div className="d-flex justify-content-around flex-wrap">

                            <div className="counter_single">
                                <div className="icon">
                                    <span className="la la-folder-open-o"></span>
                                </div>
                                <p className="value count_up">385</p>
                                <p className="title">Project completed</p>
                            </div>{/*<!-- end: .counter_single -->*/}

                            <div className="counter_single">
                                <div className="icon">
                                    <span className="la la-headphones"></span>
                                </div>
                                <p className="value count_up">260</p>
                                <p className="title">Consultant</p>
                            </div>{/*<!-- end: .counter_single -->*/}

                            <div className="counter_single">
                                <div className="icon">
                                    <span className="la la-trophy"></span>
                                </div>
                                <p className="value count_up">150</p>
                                <p className="title">Award Winning</p>
                            </div>{/*<!-- end: .counter_single -->*/}

                            <div className="counter_single">
                                <div className="icon">
                                    <span className="la la-user-plus"></span>
                                </div>

                                <p className="value count_up">100</p>
                                <p className="title">Satisfied Customers</p>
                            </div>{/*<!-- end: .counter_single -->*/}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export const CountUp2 = () => {
    return (

        <section className="clients-with-facts bgimage biz_overlay overlay--primary p-top-105 p-bottom-105">
            <div className="bg_image_holder"><img src="./assets/img/bg1.jpg" alt="" /></div>
            <div className="container content_above">
                <div className="row">
                    <div className="col-lg-5 margin-md-60">
                        <h2 className="color-light">Some of Company Real Facts</h2>
                        <p className="color-light m-0">Investig ationes demons traghyve lectores legere liusgr awquod legunt dbtsaepius clary tyitas awq
                            Investig atiohynes demon dbtsaepius clary.</p>
                    </div>{/*<!-- ends: .col-lg-5 -->*/}
                    <div className="col-lg-7">
                        <div className="d-flex justify-content-lg-around justify-content-between flex-wrap">
                            <div className="counter_single">
                                <div className="icon">
                                    <span className="la la-folder-open-o"></span>
                                </div>
                                <p className="value count_up">385</p>
                                <p className="title">Project completed</p>
                            </div>{/*<!-- end: .counter_single -->*/}
                            <div className="counter_single">
                                <div className="icon">
                                    <span className="la la-headphones"></span>
                                </div>
                                <p className="value count_up">260</p>
                                <p className="title">Consultant</p>
                            </div>{/*<!-- end: .counter_single -->*/}
                            <div className="counter_single">
                                <div className="icon">
                                    <span className="la la-trophy"></span>
                                </div>
                                <p className="value count_up">150</p>
                                <p className="title">Award Winning</p>
                            </div>{/*<!-- end: .counter_single -->*/}
                        </div>
                    </div>{/*<!-- ends: .col-lg-7 -->*/}
                </div>
            </div>{/*<!-- ends: .container -->*/}
        </section>
    )
}


export const CountUp3 = () => {
    return (
        <div className="counter counter--3 biz_overlay overlay--primary">
            <div className="bg_image_holder"><img src="./assets/img/cbg2.jpg" alt="" /></div>
            <div className="container content_above">
                <div className="row">
                    <div className="col">
                        <div className="d-flex justify-content-around flex-wrap">
                            <div className="counter_single">
                                <div className="icon">
                                    <span className="la la-folder-open-o"></span>
                                </div>
                                <p className="value count_up">385</p>
                                <p className="title">Project completed</p>
                            </div>{/*<!-- end: .counter_single -->*/}
                            <div className="counter_single">
                                <div className="icon">
                                    <span className="la la-headphones"></span>
                                </div>
                                <p className="value count_up">260</p>
                                <p className="title">Consultant</p>
                            </div>{/*<!-- end: .counter_single -->*/}
                            <div className="counter_single">
                                <div className="icon">
                                    <span className="la la-trophy"></span>
                                </div>
                                <p className="value count_up">150</p>
                                <p className="title">Award Winning</p>
                            </div>{/*<!-- end: .counter_single -->*/}
                            <div className="counter_single">
                                <div className="icon">
                                    <span className="la la-user-plus"></span>
                                </div>
                                <p className="value count_up">100</p>
                                <p className="title">Satisfied Customers</p>
                            </div>{/*<!-- end: .counter_single -->*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const CountUp5 = () => {
    return (
        <div className="counter counter--5 biz_overlay">
            <div className="bg_image_holder"><img src="./assets/img/cbg3.jpg" alt="" /></div>
            <div className="container content_above">
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="counter_single">
                            <p className="value count_up">1500</p>
                            <p className="title">Project completed</p>
                        </div>{/*<!-- end: .counter_single -->*/}
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="counter_single">
                            <p className="value count_up">200</p>
                            <p className="title">Consultant</p>
                        </div>{/*<!-- end: .counter_single -->*/}
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="counter_single">
                            <p className="value count_up">560</p>
                            <p className="title">Award Winning</p>
                        </div>{/*<!-- end: .counter_single -->*/}
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="counter_single">
                            <p className="value count_up">50</p>
                            <p className="title">Satisfied Customers</p>
                        </div>{/*<!-- end: .counter_single -->*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export const CountUp7 = () => {
    return (
        <div className="counter counter--7 biz_overlay overlay--primary">
            <div className="bg_image_holder"><img src="./assets/img/cbg5.jpg" alt="" /></div>
            <div className="container content_above">
                <div className="row align-items-center">
                    <div className="col-md-5 margin-md-60">
                        <div className="counter_left_content">
                            <h4>Key Figures</h4>
                            <p>Investiga tiones demonstr averunt lectres legere me lius quod ii qua legunt saepius. Clarias
                                est
                                etiam pro cessus dynamicus, qui sequitur mutaety tionem consu etudium
                                Investig ationes demons trave.</p>
                        </div>
                    </div>
                    <div className="col-md-6 offset-md-1">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="counter_single">
                                    <p className="value count_up">520</p>
                                    <p className="title">USD Revenue</p>
                                </div>{/*<!-- end: .counter_single -->*/}
                            </div>
                            <div className="col-sm-6">
                                <div className="counter_single">
                                    <p className="value count_up">478</p>
                                    <p className="title">USD Assets</p>
                                </div>{/*<!-- end: .counter_single -->*/}
                            </div>
                            <div className="col-sm-6">
                                <div className="counter_single">
                                    <p className="value count_up">980</p>
                                    <p className="title">Employees</p>
                                </div>{/*<!-- end: .counter_single -->*/}
                            </div>
                            <div className="col-sm-6">
                                <div className="counter_single">
                                    <p className="value count_up">257</p>
                                    <p className="title">USD Net Income</p>
                                </div>{/*<!-- end: .counter_single -->*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const CountUp4 = (props) => {
    return (
        <section className={props.padding ? props.padding : "home8-counter section-padding-sm"}>
        <div className="counter counter--4">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="d-flex justify-content-around flex-wrap">
                            <div className="counter_single">
                                <p className="value count_up">385</p>
                                <p className="title">Project completed</p>
                            </div>{/*<!-- end: .counter_single -->*/}
                            <div className="counter_single">
                                <p className="value count_up">260</p>
                                <p className="title">Consultant</p>
                            </div>{/*<!-- end: .counter_single -->*/}
                            <div className="counter_single">
                                <p className="value count_up">150</p>
                                <p className="title">Award Winning</p>
                            </div>{/*<!-- end: .counter_single -->*/}
                            <div className="counter_single">
                                <p className="value count_up">100</p>
                                <p className="title">Satisfied Customers</p>
                            </div>{/*<!-- end: .counter_single -->*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>{/*<!-- ends: .counter -->*/}
    </section>
    )
}

export const CountUp1 = (props) => {
    return (
        <Fragment>
            <div className="counter counter--1 counter--alt bgimage biz_overlay overlay--primary">
                <div className="bg_image_holder"><img src="./assets/img/c1bg.jpg" alt="" /></div>
                <div className="container content_above">
                    <div className="row">
                        <div className="col">
                            <div className="d-flex justify-content-around flex-wrap">
                                <div className="counter_single">
                                    <p className="value count_up">385</p>
                                    <p className="title">Project completed</p>
                                </div>{/*<!-- end: .counter_single -->*/}
                                <div className="counter_single">
                                    <p className="value count_up">260</p>
                                    <p className="title">Consultant</p>
                                </div>{/*<!-- end: .counter_single -->*/}
                                <div className="counter_single">
                                    <p className="value count_up">150</p>
                                    <p className="title">Award Winning</p>
                                </div>{/*<!-- end: .counter_single -->*/}
                                <div className="counter_single">
                                    <p className="value count_up">100</p>
                                    <p className="title">Satisfied Customers</p>
                                </div>{/*<!-- end: .counter_single -->*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import FilterItem  from "../../../content/element/filter/filter-grid-masonry";

const Filter = props => {
    const { project } = props;    
    return (
        <div className="filter-wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="filter-sort2">
                            <ul id="okagrid">
                                <li data-uk-filter="" className="active"> All</li>
                                <li data-uk-filter="1"> Business Service</li>
                                <li data-uk-filter="2"> Financial Services</li> 
                                <li data-uk-filter="3"> Travel & Aviation</li>
                                <li data-uk-filter="4"> Academic Research</li>
                            </ul>
                        </div>{/*<!-- ends: .filter-sort -->*/}
                    </div>{/*<!-- ends: .col-lg-12 -->*/}
                </div>
                <div data-uk-grid="{controls: '#okagrid'}" className="row ukm">
                    {
                        Object.values(project).slice(0, 6).map((value, key) => {
                            const { id, imgSrc, category, title, subtitle, content } = value;
                            return (
                                <FilterItem 
                                    key={ id }
                                    id={ id }
                                    img={ imgSrc }
                                    category={ category }
                                    title={ title }
                                    subtitle={ subtitle }
                                    content={content}

                                />
                            )
                        })
                    }
                </div>
            </div>
            <div className="text-center m-top-40">
                <NavLink to='/at_demo'  className="btn btn-primary btn-icon icon-left"><i className="la la-refresh"></i>Load More</NavLink>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        project : state.project
    }
}
export default connect(mapStateToProps)(Filter);
import React from 'react';
import {NavLink} from 'react-router-dom';

const Cta2 = () => {
    return (
        <section className="cta-wrapper cta--two bgimage biz_overlay overlay--dark">
            <div className="bg_image_holder">
                <img src="./assets/img/cta2.png" alt="" />
            </div>
            <div className="container content_above">
                <div className="cta-content row d-flex align-items-center">
                    <div className="col-lg-9">
                        <p>Trusted by over 1000+ worldwide business! <br /> Start your projects now.
                        </p>
                    </div>
                    <div className="col-lg-3">
                        <div className="action-btn">
                            <NavLink to="/contact" className="btn btn-secondary">Get In Touch Now</NavLink>
                        </div>
                    </div>
                </div>{/*<!-- ends: .cta-content -->*/}
            </div>
        </section>
    )
}
export default Cta2;
import React, { Fragment, Component } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';


class Tables extends Component {
    render() {
      return (
        <Fragment>

            <Header pageTitle="Tables" />
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Table - 1</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="section-bg p-top-100 p-bottom-110">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="table-responsive">
                                <table className="table table-one">
                                    <thead className="table-secondary table-secondary--darken">
                                        <tr>
                                            <th scope="col">Job Title</th>
                                            <th scope="col">Location</th>
                                            <th scope="col">Category</th>
                                            <th scope="col">Date</th>
                                        </tr>
                                    </thead>{/* ends: thead */}
                                    <tbody>
                                        <tr>
                                        <th scope="row">Account Business Manager</th>
                                            <td>Vienna, Austria</td>
                                            <td>Administrative</td>
                                            <td>Jan 08, 2019</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Cash Application and Collections Specialist</th>
                                            <td>Detroit, Michigan</td>
                                            <td>Marketing</td>
                                            <td>Mar 23, 2019</td>
                                        </tr>
                                        <tr>
                                        <th scope="row">Director of Financial Systems</th>
                                            <td>London, UK</td>
                                            <td>Finance</td>
                                            <td>Apr 09, 2019</td>
                                        </tr>
                                        <tr>
                                        <th scope="row">Account Business Manager</th>
                                            <td>Paris, France</td>
                                            <td>Support Manager</td>
                                            <td>Jun 10, 2019</td>
                                        </tr>
                                        <tr>
                                        <th scope="row">Cash Application and Collections Specialist</th>
                                            <td>Detroit, Michigan</td>
                                            <td>Senior Executive</td>
                                            <td>Sep 17, 2019</td>
                                        </tr>
                                        <tr>
                                        <th scope="row">Director of Financial Systems</th>
                                            <td>London, UK</td>
                                            <td>Accounting</td>
                                            <td>Nov 01, 2019</td>
                                        </tr>
                                    </tbody>{/* ends: tbody */}
                                </table>
                            </div>{/* ends: .table-responsive */}
                        </div>
                    </div>
                </div>
            </section>{/* ends: section */}
            {/* Table - 1 */}


            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Table - 2</h3>
                        </div>
                    </div>
                </div>
            </div>

            <section className="table-style2 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="table-responsive">
                                <table className="table table-two">
                                    <thead className="table-gray">
                                        <tr>
                                            <th scope="col">Shipping Method</th>
                                            <th scope="col">Delivery Time</th>
                                            <th scope="col">Handling Fee</th>
                                        </tr>
                                    </thead>{/* ends: thead */}
                                    <tbody>
                                        <tr>
                                            <th scope="row">
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="select-one" name="customRadioInline1" className="custom-control-input" />
                                                    <label className="custom-control-label" htmlFor="select-one"><span>Fedex Courier</span></label>
                                                </div>{/* End: .custom-control */}
                                            </th>
                                            <td>2 - 4 days</td>
                                            <td>$20</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="select-two" name="customRadioInline1" className="custom-control-input" />
                                                    <label className="custom-control-label" htmlFor="select-two"><span>International Shipping</span></label>
                                                </div>{/* End: .custom-control */}
                                            </th>
                                            <td>1 - 3 days</td>
                                            <td>$25</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="select-three" name="customRadioInline1" className="custom-control-input" />
                                                    <label className="custom-control-label" htmlFor="select-three"><span>Local Pickup from Store</span></label>
                                                </div>{/* End: .custom-control */}
                                            </th>
                                            <td>1 -2 days</td>
                                            <td>Free</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="select-four" name="customRadioInline1" className="custom-control-input" />
                                                    <label className="custom-control-label" htmlFor="select-four"><span>Local Shipping</span></label>
                                                </div>{/* End: .custom-control */}
                                            </th>
                                            <td>Up to One Week</td>
                                            <td>$10</td>
                                        </tr>
                                    </tbody>{/* ends: tbody */}
                                </table>
                            </div>{/* ends: .table-responsive */}
                        </div>
                    </div>
                </div>
            </section>


            <Footer />
        </Fragment>
      )
    }
}
export default Tables

import React from 'react';

export const BlockquoteOne = () => {
    return (
        <section className="m-bottom-70">
            <div className="quotes-wrapper blockquote--one">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            {/*<!-- start blockquote -->*/}
                            <blockquote className="blockquote blockquote3">
                                <p>Crocal has the best responsive function on the market is very fast, good adaptable and easy to handle. Support responds quickly and is helpful lorem ipsum dolor sit amet.</p>
                                <div className="quote-author">
                                    <p><span>Jeff Collins,</span> Founder of Ratri Cloud Inc.</p>
                                </div>
                            </blockquote>{/*<!-- end: blockquote -->*/}
                        </div>
                    </div>
                </div>
            </div>{/*<!-- ends: .quotes-wrapper -->*/}
        </section>
    )
}

export const BlockquoteTwo = () => {
    return (
        <section className="quotes-wrapper blockquote--two">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <blockquote className="blockquote blockquote4">
                  <p>My focus areas are on global standardization and harmonization of business processes lorem dolor is reorganization of marketing and customer.</p>
                  <div className="quote-author">
                    <p><span>Jeff Collins,</span> Founder of Ratri Cloud Inc.</p>
                  </div>
                </blockquote>{/* end: blockquote */}
              </div>
            </div>
          </div>
        </section>
      )
}

export const BlockquoteThree = () => {
    return (
        <section className="quotes-wrapper blockquote--three">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* start blockquote */}
                <blockquote className="blockquote blockquote1 ">
                  <p>My focus areas are on global standardization and harmonization of business processes lorem dolor is reorganization of marketing and customer standardization and harmonization.</p>
                  <div className="quote-author">
                    <p><span>Jeff Collins,</span> Founder of Ratri Cloud Inc.</p>
                  </div>
                </blockquote>{/* end: blockquote */}
              </div>
            </div>
          </div>
        </section>
    )
}

export const BlockquoteFour = () => {
    return (
        <section className="quotes-wrapper blockquote--four">
          <div className="container">
            <div className="row">
              <div className="col-lg-9">
                <div className="m-bottom-30">
                  <blockquote className="blockquote blockquote2 blockquote-sm">
                    <p>My focus areas are on global standardization and harmonization of business processes, reorganization of marketing and customer.</p>
                    <div className="quote-author">
                      <p><span>Jeff Collins,</span> Founder of Ratri Cloud Inc.</p>
                    </div>
                  </blockquote>{/* end: blockquote */}
                </div>
                {/* start blockquote */}
                <blockquote className="blockquote blockquote1 blockquote-sm">
                  <p>My focus areas are on global standardization and harmonization of business processes lorem dolor is reorganization of marketing and customer standardization and harmonization.</p>
                  <div className="quote-author">
                    <p><span>Jeff Collins,</span> Founder of Ratri Cloud Inc.</p>
                  </div>
                </blockquote>{/* end: blockquote */}
              </div>
            </div>
          </div>
        </section>
      )
}

export const BlockquoteFive = () => {
    return (
        <section className="quotes-wrapper quotes-with-image p-top-80 p-bottom-80">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6">
                <img src="./assets/img/sb3_img.png" alt="" />
              </div>
              <div className="col-lg-6">
                <div>
                  {/* start blockquote */}
                  <blockquote className="blockquote5">
                    <p className="strong">Ratri Cloud is the best consulting company I have
                      ever worked and highly recommended for all
                      size of companies.</p>
                    <p>Investig ationes demons trave wanrunt lectores legere liushgfy quod legunt saeph claritas nvestig ationes demons trave rugngt investiga tiones demons averu. Investig ationes demons trave.</p>
                    <span className="author-sign">Robart Smith</span>
                  </blockquote>{/* end: blockquote */}
                </div>
              </div>
            </div>
          </div>
        </section>
      )
}

import React, {Component, Fragment} from 'react';
import NavItem from "./navItem";

class NabbarColor2 extends Component {    
        render() {  
          return (
            <Fragment>
                {/* <!-- start menu area --> */}
                <div className="menu_area menu4">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-light bg-light px-0">
                            <NavItem />                    
                        </nav>
                    </div>
                </div>
                {/* <!-- end menu area --> */}
            </Fragment>
        );
    }
}
export default NabbarColor2;
import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import FooterCurvedLight from '../../layout/footer/footerCurvedLight';
import FooterCurvedDark from '../../layout/footer/footerCurvedDark';
import FooterWavedLignt from '../../layout/footer/footerWavedLignt';
import Footer5 from '../../layout/footer/footer-light';
import FooterLight2 from '../../layout/footer/footer-light-2';
import FooterDark3 from '../../layout/footer/footer-dark-3';


const Footers = () => {
    return (
        <Fragment>

            <Header pageTitle="Footers" />
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Footer Curved (Light)</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterCurvedLight />
                {/* Footer Curved (Light) */}

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Footer Curved (Dark)</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterCurvedDark />
                {/* Footer Curved (Dark) */}

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Footer Waved (Light)</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterWavedLignt />
                {/* Footer Waved (Light) */}

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Footer (Light)</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer5 />
                {/* Footer (Light) */}

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Footer (Light-2)</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterLight2 />
                {/* Footer (Light-2) */}

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Footer (dark-1)</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                {/* Footer (dark-1) */}

                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Footer (dark-3)</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterDark3 />
                {/* Footer (dark-3) */}

            </div>
            <section className="p-top-30 p-bottom-100"></section>
            <Footer />
        </Fragment>
    )
}

export default Footers;
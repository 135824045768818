import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import Filter from '../../container/element/filter/filter';
import FilterGrid2 from '../../container/element/filter/filter-grid-2';
import FilterGridMasonry from '../../container/element/filter/filter-grid-masonry';


const Filtercont = () => {
    return (
        <Fragment>

            <Header pageTitle="Filters" />
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Filters- 1</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Filter />
            {/* Filters - 1 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Filters- 2</h3>
                        </div>
                    </div>
                </div>
            </div>
            <FilterGrid2 />
            {/* Filters - 2 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Filters- 3</h3>
                        </div>
                    </div>
                </div>
            </div>
            <FilterGridMasonry />
            {/* Filters - 3 */}

            <section className="p-top-30 p-bottom-100"></section>
            <Footer />
        </Fragment>
    )
}

export default Filtercont;
import $ from "jquery";
import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel2';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

let rtlCheck = $("body").css("direction");
let falsy = false;
if(rtlCheck === "rtl"){
    falsy = true;
}

const options = {
    items: 3,
    margin: 30,
    nav: false,
    rtl: falsy,
    dots: true,
    dotsClass: "owl-dots dot-circle",
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 2
        },
        1000: {
            items: 3
        }
    }
};
class Slider extends Component {
    render() {
        const {blog} = this.props;

        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <OwlCarousel options={options} className="carousel-one owl-carousel">
                            {
                                Object.values(blog).map((value, key) => {
                                    const {id, imgSrc, title, content} = value;
                                    return (
                                        <div className="carousel-one-single" key={id}>
                                            <figure>
                                                <img src={imgSrc} alt="" />
                                            </figure>
                                            <h6><NavLink to={"/news-details"+id} >{title}</NavLink></h6>
                                            <p>{content}</p>
                                        </div>
                                    )
                                })
                            }
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        blog : state.blog
    }
}
export default connect(mapStateToProps)(Slider);
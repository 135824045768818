import React from 'react';

const ContentBlock6 = ()=> {
    return (
        <section className="content-block content-block--6 block-full-width">
            <div className="img bgimage">
                <div className="bg_image_holder">
                    <img src="./assets/img/cb3.png" alt="" />
                </div>
            </div>{/*<!-- ends:.img -->*/}
            <div className="container content_above">
                <div className="row">
                    <div className="col-lg-5 offset-lg-7">
                        <div className="content">
                            <h3>Our Core Features</h3>
                            <p>Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus dynamicus quipro keds sequitur mutay.</p>
                            <div className="m-top-25">
                                <ul className="check-list--one">
                                    <li className="list-item checklist icon-secondary">
                                        <img src="./assets/img/svg/checked.svg" alt="" className="svg" />
                                        Purus euhy dolor tempor fringilla quam
                                    </li>
                                    <li className="list-item checklist icon-secondary">
                                        <img src="./assets/img/svg/checked.svg" alt="" className="svg" />
                                        Curabitur ullam corper wegete
                                    </li>
                                    <li className="list-item checklist icon-secondary">
                                        <img src="./assets/img/svg/checked.svg" alt="" className="svg" />
                                        Quisque euismd pellents massa conuat
                                    </li>
                                    <li className="list-item checklist icon-secondary">
                                        <img src="./assets/img/svg/checked.svg" alt="" className="svg" />
                                        Quisque euismod ellentesque massa
                                    </li>
                                </ul>{/*<!-- ends .check-list--one -->*/}
                            </div>
                        </div>
                    </div>{/*<!-- ends:.col-lg-5 -->*/}
                </div>
            </div>{/*<!-- ends: .container -->*/}
        </section>
    
    )
}
export default ContentBlock6;
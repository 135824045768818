import React, {Fragment} from 'react';
import Navbar from '../navbar/colorNav';
import TopHeader from '../header/topHeader';
import { NavLink } from 'react-router-dom';
const Content_height = () => {      
    return (
        <Fragment>
            <section className="header header--2">
               <TopHeader />
                <Navbar />                
            </section>
            <div className="intro-four bgimage">
                <div className="bg_image_holder">
                    <img src="./assets/img/slider_bg4.jpg" alt="" />
                </div>
                <div className="intro-four--contents content_above">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7">
                                <h1 className="display-3">Best Solution for Your <span>Business</span></h1>
                                <p>Deserunt dolore voluptatem assumenda quae possimus <br /> sunt dignissimos tempora officia.</p>
                                <ul className="list-unstyled">
                                    <li><NavLink to="/service" className="btn btn-primary btn-icon icon-right">Our Services <span className="la la-arrow-right"></span></NavLink></li>
                                    <li><NavLink to="/contact" className="btn btn-light btn-icon icon-right">Contact Us <span className="la la-arrow-right"></span></NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>{/*<!-- ends: .intro-four-contents -->*/}
            </div>
        </Fragment>       
                      
    )   
}


export default Content_height;




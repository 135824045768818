import React, { Fragment, Component } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';


class Teams extends Component {
    render() {
      return (
        <Fragment>

            <Header pageTitle="Timelines" />
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Timeline - 1</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="p-top-85 p-bottom-85 section-bg">
                    <div className="timeline_area section-bg timeline_area--1">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="timeline1">
                                        <div className="happening row">
                                            <div className="happening__period col-md-3">
                                                <div className="wrapper rounded shadow-sm">
                                                    <p className="date">20 January</p>
                                                    <h4 className="year">2002</h4>
                                                </div>
                                            </div>
                                            <div className="happening__desc col-md-8 offset-md-1">
                                                <h5 className="happening_title">Founded Ratri Cloud</h5>
                                                <p>Investiga tiones demonstr averunt lectres legere melius quodqua legunt saepius. Clarias kest etiam pro
                                                cessus dynamicus squitur mutaety tionem consum etudium. Investig ationes the demons trave huerunt
                                                lectores legere liusry. Investiga tiones demonstr averunt.</p>
                                            </div>
                                            </div>{/* ends: .happening */}
                                            <div className="happening row">
                                            <div className="happening__period col-md-3">
                                                <div className="wrapper rounded shadow-sm">
                                                    <p className="date">20 January</p>
                                                    <h4 className="year">2004</h4>
                                                </div>
                                            </div>
                                            <div className="happening__desc col-md-8 offset-md-1">
                                                <h5 className="happening_title">Start With a Small Service</h5>
                                                <p>Investiga tiones demonstr averunt lectres legere melius quodqua legunt saepius. Clarias kest etiam pro
                                                cessus dynamicus squitur mutaety tionem consum etudium. Investig ationes the demons trave huerunt
                                                lectores legere liusry. Investiga tiones demonstr averunt.</p>
                                            </div>
                                            </div>{/* ends: .happening */}
                                            <div className="happening row">
                                            <div className="happening__period col-md-3">
                                                <div className="wrapper rounded shadow-sm">
                                                    <p className="date">20 January</p>
                                                    <h4 className="year">2006</h4>
                                                </div>
                                            </div>
                                            <div className="happening__desc col-md-8 offset-md-1">
                                                <h5 className="happening_title">First Employees</h5>
                                                <p>Investiga tiones demonstr averunt lectres legere melius quodqua legunt saepius. Clarias kest etiam pro
                                                cessus dynamicus squitur mutaety tionem consum etudium. Investig ationes the demons trave huerunt
                                                lectores legere liusry. Investiga tiones demonstr averunt.</p>
                                            </div>
                                            </div>{/* ends: .happening */}
                                            <div className="happening row">
                                            <div className="happening__period col-md-3">
                                                <div className="wrapper rounded shadow-sm">
                                                    <p className="date">20 January</p>
                                                    <h4 className="year">2008</h4>
                                                </div>
                                            </div>
                                            <div className="happening__desc col-md-8 offset-md-1">
                                                <h5 className="happening_title">United Kingdom Office</h5>
                                                <p>Investiga tiones demonstr averunt lectres legere melius quodqua legunt saepius. Clarias kest etiam pro
                                                cessus dynamicus squitur mutaety tionem consum etudium. Investig ationes the demons trave huerunt
                                                lectores legere liusry. Investiga tiones demonstr averunt.</p>
                                            </div>
                                        </div>{/* ends: .happening */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{/* ends: .timeline_area */}
                </section>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Timeline - 2</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="m-bottom-80">
                    <div className="timeline_area timeline_area--2">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="timeline2">
                                        <div className="happening2">
                                            <p className="happening2__period">2010 - 2012</p>
                                            <h6 className="happening2__title">Global Management Committee</h6>
                                            <p>Investig ationes demons trave runt lectores legere liusry quod ile gunt saepius claritas Inves tig ationes. Our management.</p>
                                        </div>{/* ends: .happening2 */}
                                        <div className="happening2">
                                            <p className="happening2__period">2012 - 2013</p>
                                            <h6 className="happening2__title">Member of the Management Board</h6>
                                            <p>Investig ationes demons trave runt lectores legere liusry quod ile gunt saepius claritas Inves tig ationes. Our management.</p>
                                        </div>{/* ends: .happening2 */}
                                        <div className="happening2">
                                            <p className="happening2__period">2015 - 2016</p>
                                            <h6 className="happening2__title">Firm-wide Leader for SAP Business</h6>
                                            <p>Investig ationes demons trave runt lectores legere liusry quod ile gunt saepius claritas Inves tig ationes. Our management.</p>
                                        </div>{/* ends: .happening2 */}
                                    </div>
                                    </div>
                                    <div className="col-md-6">
                                    <div className="timeline2">
                                        <div className="happening2">
                                            <p className="happening2__period">2010 - 2012</p>
                                            <h6 className="happening2__title">Global Management Committee</h6>
                                            <p>Investig ationes demons trave runt lectores legere liusry quod ile gunt saepius claritas Inves tig ationes. Our management.</p>
                                        </div>{/* ends: .happening2 */}
                                        <div className="happening2">
                                            <p className="happening2__period">2012 - 2013</p>
                                            <h6 className="happening2__title">Member of the Management Board</h6>
                                            <p>Investig ationes demons trave runt lectores legere liusry quod ile gunt saepius claritas Inves tig ationes. Our management.</p>
                                        </div>{/* ends: .happening2 */}
                                        <div className="happening2">
                                            <p className="happening2__period">2015 - 2016</p>
                                            <h6 className="happening2__title">Firm-wide Leader for SAP Business</h6>
                                            <p>Investig ationes demons trave runt lectores legere liusry quod ile gunt saepius claritas Inves tig ationes. Our management.</p>
                                        </div>{/* ends: .happening2 */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{/* ends: .timeline_area */}
                </div>
            </div>
            <Footer />
        </Fragment>
      )
    }
}
export default Teams

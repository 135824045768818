import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';


import { CountUp, CountUp1, CountUp2, CountUp3, CountUp4, CountUp5, CountUp7 } from '../../content/element/counter/count';

const ContentBlock = () => {
    return (
        <Fragment>

            <Header pageTitle="Counters & Countdown" />

             {/* Counter - 1 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Counter- 1</h3>
                        </div>
                    </div>
                </div>
            </div>
            <CountUp />
            {/* Counter - 1 */}

            {/* Counter - 2 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Counter- 2</h3>
                        </div>
                    </div>
                </div>
            </div>
            <CountUp1 />
            {/* Counter - 2 */}

            {/* Counter - 3 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Counter- 3</h3>
                        </div>
                    </div>
                </div>
            </div>
            <CountUp2 />
            {/* Counter - 3 */}

            {/* Counter - 4 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Counter- 4</h3>
                        </div>
                    </div>
                </div>
            </div>
            <CountUp3 />
            {/* Counter - 4 */}

            {/* Counter - 5 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Counter- 5</h3>
                        </div>
                    </div>
                </div>
            </div>
            <CountUp4 padding="home8-counter pt-0" />
            {/* Counter - 5 */}

            {/* Counter - 6 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Counter- 6</h3>
                        </div>
                    </div>
                </div>
            </div>
            <CountUp5 />
            {/* Counter - 6 */}

             {/* Counter - 7 */}
             <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Counter- 7</h3>
                        </div>
                    </div>
                </div>
            </div>
            <CountUp7 />
            {/* Counter - 7 */}
            <Footer />
        </Fragment>
    )
}

export default ContentBlock;
import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';

import { ContactForm5, ContactForm, ContactForm3, ContactForm6, FormBox, GetInTouch } from '../../content/element/form/contact-form';

const ContentBlock = () => {
    return (
        <Fragment>

            <Header pageTitle="Contact Form" />

             {/* Content Block - 1 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Contact Form - 1</h3>
                        </div>
                    </div>
                </div>
            </div>
            <ContactForm5 padding="p-top-0 p-bottom-0" />
            {/* Content Block - 1 */}

            {/* Content Block - 2 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Contact Form - 2</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <ContactForm />
                    </div>
                </div>
            </div>
            {/* Content Block - 2 */}

            {/* Content Block - 3 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Contact Form - 3</h3>
                        </div>
                    </div>
                </div>
            </div>
            <ContactForm3 />
            {/* Content Block - 3 */}

            {/* Content Block - 4 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Contact Form - 4</h3>
                        </div>
                    </div>
                </div>
            </div>
            <ContactForm6 />
            {/* Content Block - 4 */}

            {/* Content Block - 5 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Contact Form - 5</h3>
                        </div>
                    </div>
                </div>
            </div>
            <FormBox padding="col-lg-4 offset-lg-4"/>
            {/* Content Block - 5 */}

            {/* Content Block - 6 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Contact Form - 6</h3>
                        </div>
                    </div>
                </div>
            </div>
            <GetInTouch classes="col-md-6 offset-md-3" />
            {/* Content Block - 6 */}

            {/* Content Block - 7 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Contact Form - 7</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="form-wrapper contact--from2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-8 offset-md-2 offset-lg-4">
                            <div className="form-wrapper cardify card--rounded">
                                <h5 className="color-dark m-bottom-30 text-center">Request A Call Back</h5>
                                <form action="#">
                                    <input type="text" placeholder="Your Name" className="form-control fc--rounded m-bottom-20" required />
                                    <input type="text" placeholder="Phone Number" className="form-control fc--rounded m-bottom-20" required />
                                    <div className="form-group m-bottom-20">
                                        <div className="select-basic">
                                            <select className="form-control fc--rounded">
                                                <option>Business Planning</option>
                                                <option>Option 1</option>
                                                <option>Option 2</option>
                                                <option>Option 3</option>
                                                <option>Option 4</option>
                                            </select>
                                        </div>
                                    </div>
                                    <button className="btn btn-primary btn--rounded btn-block">Request Now</button>
                                </form>
                            </div>{/* end: .form-wrapper */}
                        </div>
                    </div>
                </div>
            </section>{/* ends: .form-wrapper */}
            {/* Content Block - 7 */}

             {/* Content Block - 8 */}
             <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Contact Form - 8</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="form-wrapper section-bg py-5 contact--from7">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-4 col-md-8 offset-md-2 offset-lg-4">
                            <div className="form-wrapper cardify card--rounded">
                                <h5 className="m-bottom-30 text-center">Free Consultation</h5>
                                <form action="#">
                                    <input type="text" placeholder="Your Name" className="form-control m-bottom-20" required />
                                    <input type="text" placeholder="Phone Number" className="form-control m-bottom-20" required />
                                    <textarea className="form-control m-bottom-20" placeholder="Messages" required defaultValue={""} />
                                    <button className="btn btn-primary btn-block">Request Now</button>
                                </form>
                            </div>{/* end: .form-wrapper */}
                        </div>
                    </div>
                </div>
            </section>{/* ends: .form-wrapper */}
            {/* Content Block - 8 */}

            <Footer />
        </Fragment>
    )
}

export default ContentBlock;
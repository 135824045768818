export const Cart = prod => {    
    return (dispatch, getState) => {        
       
        new Promise((resolve, reject) => {
            var product = JSON.stringify(prod)
            localStorage.setItem("cart", product)
        }).then(() => {
            dispatch({type: 'CREATE_CART', prod})
        }).catch((err) => {
            dispatch({type: 'CREATE_CART_ERROR', err})
        })


    }
   
}
       
      
       


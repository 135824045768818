import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';


const Process = (props) => {
      return (
        <Fragment>

            <Header pageTitle="Progress Bar" />
            <section className="progress-bar-wrapper section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 mb-sm-50">
                            <div className="progress-wrapper">
                                <div className="labels d-flex justify-content-between">
                                    <span>Business</span>
                                    <span>70%</span>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar bg-secondary" role="progressbar" style={{width: '70%'}} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>{/*<!-- ends: .progress -->*/}
                            </div>{/*<!-- ends: .progress-wrapper -->*/}
                            <div className="progress-wrapper">
                                <div className="labels d-flex justify-content-between">
                                    <span>Corporate</span>
                                    <span>85%</span>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar bg-secondary" role="progressbar" style={{width: '85%'}} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>{/*<!-- ends: .progress -->*/}
                            </div>{/*<!-- ends: .progress-wrapper -->*/}
                            <div className="progress-wrapper">
                                <div className="labels d-flex justify-content-between">
                                    <span>Finance</span>
                                    <span>90%</span>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar bg-secondary" role="progressbar" style={{width: '90%'}} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>{/*<!-- ends: .progress -->*/}
                            </div>{/*<!-- ends: .progress-wrapper -->*/}
                            <div className="progress-wrapper">
                                <div className="labels d-flex justify-content-between">
                                    <span>Strategy</span>
                                    <span>75%</span>
                                </div>
                                <div className="progress">
                                    <div className="progress-bar bg-secondary" role="progressbar" style={{width: '75%'}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>{/*<!-- ends: .progress -->*/}
                            </div>{/*<!-- ends: .progress-wrapper -->*/}
                        </div>{/*<!-- ends: .col-lg-6 -->*/}
                        <div className="col-md-6">
                            <div className="progress-dark">
                                <div className="progress-wrapper">
                                    <div className="labels d-flex justify-content-between">
                                        <span>Business</span>
                                        <span>70%</span>
                                    </div>
                                    <div className="progress">
                                        <div className="progress-bar bg-light" role="progressbar" style={{width: '70%'}} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>{/*<!-- ends: .progress -->*/}
                                </div>{/*<!-- ends: .progress-wrapper -->*/}
                                <div className="progress-wrapper">
                                    <div className="labels d-flex justify-content-between">
                                        <span>Corporate</span>
                                        <span>85%</span>
                                    </div>
                                    <div className="progress">
                                        <div className="progress-bar bg-light" role="progressbar" style={{width: '85%'}} aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>{/*<!-- ends: .progress -->*/}
                                </div>{/*<!-- ends: .progress-wrapper -->*/}
                                <div className="progress-wrapper">
                                    <div className="labels d-flex justify-content-between">
                                        <span>Finance</span>
                                        <span>90%</span>
                                    </div>
                                    <div className="progress">
                                        <div className="progress-bar bg-light" role="progressbar" style={{width: '90%'}} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>{/*<!-- ends: .progress -->*/}
                                </div>{/*<!-- ends: .progress-wrapper -->*/}
                                <div className="progress-wrapper">
                                    <div className="labels d-flex justify-content-between">
                                        <span>Strategy</span>
                                        <span>75%</span>
                                    </div>
                                    <div className="progress">
                                        <div className="progress-bar bg-light" role="progressbar" style={{width: '75%'}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>{/*<!-- ends: .progress -->*/}
                                </div>{/*<!-- ends: .progress-wrapper -->*/}
                            </div>
                        </div>{/*<!-- ends: .col-lg-6 -->*/}
                    </div>
                </div>
            </section>{/*<!-- ends: .progress-bar-wrapper -->                */}
            <Footer />
        </Fragment>
      );
}
export default Process
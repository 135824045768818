import React, {Fragment} from 'react';

const ClientContent = (props) => {
    const {img} = props;
    return (
        <Fragment>
            <li className="d-flex justify-content-center align-items-center">
                <figure>
                    <img src={img} alt="" />
                </figure>
            </li>       
        </Fragment>
    )
}
export default ClientContent;

import React from 'react';
import {NavLink} from 'react-router-dom';
const noAction = e => e.preventDefault();
const Cta6 = () => {
    return (
        <section className="cta-wrapper-sm bg-primary cta--six">
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-lg-9">
                        <p className="m-0 color-light">Are you ready to start your business?</p>
                    </div>
                    <div className="col-lg-3">
                        <div className="action-btn">
                            <NavLink to="/at_demo" onClick={noAction} className="btn btn-secondary">Get a quote</NavLink>
                        </div>
                    </div>
                </div>{/*<!-- ends: .row -->*/}
            </div>
        </section>        
    )
}
export default Cta6;

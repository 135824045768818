import React from 'react';
import {NavLink} from 'react-router-dom';
import FooterItem from './footerItem';
const noAction = (e) => {
    e.preventDefault();
}

const FooterLight1 = () => {

    return (
        <footer className="footer2 footer--light">
            <div className="footer__big">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="widget text_widget">
                                <img className="footer_logo" src="./assets/img/logo.png" alt="logo" />
                                <p>Nunc placerat mi id nisi interdum they mtolis. Praesient is pharetra justo ught scel
                                    erisque the mattis lhreo quam nterdum mollisy.</p>

                                <div className="widget__social">
                                    <div className="social social--small social--gray ">
                                        <ul className="d-flex flex-wrap">
                                            <li><NavLink to="/" onClick={noAction} className="facebook"><span className="fab fa-facebook-f"></span></NavLink></li>
                                            <li><NavLink to="/" onClick={noAction} className="twitter"><span className="fab fa-twitter"></span></NavLink></li>
                                            <li><NavLink to="/" onClick={noAction} className="linkedin"><span className="fab fa-linkedin-in"></span></NavLink></li>
                                            <li><NavLink to="/" onClick={noAction} className="gplus"><span className="fab fa-google-plus-g"></span></NavLink></li>
                                        </ul>
                                    </div>{/*<!-- ends: .social -->*/}
                                </div>
                            </div>
                        </div>{/*<!-- ends: .col-lg-3 -->*/}
                        <FooterItem  color="black" news="white" />

                    </div>
                </div>
            </div>{/*<!-- ends: .footer__big -->*/}
            <div className="footer__small text-center">
                <p>©2019 Ratri Cloud. All rights reserved. Created by <NavLink to="/" onClick={noAction}>AazzTech</NavLink></p>
            </div>{/*<!-- ends: .footer__small -->*/}
        </footer>
    )
}
export default FooterLight1;

import $ from "jquery";
import React, { Component } from 'react';
import TestimonialSixCont from '../../content/testimonial/testimonial-6';
import OwlCarousel from 'react-owl-carousel2';
import {connect} from 'react-redux';

let rtlCheck = $("body").css("direction");
let falsy = false;
if(rtlCheck === "rtl"){
    falsy = true;
}


const options = {
    items: 1,
    rtl: falsy,
    nav: false,
    rewind: true,
    autoplay: true,
    navContainer: false,
    dots : true
}

class TestimonialSix extends Component {
    render() {
        const {testimonial} = this.props

        return (
            <div className="testimonial-carousel-six-wrapper">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12 section-title-primary">
                        {this.props.h3}
                    </div>
                        <div className="col-lg-10 offset-lg-1">
                            <OwlCarousel options={options} className="testimonial-carousel-six owl-carousel">
                            {
                                Object.values(testimonial).map((value, key) => {

                                    return <TestimonialSixCont
                                        name={value.name}
                                        subtitle={value.subTitle}
                                        content={value.content}
                                        img={value.imgSrc}
                                        key={key+1}
                                     />
                                })
                            }
                            </OwlCarousel>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {

    return {
        testimonial : state.testimonial
    }
}
export default connect(mapStateToProps)(TestimonialSix)
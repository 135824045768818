import React from 'react';
import { NavLink } from 'react-router-dom';
const noAction = e => e.preventDefault();
const VideoWraper = (props)=> {
    return (
        <section className={props.padding ? props.padding : "p-bottom-110"}>
          <div className="videos-wrapper video--one">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-5 margin-md-60">
                  <h2>When you’re Ready for Extraordinary</h2>
                  <p>Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus dynamicus, qui sequitur mutaety tionem consu etudium. sInvestig ationes demons trave runt lectores.</p>
                  <NavLink to='/at_demo' onClick={noAction} className="btn btn-primary mt-4">Join Our Team</NavLink>
                </div>
                <div className="col-lg-6 offset-lg-1">
                  <div className="video-single">
                    <figure>
                      <div className="v_img">
                        <img src="./assets/img/simg-2.jpg" alt="" className="rounded" />
                      </div>
                      <figcaption className="d-flex justify-content-center align-items-center shape-wrapper img-shape-two">
                        <a href="https://www.youtube.com/watch?v=omaTcIbwt9c" className="video-iframe play-btn play-btn--lg play-btn--primary"><img src="./assets/img/svg/play-btn.svg" alt="" className="svg" /></a>
                      </figcaption>
                    </figure>
                  </div>{/* ends: .video-single */}
                </div>
              </div>
            </div>
          </div>
        </section>
      );
}
export default VideoWraper;
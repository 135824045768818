import React, {Fragment} from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
const noAction = (e) => {
    e.preventDefault();
}

const Revolution_hero = (props) => {
    const slider = props.slider;

    return (

        <Fragment>
             <div id="rev_slider_24_1_wrapper" className="rev_slider_wrapper fullwidthbanner-container" data-alias="slider4" data-source="gallery" style={{margin:'0px auto',background:'transparent',padding:'0px',marginTop:'0px',marginBottom:'0px'}}>
                {/* <!-- START REVOLUTION SLIDER 5.4.8.1 fullwidth mode --> */}
                <div id="rev_slider_24_1" className="rev_slider fullwidthabanner" style={{display:'none'}} data-version="5.4.8.1">
                    <ul>
                        {
                            Object.values(slider).map((value, key) => {
                                const {imgSrc, thumb, id, title, tagline, tagline2} = value;
                               return (
                                    <li data-index={`rs-${58+id}`} key={id} data-transition="boxslide" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="default" data-thumb={thumb} data-rotate="0" data-saveperformance="off" data-title="Slide" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                                        {/* <!-- MAIN IMAGE --> */}
                                        <img src={imgSrc} alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" className="rev-slidebg" data-no-retina />
                                        {/* <!-- LAYERS --> */}
                                        <div id="rrzm_59" className="rev_row_zone rev_row_zone_middle" style={{zIndex: 5}}>
                                            {/* <!-- LAYER NR. 1 --> */}
                                            <div className="tp-caption  " id="slide-59-layer-1" data-x="100" data-y="center" data-voffset="" data-width="['auto']" data-height="['auto']" data-type="row" data-columnbreak="3" data-responsive_offset="on" data-responsive="off" data-frames='[{"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+8490","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]"  data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 5, whiteSpace: 'nowrap', fontSize: '20px', lineHeight: '22px', fontWeight: 400, color: '#ffffff', letterSpacing: '0px'}}>
                                                {/* <!-- LAYER NR. 2 --> */}
                                                <div className="tp-caption " id="slide-59-layer-2" data-x="100" data-y="100" data-width="['auto']" data-height="['auto']" data-type="column" data-responsive_offset="on" data-frames='[{"delay":"+0","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-columnwidth="16.67%" data-verticalalign="top" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]"  data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 6, width: '100%'}}></div>
                                                {/* <!-- LAYER NR. 3 --> */}
                                                <div className="tp-caption  " id="slide-59-layer-3" data-x="100" data-y="100" data-width="['auto']" data-height="['auto']" data-type="column" data-responsive_offset="on" data-responsive="off" data-frames='[{"delay":"+0","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-columnwidth="66.67%" data-verticalalign="top" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 7, width: '100%'}}>
                                                    {/* <!-- LAYER NR. 4 --> */}
                                                    <center style={{display : 'block'}}>
                                                        <div className="tp-caption   tp-resizeme" id="slide-59-layer-5" data-x="" data-y="" data-width="['auto']" data-height="['auto']" data-type="text" data-responsive_offset="on" data-fontsize="['50', '50', '40', '32']" data-lineheight="['64', '60', '43', '38']" data-frames='[{"delay":"+0","speed":1500,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 8, whiteSpace: 'normal', fontSize: '60px', lineHeight: '72px', fontWeight: 600, color: '#ffffff', letterSpacing: '0px', display: 'block'}}>
                                                            {title}
                                                        </div>
                                                    </center>
                                                    {/* <!-- LAYER NR. 5 --> */}
                                                    <center style={{display : 'block'}}>
                                                        <div className="tp-caption   tp-resizeme" id="slide-59-layer-6" data-x="" data-y="" data-width="['auto']" data-height="['auto']" data-type="text" data-responsive_offset="on" data-fontsize="['20', '20', '18', '16']" data-lineheight="['36', '36', '24', '22']" data-frames='[{"delay":"+1070","speed":2000,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-margintop="[30,30,30,30]" data-marginright="[0,0,0,0]" data-marginbottom="[35,35,35,35]" data-marginleft="[0,0,0,0]"  data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 9, whiteSpace: 'normal', fontSize: '20px', lineHeight: '36px', fontWeight: 400, color: '#ffffff', letterSpacing: '0px', display: 'block'}}>
                                                           {tagline} <br /> {tagline2}
                                                        </div>
                                                    </center>
                                                    {/* <!-- LAYER NR. 6 --> */}
                                                    <center style={{display : 'block'}}>
                                                    <div className="tp-caption" id="slide-59-layer-9" data-x="" data-y="" data-type="text" data-responsive_offset="on" data-frames='[{"delay":"+2290","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]"  data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 11, display: 'inline-block'}}>
                                                        <NavLink to="/demo" onClick={noAction} className="btn btn--rounded btn-secondary">Learn More</NavLink></div>
                                                    {/* <!-- LAYER NR. 7 --> */}
                                                    <div className="tp-caption" id="slide-59-layer-7" data-x="" data-y="" data-type="text" data-responsive_offset="on" data-frames='[{"delay":"+2550","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[30,30,30,30]"  data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 10, display: 'inline-block'}}>
                                                        <NavLink to="/demo" onClick={noAction} className="btn btn--rounded btn-outline-light">Learn More</NavLink></div>
                                                    </center>
                                                </div>
                                                {/* <!-- LAYER NR. 8 --> */}
                                                <div className="tp-caption  " id="slide-59-layer-4" data-x="100" data-y="100" data-width="['auto']" data-height="['auto']" data-type="column" data-responsive_offset="on" data-frames='[{"delay":"+0","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-columnwidth="16.67%" data-verticalalign="top" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]"  data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 12, width: '100%'}}></div>
                                            </div>
                                        </div>
                                    </li>
                               )
                            })
                        }

                    </ul>
                    <div className="tp-bannertimer tp-bottom" style={{visibility: 'hidden !important'}}></div>
                </div>
            </div>
            {/* <!-- END REVOLUTION SLIDER --> */}

        </Fragment>

    )
}
const mapStateToProps = state => {
    return {
        slider : state.slider
    }
}

export default connect(mapStateToProps)(Revolution_hero);


import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';

const Maps = (props) => {
      return (
        <Fragment>

            <Header pageTitle="Pagination" />
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Pagination Left</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="paginations-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="pagination-area">
                                    <nav aria-label="Page navigation pagination-left">
                                        <ul className="pagination">
                                            <li className="page-item"><a className="page-link" href="# ">Previous</a></li>
                                            <li className="page-item"><a className="page-link" href="# ">1</a></li>
                                            <li className="page-item"><a className="page-link" href="# ">2</a></li>
                                            <li className="page-item active"><a className="page-link" href="# ">3 <span className="sr-only">(current)</span></a></li>
                                            <li className="page-item"><a className="page-link" href="# ">4</a></li>
                                            <li className="page-item"><a className="page-link" href="# ">5</a></li>
                                            <li className="page-item"><a className="page-link" href="# ">Next</a></li>
                                        </ul>
                                    </nav>
                                </div>{/* ends: .pagination-wrapper */}
                            </div>
                        </div>
                    </div>
                </section>{/* ends: .paginations-wrapper */}
                <div className="container">
                <div className="row">
                    <div className="col">
                    <div className="showcase showcase--title1">
                        <h3>Pagination Center</h3>
                    </div>
                    </div>
                </div>
                </div>
                <section className="paginations-wrapper">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="pagination-area">
                        <nav aria-label="Page navigation pagination-left">
                            <ul className="pagination justify-content-center">
                            <li className="page-item"><a className="page-link" href="# ">Previous</a></li>
                            <li className="page-item"><a className="page-link" href="# ">1</a></li>
                            <li className="page-item"><a className="page-link" href="# ">2</a></li>
                            <li className="page-item active"><a className="page-link" href="# ">3 <span className="sr-only">(current)</span></a></li>
                            <li className="page-item"><a className="page-link" href="# ">4</a></li>
                            <li className="page-item"><a className="page-link" href="# ">5</a></li>
                            <li className="page-item"><a className="page-link" href="# ">Next</a></li>
                            </ul>
                        </nav>
                        </div>{/* ends: .pagination-wrapper */}
                    </div>
                    </div>
                </div>
                </section>{/* ends: .paginations-wrapper */}
                <div className="container">
                <div className="row">
                    <div className="col">
                    <div className="showcase showcase--title1">
                        <h3>Pagination Right</h3>
                    </div>
                    </div>
                </div>
                </div>
                <section className="paginations-wrapper">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="pagination-area">
                        <nav aria-label="Page navigation pagination-left">
                            <ul className="pagination justify-content-end">
                            <li className="page-item"><a className="page-link" href="#  ">Previous</a></li>
                            <li className="page-item"><a className="page-link" href="#  ">1</a></li>
                            <li className="page-item"><a className="page-link" href="#  ">2</a></li>
                            <li className="page-item active"><a className="page-link" href="#  ">3 <span className="sr-only">(current)</span></a></li>
                            <li className="page-item"><a className="page-link" href="#  ">4</a></li>
                            <li className="page-item"><a className="page-link" href="#  ">5</a></li>
                            <li className="page-item"><a className="page-link" href="#  ">Next</a></li>
                            </ul>
                        </nav>
                        </div>{/* ends: .pagination-wrapper */}
                    </div>
                    </div>
                </div>
                </section>{/* ends: .paginations-wrapper */}
                <div className="container">
                <div className="row">
                    <div className="col">
                    <div className="showcase showcase--title1">
                        <h3>Pagination Arrows</h3>
                    </div>
                    </div>
                </div>
                </div>
                <section className="paginations-wrapper">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="pagination-area">
                        <nav aria-label="Page navigation pagination-left">
                            <ul className="pagination justify-content-center">
                            <li className="page-item"><a className="page-link" href="# "><i className="la la-angle-double-left" /></a></li>
                            <li className="page-item"><a className="page-link" href="# ">1</a></li>
                            <li className="page-item"><a className="page-link" href="# ">2</a></li>
                            <li className="page-item active"><a className="page-link" href="# ">3 <span className="sr-only">(current)</span></a></li>
                            <li className="page-item"><a className="page-link" href="# ">4</a></li>
                            <li className="page-item"><a className="page-link" href="# ">5</a></li>
                            <li className="page-item"><a className="page-link" href="# "><i className="la la-angle-double-right" /></a></li>
                            </ul>
                        </nav>
                        </div>{/* ends: .pagination-wrapper */}
                    </div>
                    </div>
                </div>
                </section>{/* ends: .paginations-wrapper */}
                <div className="container">
                <div className="row">
                    <div className="col">
                    <div className="showcase showcase--title1">
                        <h3>Pagination Rounded</h3>
                    </div>
                    </div>
                </div>
                </div>
                <section className="paginations-wrapper m-bottom-70">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="pagination-area">
                        <nav aria-label="Page navigation pagination-left">
                            <ul className="pagination pagination-rounded justify-content-center">
                            <li className="page-item"><a className="page-link" href="# "><i className="la la-angle-left" /></a></li>
                            <li className="page-item"><a className="page-link" href="# ">1</a></li>
                            <li className="page-item"><a className="page-link" href="# ">2</a></li>
                            <li className="page-item active"><a className="page-link" href="# ">3 <span className="sr-only">(current)</span></a></li>
                            <li className="page-item"><a className="page-link" href="# ">4</a></li>
                            <li className="page-item"><a className="page-link" href="# ">5</a></li>
                            <li className="page-item"><a className="page-link" href="# "><i className="la la-angle-right" /></a></li>
                            </ul>
                        </nav>
                        </div>{/* ends: .pagination-wrapper */}
                    </div>
                    </div>
                </div>
                </section>{/* ends: .paginations-wrapper */}
            </div>
            <Footer />
        </Fragment>
      );
}

export default Maps
import React from 'react';

const TestimonialFiveCon = (props) => {
    const {img, name, content} = props;
    return (
        <div className="carousel-single">
            <div className="author-text">
                <p>{content}</p>
            </div>
            <div className="author-spec d-flex">
                <div className="author-img">
                    <img src={img} alt="" className="rounded-circle" />
                </div>
                <div className="author-desc">
                    <h6>{name}</h6>
                    <span className="color-secondary">aazztech.com</span>
                </div>
            </div>
        </div>
        // <!-- end: .carousel-single -->
    )
}
export default TestimonialFiveCon;
import $ from "jquery";
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel2';
import {connect} from 'react-redux';

let rtlCheck = $("body").css("direction");
let falsy = false;
if(rtlCheck === "rtl"){
    falsy = true;
}
const options = {
    items: 3,
    rtl: falsy,
    nav: true,
    navText: ['<i class="la la-angle-left"></i>', '<i class="la la-angle-right"></i>'],
    navContainerClass: "nav-square nav-square-dark",
    dots: false,
    responsive: {
        0: {
            items: 1
        },
        768:{
            items:2
        },
        1000: {
            items: 3
        }
    },
    navElement: 'button'
};
class Team_wrap extends Component {
    render() {
        const {team} = this.props;
        return (
            <OwlCarousel options={options} className="team-carousel-one owl-carousel">
            {
                Object.values(team).slice(10, 14).map((value, key) => {
                    const {imgSrc, id, name, designation} = value;
                    return (
                        <div className="carousel-single" key={id}>
                            <div className="card card-shadow card--team1">
                                <div className="card-body text-center">
                                    <img src={imgSrc} alt="" className="rounded-circle" />
                                    <h6><a href="team-single.html">{name}</a></h6>
                                    <span className="team-designation">{designation}</span>
                                    <ul className="team-social d-flex justify-content-center">
                                        <li><NavLink to="#"><i className="fab fa-google-plus-g"></i></NavLink></li>
                                        <li><NavLink to="#"><i className="fab fa-facebook-f"></i></NavLink></li>
                                        <li><NavLink to="#"><i className="fab fa-twitter"></i></NavLink></li>
                                        <li><NavLink to="#"><i className="fab fa-linkedin-in"></i></NavLink></li>
                                    </ul>
                                </div>
                            </div>{/*<!-- End: .card -->*/}
                        </div>
                    )
                })
            }
            </OwlCarousel>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        team : state.team
    }
}
export default connect(mapStateToProps)(Team_wrap);
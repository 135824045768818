import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';


const EmbedVideo = () => {
    return (
        <Fragment>

            <Header pageTitle="Embed Videos" />
            <section className="embed-videos p-top-0 p-bottom-100">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Embed Videos</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 margin-md-60">
                            <div className="embeds embed-responsive embed-responsive-16by9">
                                <iframe title="video" className="embed-responsive-item" src="https://player.vimeo.com/video/84455701" allowFullScreen />
                            </div>{/* End: .embeds */}
                        </div>
                        <div className="col-lg-6">
                            <div className="embeds embed-responsive embed-responsive-16by9">
                                <iframe title="video" className="embed-responsive-item" src="https://www.youtube.com/embed/ssutK1Gei4A?rel=0" allow="autoplay; encrypted-media" allowFullScreen />
                            </div>{/* End: .embeds */}
                        </div>
                    </div>
                </div>
            </section>{/* ends: .embed-videos */}
            <Footer />
        </Fragment>
    )
}

export default EmbedVideo;
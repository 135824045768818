import React from 'react';
import {NavLink} from 'react-router-dom';
const TopHeader = () => {
    return (
        <div className="top_bar top--bar2 d-flex align-items-center bg-primary">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="d-flex topbar_content justify-content-between">
                            <div className="top_bar--lang align-self-center order-2">
                                <div className="dropdown">
                                    <div className="dropdown-toggle d-flex align-items-center" id="dropdownMenuButton1" role="menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span className="lang">en</span>
                                        <img className="lang_flag" src="./assets/img/en.png" alt="English" />
                                        <span className="la la-angle-down"></span>
                                    </div>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <NavLink className="dropdown-item" data-lang="en" to="#"><img src="./assets/img/en.png" alt="" />English</NavLink>
                                        {/*<NavLink className="dropdown-item" data-lang="fr" to="#"><img src="./assets/img/fr.jpg" alt="" />Français</NavLink>
                                        <NavLink className="dropdown-item" data-lang="tr" to="#"><img src="./assets/img/tr.jpg" alt="" />Türkçee</NavLink>
                                        <NavLink className="dropdown-item" data-lang="es" to="#"><img src="./assets/img/es.jpg" alt="" />Español</NavLink>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="top_bar--info order-0 d-none d-lg-block align-self-center">
                                <ul>
                                    <li><span className="la la-clock-o"></span>
                                        <p>Mon-Sat 8.00 - 18.00</p>
                                    </li>
                                    <li><span className="la la-envelope"></span>
                                        <p>enquiry@ratricloud.com</p>
                                    </li>
                                    <li><span className="la la-headphones"></span>
                                        <p>+6 03-9212 2805</p>
                                    </li>
                                    <li><span className="la la-phone"></span>
                                        <p>+6 016-9370093</p>
                                    </li>
                                    <li><span className=""></span>
                                        <img src="./assets/img/phone_qr.png" alt="" />
                                    </li>


                                </ul>
                            </div>
                            <div className="top_bar--social">
                                <ul>
                                    <li><a href="https://www.facebook.com/Ratri-Cloud-100615298388092/" target="_blank"><span className="fab fa-facebook-f"></span></a></li>
                                    <li><a href="https://www.instagram.com/ratri.cloud/" target="_blank"><span className="fab fa-instagram"></span></a></li>
                                    {/*<li><NavLink to="#"><span className="fab fa-twitter"></span></NavLink></li>
                                    <li><NavLink to="#"><span className="fab fa-vimeo-v"></span></NavLink></li>
                                    <li><NavLink to="#"><span className="fab fa-linkedin-in"></span></NavLink></li>*/}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopHeader;

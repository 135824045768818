import React from 'react';

const ContentBlock15 = (props)=> {
    return (
        <section className={props.padding ? props.padding : "content-block content-block--15"}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 section-title">
              <h2>Amazing Business Solution <br /> Whatever Your Needs</h2>
              <p>We provide highly professional services of emerging technology that make your business more efficient and unique.</p>
            </div>{/* ends: .section-title */}
            <div className="col-lg-12 m-bottom-20">
              <div className="row align-items-center">
                <div className="col-lg-5 order-lg-0 order-1">
                  <div className="content-desc">
                    <h4>Software Development</h4>
                    <p>MoonoSys is a growing software company. It provides unique solutions blended with the latest technologies.
                      <br /><br /> From the beginning of development, our developers provide extensive development support, discussing ideas, getting project plans and insight.</p>
                  </div>
                </div>
                <div className="col-lg-6 offset-lg-1 order-lg-1 order-0">
                  <img src="./assets/img/service1.png" alt="" className="img-fluid" />
                </div>
              </div>
            </div>{/* ends: .col-lg-12 */}
            <div className="col-lg-12 m-bottom-20">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <img src="./assets/img/service2.png" alt="" className="img-fluid" />
                </div>
                <div className="col-lg-5 offset-lg-1">
                  <div className="content-desc">
                    <h4>Hardware Supply</h4>
                    <p>We provide genuine and professional recommendations on everything from door lock system to WIFI and CCTV. We tailor each solution to meet our customers's specific needs.
                      <br /><br /> We also will take care on the Installation, Warranty and most importantly Customer Service.</p>
                  </div>
                </div>
              </div>
            </div>{/* ends: .col-lg-12 */}
            <div className="col-lg-12">
              <div className="row align-items-center">
                <div className="col-lg-5 order-lg-0 order-1">
                  <div className="content-desc">
                    <h4>Enterprise Solution & Advisory</h4>
                    <p>We are an enterprise solutions architect for the business and offers ultimate resources for your company.
                      <br /><br /> Our solutions are based on unparalleled growth, valuable database, top-notch services, Data personalization features, better architecture and design.</p>
                  </div>
                </div>
                <div className="col-lg-6 offset-lg-1 order-lg-1 order-0 margin-md-60">
                  <img src="./assets/img/service3.png" alt="" className="img-fluid" />
                </div>
              </div>
            </div>{/* ends: .col-lg-12 */}
          </div>
        </div>
      </section>
    )
}
export default ContentBlock15;

import $ from "jquery";
import React from 'react';
import { connect } from 'react-redux';
import OwlCarousel from 'react-owl-carousel2';

let rtlCheck = $("body").css("direction");
let falsy = false;
if(rtlCheck === "rtl"){
    falsy = true;
}
const options = {
    items: 5,
    margin: 30,
    rtl: falsy,
    nav: true,
    navText: ['<i class="la la-angle-left"></i>', '<i class="la la-angle-right"></i>'],
    navContainerClass: "nav-square nav-square-dark",
    dots: false,
    navElement: 'button',
    responsive: {
        0: {
            items: 1
        },
        600:{
            items:3
        },
        992:{
            items:5
        }
    }
}
const LogoCarousel = props => {
    const {client} = props;
    return (
        <section className={props.padding ? props.paddding : "section-padding"}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <OwlCarousel options={options} className="logo-carousel-three owl-carousel">
                            {
                                Object.values(client[0].clients).map((value, key) => {
                                    const {id, imgSrc} = value;
                                    return (
                                        <div className="carousel-single" key={id}>
                                            <div className="logo-box">
                                                <img src={imgSrc} alt="" />
                                            </div>{/*<!-- ends: .logo-box -->*/}
                                        </div>
                                    )
                                })
                            }
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
    )
}
const mapStateToProps = state => {
    return {
        client : state.client
    }
}
export default connect(mapStateToProps)(LogoCarousel);
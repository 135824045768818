import React, { Fragment, Component } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import TwitterFeeds from '../../container/testimonial/twitter-feeds';
import TwitterFeeds2 from '../../container/testimonial/twitter-feeds-2';


class Teams extends Component {
    render() {
      return (
        <Fragment>

            <Header pageTitle="Teams" />

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Twitter Feed - 1</h3>
                        </div>
                    </div>
                </div>
            </div>
            <TwitterFeeds />
            {/* Twitter Feed - 1 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Twitter Feed - 2</h3>
                        </div>
                    </div>
                </div>
            </div>
            <TwitterFeeds2 classes="twitter-feeds twitter-feeds--light pt-0 p-bottom-110" />
            {/* Twitter Feed - 2 */}

            <Footer />
        </Fragment>
      )
    }
}
export default Teams

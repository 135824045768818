import React from 'react';
import { NavLink } from 'react-router-dom';
const noAction = e => e.preventDefault();
export const PricingOne = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-4 col-md-6">
                    <div className="pricing pricing--1 shadow-lg-2">
                        <div className="pricing__title">
                            <h4>Basic</h4>
                            <span>Basic Solution</span>
                        </div>
                        <div className="pricing__price rounded">
                            <p><sup>From RM</sup>5<small>/month</small></p>
                        </div>
                        <div className="pricing__features">
                            <ul>
                                <li>RM5 per room monthly</li>
                                <li>Front Desk management system</li>
                            </ul>{/*<!-- end: .pricing__features > .pricing -->*/}
                        </div>
                        {/*<NavLink to="#" onClick={noAction} className="btn btn-outline-secondary">purchase</NavLink>*/}
                    </div>{/*<!-- end: .pricing -->*/}
                </div>{/*<!-- ends .col-lg-4 -->*/}
                <div className="col-lg-4 col-md-6">
                    <div className="pricing pricing--1 shadow-lg-2">
                        <div className="pricing__title">
                            <h4>Add-On 1</h4>
                            <span>Multi Properties</span>
                        </div>
                        <div className="pricing__price rounded">
                            <p><sup>From RM</sup>5<small>/month</small></p>
                        </div>
                        <div className="pricing__features">
                            <ul>
                                <li>RM5 per property monthly</li>
                                <li>Front Desk management system</li>
                                <li>Access to multi properties from a single login</li>
                            </ul>{/*<!-- end: .pricing__features > .pricing -->*/}
                        </div>
                       {/* <NavLink to="#" onClick={noAction} className="btn btn-outline-secondary">purchase</NavLink>*/}
                    </div>{/*<!-- end: .pricing -->*/}
                </div>{/*<!-- ends .col-lg-4 -->*/}
                <div className="col-lg-4 col-md-6">
                    <div className="pricing pricing--1 shadow-lg-2">
                        <div className="pricing__title">
                            <h4>Add-On 2</h4>
                            <span>Channel Manager</span>
                        </div>
                        <div className="pricing__price rounded">
                            <p><sup>From RM</sup>50<small>/month</small></p>
                        </div>
                        <div className="pricing__features">
                            <ul>
                                <li>RM50 per OTA monthly</li>
                                <li>Receive OTAs booking automatically from management system</li>
                                <li>Update room allotments automatically from management system</li>
                            </ul>{/*<!-- end: .pricing__features > .pricing -->*/}
                        </div>
                        {/*<NavLink to="#" onClick={noAction} className="btn btn-outline-secondary">purchase</NavLink>*/}
                    </div>{/*<!-- end: .pricing -->*/}
                </div>{/*<!-- ends .col-lg-4 -->*/}
            </div>
        </div>
    )
}

export const PricingTwo = () => {
    return (
        <section className="p-top-100 p-bottom-80 sectionbg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="m-bottom-50">
                            <div className="divider divider-simple text-center">
                                <h3>Pricing Plans</h3>
                            </div>
                        </div>
                    </div>{/*<!-- ends: .col-lg-12 -->*/}
                    <div className="col-lg-12">
                        <div className="pricing pricing--2 shadow-lg-2 d-flex">
                            <div className="flex-1">
                                <div className="pricing__features_list">
                                    <p>Simple, flexible, and predictable pricing. Choose which package
                                        is best suited for you.</p>
                                    <ul className="list">
                                        <li>Limitless Concepts</li>
                                        <li>Annual Reports</li>
                                        <li>Free Support</li>
                                        <li>Expert Reviews</li>
                                        <li>Community Access</li>
                                    </ul>
                                </div>
                            </div>{/*<!-- end: .pricing__features_list -->*/}
                            <div className="pricing__wrapper d-flex">
                                <div className="pricing_option">
                                    <div className="pricing__title">
                                        <h4>Starter</h4>
                                        <span>Basic Solutions</span>
                                    </div>
                                    <div className="pricing__price rounded">
                                        <p><sup>$</sup>29<small>/month</small></p>
                                    </div>
                                    <ul className="favail">
                                        <li>20 concept</li>
                                        <li><i className="la la-close"></i></li>
                                        <li><i className="la la-check"></i></li>
                                        <li><i className="la la-check"></i></li>
                                        <li><i className="la la-close"></i></li>
                                    </ul>
                                    <NavLink to="/at_demo" onClick={noAction} className="btn btn-secondary">purchase</NavLink>
                                </div>{/*<!-- End .pricing_option -->*/}
                                <div className="pricing_option">
                                    <div className="pricing__title">
                                        <h4>professional</h4>
                                        <span>Advanced Solutions</span>
                                    </div>
                                    <div className="pricing__price rounded">
                                        <p><sup>$</sup>49<small>/month</small></p>
                                    </div>
                                    <ul className="favail">
                                        <li>20 concept</li>
                                        <li><i className="la la-close"></i></li>
                                        <li><i className="la la-check"></i></li>
                                        <li><i className="la la-check"></i></li>
                                        <li><i className="la la-close"></i></li>
                                    </ul>
                                    <NavLink to="/at_demo" onClick={noAction} className="btn btn-secondary">purchase</NavLink>
                                </div>{/*<!-- End .pricing_option -->*/}
                                <div className="pricing_option">
                                    <div className="pricing__title">
                                        <h4>enterprise</h4>
                                        <span>Customizable Solutions</span>
                                    </div>
                                    <div className="pricing__price rounded">
                                        <p><sup>$</sup>79<small>/month</small></p>
                                    </div>
                                    <ul className="favail">
                                        <li>20 concept</li>
                                        <li><i className="la la-close"></i></li>
                                        <li><i className="la la-check"></i></li>
                                        <li><i className="la la-check"></i></li>
                                        <li><i className="la la-close"></i></li>
                                    </ul>
                                    <NavLink to="/at_demo" onClick={noAction} className="btn btn-secondary">purchase</NavLink>
                                </div>{/*<!-- End .pricing_option -->*/}
                            </div>{/*<!-- end: .pricing__wrapper -->*/}
                        </div>
                    </div>{/*<!-- ends: .col-lg-12 -->*/}
                </div>
            </div>{/*<!-- ends: .container -->*/}
        </section>
    )
}
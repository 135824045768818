import React, { Fragment, Component } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';


class Subscrib extends Component {
    render() {
      return (
        <Fragment>

            <Header pageTitle="Typography" />
            <section className="typography-area p-top-100 p-bottom-50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="showcase-box heading-styles">
                                <div className="showcase-header">
                                    <h4>Heading Styles</h4>
                                    <p>All HTML headings, <span>&lt;h1&gt;</span> through <span>&lt;h6&gt;</span> are available.</p>
                                </div>
                                <div className="showcase-body">
                                    <div className="heading-group">
                                        <h1>h1. Default Heading</h1>
                                        <h2>h2. Default Heading</h2>
                                        <h3>h3. Default Heading</h3>
                                        <h4>h4. Default Heading</h4>
                                        <h5>h5. Default Heading</h5>
                                        <h6>h6. Default Heading</h6>
                                </div>
                                </div>
                            </div>{/* ends: .showcase-box */}
                        </div>
                        <div className="col-lg-12">
                            <div className="showcase-box heading-styles">
                                <div className="showcase-header">
                                    <h4>Heading Colors</h4>
                                    <p>All HTML headings, <span>&lt;h1&gt;</span> through <span>&lt;h6&gt;</span> are available.</p>
                                </div>
                                <div className="showcase-body">
                                    <div className="heading-group">
                                        <h1 className="text-primary">h1. Default Heading</h1>
                                        <h2 className="text-secondary">h2. Default Heading</h2>
                                        <h3 className="text-success">h3. Default Heading</h3>
                                        <h4 className="text-info">h4. Default Heading</h4>
                                        <h5 className="text-warning">h5. Default Heading</h5>
                                        <h6 className="text-danger">h6. Default Heading</h6>
                                    </div>
                                </div>
                            </div>{/* ends: .showcase-box */}
                        </div>
                        <div className="col-lg-12">
                            <div className="showcase-box heading-styles">
                                <div className="showcase-header">
                                    <h4>Display Heading Styles</h4>
                                </div>
                                <div className="showcase-body">
                                    <div className="heading-group">
                                        <h1 className="display-1">Display 1</h1>
                                        <h1 className="display-2">Display 2</h1>
                                        <h1 className="display-3">Display 3</h1>
                                    </div>
                                </div>
                            </div>{/* ends: .showcase-box */}
                        </div>
                        <div className="col-lg-12">
                            <div className="showcase-box heading-styles">
                                <div className="showcase-header">
                                    <h4>Headings with Sub-Heading</h4>
                                </div>
                                <div className="showcase-body">
                                    <div className="sub_heading-group">
                                        <p className="subHeading-top text-primary">Small Sub-Heading</p>
                                        <h1 className="display-1">Default Heading</h1>
                                    </div>
                                <div className="sub_heading-group">
                                    <h2>Default Heading</h2>
                                    <p className="subHeading-bottom">A short sub-text to define the heading</p>
                                </div>
                                </div>
                            </div>{/* ends: .showcase-box */}
                        </div>
                        <div className="col-lg-12">
                            <div className="showcase-box lead-styles">
                                <div className="showcase-header">
                                    <h4>Lead Paragraph</h4>
                                    <p>Make a paragraph stand out by adding <span>.lead</span></p>
                                </div>
                                <div className="showcase-body">
                                    <p className="lead lead--medium">Six basic beliefs that guide and encourage us to achieve lasting success for our clients and firm.</p>
                                    <p className="lead">Six basic beliefs that guide and encourage us to achieve lasting success for our clients and firm.</p>
                                </div>
                            </div>{/* ends: .showcase-box */}
                        </div>
                        <div className="col-lg-12">
                            <div className="showcase-box">
                                <div className="showcase-header">
                                    <h4>Standard Paragraph</h4>
                                    <p>Standard paragraph example</p>
                                </div>
                                <div className="showcase-body">
                                    <p>Investiga tiones demonstr averunt lectores legere me lius quod ii qua legunt saepius. Claritas est etiam pro cessus dynamicus quipro sequitur mutay tionem consue tudium torum. Mirum est notare quam littera gothica quam.</p>
                                </div>
                            </div>{/* ends: .showcase-box */}
                        </div>
                        <div className="col-lg-12">
                            <div className="showcase-box">
                                <div className="showcase-header">
                                    <h4>Blockquotes</h4>
                                    <p>Blockquotes example</p>
                                </div>
                                <div className="showcase-body">
                                    <div className="m-bottom-30">
                                        {/* start blockquote */}
                                        <blockquote className="blockquote blockquote3">
                                            <p>Crocal has the best responsive function on the market is very fast, good adaptable and easy to handle. Support responds quickly and is helpful lorem ipsum dolor sit amet.</p>
                                            <div className="quote-author">
                                                <p><span>Jeff Collins,</span> Founder of Ratri Cloud Inc.</p>
                                            </div>
                                        </blockquote>{/* end: blockquote */}
                                    </div>
                                    <div className="m-bottom-30">
                                        <blockquote className="blockquote blockquote4">
                                            <p>My focus areas are on global standardization and harmonization of business processes lorem dolor is reorganization of marketing and customer.</p>
                                            <div className="quote-author">
                                                <p><span>Jeff Collins,</span> Founder of Ratri Cloud Inc.</p>
                                            </div>
                                        </blockquote>{/* end: blockquote */}
                                    </div>
                                    <div className="m-bottom-30">
                                        {/* start blockquote */}
                                        <blockquote className="blockquote blockquote1 ">
                                            <p>My focus areas are on global standardization and harmonization of business processes lorem dolor is reorganization of marketing and customer standardization and harmonization.</p>
                                            <div className="quote-author">
                                                <p><span>Jeff Collins,</span> Founder of Ratri Cloud Inc.</p>
                                            </div>
                                        </blockquote>{/* end: blockquote */}
                                    </div>
                                    <div>
                                        <blockquote className="blockquote blockquote2 ">
                                            <p>My focus areas are on global standardization and harmonization of business processes, reorganization of marketing and customer.</p>
                                            <div className="quote-author">
                                                <p><span>Jeff Collins,</span> Founder of Ratri Cloud Inc.</p>
                                            </div>
                                        </blockquote>{/* end: blockquote */}
                                    </div>
                                </div>
                            </div>{/* ends: .showcase-box */}
                        </div>
                        <div className="col-lg-12">
                            <div className="showcase-box">
                                <div className="showcase-header">
                                <h4>Text Alignments</h4>
                                    <p>Easily realign text to components with text alignment classes.</p>
                                </div>
                                <div className="showcase-body">
                                    <p className="text-left">Left aligned text on all viewport sizes.</p>
                                    <p className="text-center">Center aligned text on all viewport sizes.</p>
                                    <p className="text-right">Right aligned text on all viewport sizes.</p>
                                </div>
                            </div>{/* ends: .showcase-box */}
                        </div>
                        <div className="col-lg-12">
                            <div className="showcase-box">
                                <div className="showcase-header">
                                    <h4>Text Transform</h4>
                                    <p>Transform text in components with text capitalization classes.</p>
                                </div>
                                <div className="showcase-body">
                                    <p className="text-lowercase">Lowercased text.</p>
                                    <p className="text-uppercase">Uppercased text.</p>
                                    <p className="text-capitalize">CapiTaliZed text.</p>
                                </div>
                            </div>{/* ends: .showcase-box */}
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Fragment>
      )
    }
}
export default Subscrib

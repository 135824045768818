import React from 'react';

const ContentBlock5 = ()=> {
    return (
        <section className="p-top-100 sectionbg p-bottom-110">
        <div className="container">
            <div className="row">
                <div className="col-lg-12 m-bottom-50">
                    <div className="divider section-title-simple">
                        <h1>Key Initiative</h1>
                        <p className="m-auto mb-0">Investiga tiones demonstr averunt lectres legere me lius quod qua legunt saepius estre etiam cessus.</p>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="content-block section-bg section-feature-boxes">
            <div className="container">
                <div className="section-img">
                    <img src="./assets/img/cb5.png" alt="" />
                </div>
                <div className="row">
                    <div className="col-lg-6 offset-lg-6">
                        <div className="feature-boxes row">
                            <div className="col-md-6">
                                <div className="icon-box-nine text-center">
                                    <span className="icon-rounded-sm"><i className="la la-sun-o"></i></span>
                                    <h6>Innovation</h6>
                                    <p className="">Investig ationes demons legere lyrus quod legunt itasest demons.</p>
                                </div>{/*<!-- ends: .icon-box -->*/}
                            </div>{/*<!-- ends: .col-md-3 -->*/}
                            <div className="col-md-6">
                                <div className="icon-box-nine text-center">
                                    <span className="icon-rounded-sm"><i className="la la-cart-arrow-down"></i></span>
                                    <h6>Go Digital</h6>
                                    <p className="">Investig ationes demons legere lyrus quod legunt itasest demons.</p>
                                </div>{/*<!-- ends: .icon-box -->*/}
                            </div>{/*<!-- ends: .col-md-3 -->*/}
                            <div className="col-md-6">
                                <div className="icon-box-nine text-center">
                                    <span className="icon-rounded-sm"><i className="la la-bar-chart"></i></span>
                                    <h6>Regulation</h6>
                                    <p className="">Investig ationes demons legere lyrus quod legunt itasest demons.</p>
                                </div>{/*<!-- ends: .icon-box -->*/}
                            </div>{/*<!-- ends: .col-md-3 -->*/}
                            <div className="col-md-6">
                                <div className="icon-box-nine text-center">
                                    <span className="icon-rounded-sm"><i className="la la-money"></i></span>
                                    <h6>Excellence</h6>
                                    <p className="">Investig ationes demons legere lyrus quod legunt itasest demons.</p>
                                </div>{/*<!-- ends: .icon-box -->*/}
                            </div>{/*<!-- ends: .col-md-3 -->*/}
                        </div>
                    </div>{/*<!-- ends: .col-lg-6 -->*/}
                </div>
            </div>
        </div>{/*<!-- ends: .content-block -->*/}
    </section>
    )
}
export default ContentBlock5;
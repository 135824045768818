import React, {Component, Fragment} from 'react';
import NavItem from "./navItem";

class NabbarColor3 extends Component {    
        render() {  
          return (
            <Fragment>
                {/* <!-- start menu area --> */}
                <div className="menu_area menu3">                    
                    <div className="navbar navbar-expand-lg navbar-dark bg-dark px-0">
                        <nav className="container">
                            <NavItem />                    
                        </nav>
                    </div>
                </div>
                {/* <!-- end menu area --> */}
            </Fragment>
        );
    }
}
export default NabbarColor3;
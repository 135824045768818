import React, { Fragment, Component } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import TopHeader from '../../layout/header/topHeader';
import TopHeader2 from '../../layout/header/top-header-2';
import NabbarColor from '../../layout/navbar/colorNav';
import NabbarColor2 from '../../layout/navbar/colorNav-2';
import NabbarColor3 from '../../layout/navbar/colorNav-3';
import NabbarTrans from '../../layout/navbar/transNav';
import NabbarTrans2 from '../../layout/navbar/transNav-2';


class Headers extends Component {
    render() {
      return (
        <Fragment>

            <Header pageTitle="Headers" />

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Headers - 1</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="header header--2">
                <TopHeader />
                <NabbarColor />
            </section>
            {/* Headers - 1 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Headers - 2</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="header header--2">
                <TopHeader2 />
                <NabbarColor2 />
            </section>
            {/* Headers - 2 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Headers - 2</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="header header--3">
                <TopHeader2 />
                <NabbarColor3 />
            </section>
            {/* Headers - 2 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Headers - 3</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="intro-area">
                <div className="">
                    <div className="intro-area-11">
                        <NabbarTrans />
                    </div>
                </div>
            </section>
            {/* Headers - 3 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Headers - 4</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="intro-area-two m-bottom-110">
                <div className="">
                    <NabbarTrans2 />
                </div>
            </section>
            {/* Headers - 4 */}

            <Footer />
        </Fragment>
      )
    }
}
export default Headers

import React, { Fragment } from 'react';
import Header from '../layout/header/singlePageHeader';
import Footer from '../layout/footer/footer-5';
import Map2 from '../content/element/map/map-2';
import { GetInTouch } from '../content/element/form/contact-form';


const Contact = () => {
    return (
        <Fragment>

            <Header pageTitle='Contact Us' />
            <section className="contact-split d-flex align-items-center">
                <Map2 />
                <GetInTouch />
            </section>
            <Footer />
        </Fragment>
    )
}

export default Contact;

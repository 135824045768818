import clientReducer from './clientReducer';
import testReducer from './testimonialReducer';
import blogReducer from './blogReducer';
import teamReducer from './teamReducer';
import slideReducer from './slidReducer';
import projectReducer from './projectReducer';
import productReducer from './productReducer';
import cartReducer from './cartReducer';
import eventReducer from './eventReducer';
import hardwareReducer from './hardwareReducer';
import consultationReducer from './consultationReducer';

import {combineReducers} from 'redux';

const rootReducer = combineReducers({
    client: clientReducer,
    testimonial: testReducer,
    blog: blogReducer,
    team: teamReducer,
    slider: slideReducer,
    project: projectReducer,
    product: productReducer,
    cart: cartReducer,
    event: eventReducer,
    hardware: hardwareReducer,
    consultation: consultationReducer
})

export default rootReducer;

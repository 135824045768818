import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';


const Blockquotes = () => {
    return (
        <Fragment>

            <Header pageTitle="Buttons" />

            <section className="buttons-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="showcase-box">
                                <div className="showcase-header">
                                    <h4>Basic buttons</h4>
                                </div>
                                <div className="showcase-body">
                                    <div className="button-group">
                                        <button type="button" className="btn btn-primary">Primary</button>
                                        <button type="button" className="btn btn-secondary">Secondary</button>
                                        <button type="button" className="btn btn-success">Success</button>
                                        <button type="button" className="btn btn-info">Info</button>
                                        <button type="button" className="btn btn-warning">Warning</button>
                                        <button type="button" className="btn btn-danger">Danger</button>
                                        <button type="button" className="btn btn-light">Light</button>
                                        <button type="button" className="btn btn-dark">Dark</button>
                                        <button type="button" className="btn btn-gradient">Gradient</button>
                                    </div>
                                </div>{/*<!-- Ends: .showcase-body -->*/}
                            </div>{/*<!-- Ends: showcase-box -->*/}
                            <div className="showcase-box">
                                <div className="showcase-header">
                                    <h4>Rounded Buttons</h4>
                                </div>
                                <div className="showcase-body">
                                    <div className="button-group">
                                        <button type="button" className="btn btn-primary btn--rounded">Primary</button>
                                        <button type="button" className="btn btn-secondary btn--rounded">Secondary</button>
                                        <button type="button" className="btn btn-success btn--rounded">Success</button>
                                        <button type="button" className="btn btn-info btn--rounded">Info</button>
                                        <button type="button" className="btn btn-warning btn--rounded">Warning</button>
                                        <button type="button" className="btn btn-danger btn--rounded">Danger</button>
                                        <button type="button" className="btn btn-light btn--rounded">Light</button>
                                        <button type="button" className="btn btn-dark btn--rounded">Dark</button>
                                        <button type="button" className="btn btn-gradient btn--rounded">Gradient</button>
                                    </div>
                                </div>{/*<!-- Ends: .showcase-body -->*/}
                            </div>{/*<!-- Ends: showcase-box -->*/}
                            <div className="showcase-box">
                                <div className="showcase-header">
                                    <h4>Square Buttons</h4>
                                </div>
                                <div className="showcase-body">
                                    <div className="button-group">
                                        <button type="button" className="btn btn-primary rounded-0">Primary</button>
                                        <button type="button" className="btn btn-secondary rounded-0">Secondary</button>
                                        <button type="button" className="btn btn-success rounded-0">Success</button>
                                        <button type="button" className="btn btn-info rounded-0">Info</button>
                                        <button type="button" className="btn btn-warning rounded-0">Warning</button>
                                        <button type="button" className="btn btn-danger rounded-0">Danger</button>
                                        <button type="button" className="btn btn-light rounded-0">Light</button>
                                        <button type="button" className="btn btn-dark rounded-0">Dark</button>
                                        <button type="button" className="btn btn-gradient rounded-0">Gradient</button>
                                    </div>
                                </div>{/*<!-- Ends: .showcase-body -->*/}
                            </div>{/*<!-- Ends: showcase-box -->*/}
                            <div className="showcase-box">
                                <div className="showcase-header">
                                    <h4>Buttons Border Default</h4>
                                </div>
                                <div className="showcase-body">
                                    <div className="button-group">
                                        <button type="button" className="btn btn-outline-primary">Primary</button>
                                        <button type="button" className="btn btn-outline-secondary">Secondary</button>
                                        <button type="button" className="btn btn-outline-success">Success</button>
                                        <button type="button" className="btn btn-outline-info">Info</button>
                                        <button type="button" className="btn btn-outline-warning">Warning</button>
                                        <button type="button" className="btn btn-outline-danger">Danger</button>
                                        <button type="button" className="btn btn-outline-light">Light</button>
                                        <button type="button" className="btn btn-outline-dark">Dark</button>
                                    </div>
                                </div>{/*<!-- Ends: .showcase-body -->*/}
                            </div>{/*<!-- Ends: showcase-box -->*/}
                            <div className="showcase-box">
                                <div className="showcase-header">
                                    <h4>Buttons Border Thick</h4>
                                </div>
                                <div className="showcase-body">
                                    <div className="button-group">
                                        <button type="button" className="btn btn-outline-primary outline-thick-primary">Primary</button>
                                        <button type="button" className="btn btn-outline-secondary outline-thick-secondary">Secondary</button>
                                        <button type="button" className="btn btn-outline-success outline-thick-success">Success</button>
                                        <button type="button" className="btn btn-outline-info outline-thick-info">Info</button>
                                        <button type="button" className="btn btn-outline-warning outline-thick-warning">Warning</button>
                                        <button type="button" className="btn btn-outline-danger outline-thick-danger">Danger</button>
                                        <button type="button" className="btn btn-outline-light outline-thick-light">Light</button>
                                        <button type="button" className="btn btn-outline-dark outline-thick-dark">Dark</button>
                                    </div>
                                </div>{/*<!-- Ends: .showcase-body -->*/}
                            </div>{/*<!-- Ends: showcase-box -->*/}
                            <div className="showcase-box">
                                <div className="showcase-header">
                                    <h4>Buttons With Arrow</h4>
                                </div>
                                <div className="showcase-body">
                                    <div className="button-group">
                                        <button type="button" className="btn btn-primary btn-arrow">Primary <i className="la la-arrow-right"></i></button>
                                        <button type="button" className="btn btn-secondary btn-arrow">Secondary <i className="la la-arrow-right"></i></button>
                                        <button type="button" className="btn btn-success btn-arrow">Success <i className="la la-arrow-right"></i></button>
                                        <button type="button" className="btn btn-info btn-arrow">Info <i className="la la-arrow-right"></i></button>
                                        <button type="button" className="btn btn-warning btn-arrow">Warning <i className="la la-arrow-right"></i></button>
                                        <button type="button" className="btn btn-danger btn-arrow">Danger <i className="la la-arrow-right"></i></button>
                                        <button type="button" className="btn btn-light btn-arrow">Light <i className="la la-arrow-right"></i></button>
                                        <button type="button" className="btn btn-dark btn-arrow">Dark <i className="la la-arrow-right"></i></button>
                                        <button type="button" className="btn btn-gradient btn-arrow">Gradient <i className="la la-arrow-right"></i></button>
                                    </div>
                                </div>{/*<!-- Ends: .showcase-body -->*/}
                            </div>{/*<!-- Ends: showcase-box -->*/}
                            <div className="showcase-box">
                                <div className="showcase-header">
                                    <h4>Buttons With Icons</h4>
                                </div>
                                <div className="showcase-body">
                                    <div className="button-group">
                                        <button type="button" className="btn btn-primary btn-icon icon-left"><i className="la la-user"></i> Primary</button>
                                        <button type="button" className="btn btn-secondary btn-icon icon-left"><i className="la la-bell"></i> Secondary</button>
                                        <button type="button" className="btn btn-success btn-icon icon-left"><i className="la la-check"></i> Success</button>
                                        <button type="button" className="btn btn-info btn-icon icon-left"><i className="la la-bullhorn"></i> Info</button>
                                        <button type="button" className="btn btn-warning btn-icon icon-left"><i className="la la-info-circle"></i> Warning</button>
                                        <button type="button" className="btn btn-danger btn-icon icon-left"><i className="la la-warning"></i> Danger</button>
                                        <button type="button" className="btn btn-light btn-icon icon-left"><i className="la la-map-marker"></i> Light</button>
                                        <button type="button" className="btn btn-dark btn-icon icon-left"><i className="la la-undo"></i> Dark</button>
                                        <button type="button" className="btn btn-gradient btn-icon icon-left"><i className="la la-money"></i> Gradient</button>
                                    </div>
                                </div>{/*<!-- Ends: .showcase-body -->*/}
                            </div>{/*<!-- Ends: showcase-box -->*/}
                            <div className="showcase-box">
                                <div className="showcase-header">
                                    <h4>Block Buttons</h4>
                                </div>
                                <div className="showcase-body">
                                    <div className="button-group row">
                                        <div className="col-lg-6">
                                            <button type="button" className="btn btn-primary btn-lg btn-block">Block Level Default Button</button>
                                        </div>
                                        <div className="col-lg-6">
                                            <button type="button" className="btn btn-secondary btn-lg rounded-0 btn-block">Block Level Square Button</button>
                                        </div>
                                        <div className="col-lg-6">
                                            <button type="button" className="btn btn-outline-primary btn--rounded btn-lg btn-block">Block Level Border Button</button>
                                        </div>
                                        <div className="col-lg-6">
                                            <button type="button" className="btn btn-secondary btn-lg btn--rounded btn-block">Block Level Rounded Button</button>
                                        </div>
                                    </div>
                                </div>{/*<!-- Ends: .showcase-body -->*/}
                            </div>{/*<!-- Ends: showcase-box -->*/}
                            <div className="showcase-box">
                                <div className="showcase-header">
                                    <h4>Button Sizes</h4>
                                </div>
                                <div className="showcase-body">
                                    <div className="button-group">
                                        <button type="button" className="btn btn-primary btn-lg">Large Button</button>
                                        <button type="button" className="btn btn-secondary btn-lg">Large Button</button>
                                        <button type="button" className="btn btn-success btn-lg">Large Button</button>
                                    </div>
                                    <div className="button-group">
                                        <button type="button" className="btn btn-primary">Default Button</button>
                                        <button type="button" className="btn btn-secondary">Default Button</button>
                                        <button type="button" className="btn btn-success">Default Button</button>
                                    </div>
                                    <div className="button-group">
                                        <button type="button" className="btn btn-primary btn-sm">Small</button>
                                        <button type="button" className="btn btn-secondary btn-sm">Small</button>
                                        <button type="button" className="btn btn-success btn-sm">Small</button>
                                    </div>
                                </div>{/*<!-- Ends: .showcase-body -->*/}
                            </div>{/*<!-- Ends: .showcase-box -->*/}
                        </div>{/*<!-- .col-lg-12 -->*/}
                    </div>
                </div>
            </section>{/*<!-- Ends: .buttons-wrapper -->*/}
            <Footer />
        </Fragment>
    )
}

export default Blockquotes;
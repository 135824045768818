import $ from "jquery";
import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel2';

let rtlCheck = $("body").css("direction");
let falsy = false;
if(rtlCheck === "rtl"){
    falsy = true;
}

const options = {
    items: 1,
    rtl: falsy,
    dots: true,
    animateIn: "fadeIn",
    animateOut: "fadeOut",
    responsive:{
        0:{
            items:1
        },
        600:{
            items:1
        },
        1000:{
            items:1
        }
    }
};
class IntroCarousel extends Component {
    render() {

        return (
            <OwlCarousel options={options} className="intro_area9-carousel owl-carousel" >
                <div><img src="./assets/img/slide9_1.png" alt="" /></div>
                <div><img src="./assets/img/slide9_3.png" alt="" /></div>
                <div><img src="./assets/img/slide9_2.png" alt="" /></div>
            </OwlCarousel>
        )
    }
}

export default IntroCarousel;
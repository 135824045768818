import React, {Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';

class SubscribeWraper extends Component {
    constructor (props) {
        super (props)
        this.state = {
            email:''            
        }
        this.validator = new SimpleReactValidator(); 
        this.setStateFromInput = this.setStateFromInput.bind(this);       
    }

    setStateFromInput = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    }

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render () {

        const Shipping_Address = (e) => {
            e.preventDefault()
            if (this.validator.allValid()) {
                console.log(this.state)                           
                var success = true;
                return success;
                        
            } else {
                this.validator.showMessages();              
                this.forceUpdate();              
                var success2 = false;
                return success2;               
            }
        }

    return (
        <section className="subscribe-wrapper bgimage biz_overlay overlay--dark2 subscribe--one">
            <div className="bg_image_holder" style={{backgroundImage: 'url(&quot;img/sbg1.jpg&quot;)', opacity: 1}}>
                <img src="./assets/img/sbg1.jpg" alt="img/sbg1.jpg" />
            </div>
            <div className="container content_above">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2>Subscribe To Our Newsletter</h2>
                        <p>Subscribe to get update and information. Don't worry, we won't send spam!</p>
                        <form>
                            <div className="row">
                                <div className="col-lg-6 offset-lg-3">
                                    
                                    <div className="input-group input-group-subscribe subscribe--rounded">
                                        <div className="text-field">
                                            <input type="text" value={this.state.email} name="email" onChange={this.setStateFromInput} className="form-control" placeholder="Enter your email" aria-label="Email" />
                                        </div>
                                        <button onClick={Shipping_Address} type="submit">Submit</button>
                                    </div>{/*<!-- End: .input-group -->*/}
                                    <div className="text-danger">{this.validator.message('Email', this.state.email, 'required|email')}</div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>{/*<!-- ends .row -->*/}
            </div>
        </section>
    )
    }
}
export default SubscribeWraper;
import $ from "jquery";
import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel2';
import { connect } from 'react-redux';
let rtlCheck = $("body").css("direction");
let falsy = false;
if(rtlCheck === "rtl"){
    falsy = true;
}
const options = {
    items: 1,
    rtl: falsy,
    nav: false,
    dots: true,
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 1
        },
        1000: {
            items: 1
        }
    }
};
class ImageCarousel2 extends Component {
    render() {
        const {project} = this.props;
        return (
            <OwlCarousel options={options} className="image-carousel-two owl-carousel">
                {
                    Object.values(project).slice(0, 6).map((value, key) => {
                        return (
                        <div key={value.id} className="carousel-single">
                            <figure>
                                <img src={value.imgSrc} alt="" />
                            </figure>
                        </div>
                        )
                    })

                }
            </OwlCarousel>
        )
    }
}
const mapStateToProps = state => {
    return {
        project : state.project
    }
}
export default connect(mapStateToProps)(ImageCarousel2);
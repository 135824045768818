import React, {Fragment} from 'react';
import Navbar from '../navbar/colorNav';
import TopHeader from '../header/topHeader';
import { NavLink } from 'react-router-dom';

const Slider_header = () => {
    return (
        <Fragment>
            <section className="header header--2">
               <TopHeader />
                <Navbar />
            </section>
            {/* <!-- ends: .intro-area --> */}
            <section className="p-top-110 p-bottom-40">

                <div className="intro-five">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h1 className="display-2">Business <br /> <span>Startup</span></h1>
                                <p>Deserunt dolore voluptatem assumenda quae possimus sunt dignissimos tempora officia. Lorem ipsum
                                    dolor sit amet consectetur adipisicing dolore.</p>
                                <NavLink to="/demo" className="btn btn-secondary">Learn More</NavLink>
                            </div>{/*<!-- ends: .col-lg-6 -->*/}
                            <div className="col-md-6">

                                <div className="shape-wrapper img-shape-one">
                                    <img src="./assets/img/simg-1.jpg" alt="" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>{/*<!-- ends: .intro-three -->*/}

            </section>
        </Fragment>

    )
}


export default Slider_header;





import React, {Fragment} from 'react';
import Navbar from '../navbar/colorNav';
import TopHeader from '../header/topHeader';
import Slider from '../slider/revollution-slider-17';

const Slider_header_27 = () => {      
    return (
        <Fragment>
            <section className="header header--2">
               <TopHeader />
                <Navbar />
                <Slider />
            </section>
            {/* <!-- ends: .intro-area --> */}
        </Fragment>       
                      
    )   
}


export default Slider_header_27;




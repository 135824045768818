import React from 'react';

export const TimelineTwo = () => {
    return (
        <div className="row">
            <div className="col-lg-6 margin-md-60">
                <h4>Experience</h4>
                <p>We can offer you a wide range of opportunities to build varied and rewarding career.We offer attractive careers.</p>
                <div className="timeline2 m-top-45">
                    <div className="happening2">
                        <p className="happening2__period">2010 - 2012</p>
                        <h6 className="happening2__title">Global Management Committee at Ratri Cloud</h6>
                        <p>Investig ationes demons trave runt lectores legere liusry quod ile gunt saepius claritas Inves tig ationes. Our management.</p>
                    </div>{/*<!-- ends: .happening2 -->*/}
                    <div className="happening2">
                        <p className="happening2__period">2012 - 2013</p>
                        <h6 className="happening2__title">Member of the Management Board at Ratri Cloud</h6>
                        <p>Investig ationes demons trave runt lectores legere liusry quod ile gunt saepius claritas Inves tig ationes. Our management.</p>
                    </div>{/*<!-- ends: .happening2 -->*/}
                    <div className="happening2">
                        <p className="happening2__period">2015 - 2016</p>
                        <h6 className="happening2__title">Firm-wide Leader for SAP Business</h6>
                        <p>Investig ationes demons trave runt lectores legere liusry quod ile gunt saepius claritas Inves tig ationes. Our management.</p>
                    </div>{/*<!-- ends: .happening2 -->*/}
                </div>
            </div>
            <div className="col-lg-6">
                <h4>Educational Background</h4>
                <p>We can offer you a wide range of opportunities to build varied and rewarding career.We offer attractive careers.</p>
                <div className="timeline2 m-top-45">
                    <div className="happening2">
                        <p className="happening2__period">2010 - 2012</p>
                        <h6 className="happening2__title">Global Management Committee at Ratri Cloud</h6>
                        <p>Investig ationes demons trave runt lectores legere liusry quod ile gunt saepius claritas Inves tig ationes. Our management.</p>
                    </div>{/*<!-- ends: .happening2 -->*/}
                    <div className="happening2">
                        <p className="happening2__period">2012 - 2013</p>
                        <h6 className="happening2__title">Member of the Management Board at Ratri Cloud</h6>
                        <p>Investig ationes demons trave runt lectores legere liusry quod ile gunt saepius claritas Inves tig ationes. Our management.</p>
                    </div>{/*<!-- ends: .happening2 -->*/}
                    <div className="happening2">
                        <p className="happening2__period">2015 - 2016</p>
                        <h6 className="happening2__title">Firm-wide Leader for SAP Business</h6>
                        <p>Investig ationes demons trave runt lectores legere liusry quod ile gunt saepius claritas Inves tig ationes. Our management.</p>
                    </div>{/*<!-- ends: .happening2 -->*/}
                </div>
            </div>
        </div>
    )
}

export const TimelineOne = () => {
    return (
    <div className="timeline_area section-bg timeline_area--1">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="timeline1">
                <div className="happening row">
                  <div className="happening__period col-md-3">
                    <div className="wrapper rounded shadow-sm">
                      <p className="date">20 January</p>
                      <h4 className="year">2002</h4>
                    </div>
                  </div>
                  <div className="happening__desc col-md-8 offset-md-1">
                    <h5 className="happening_title">Founded Ratri Cloud</h5>
                    <p>Investiga tiones demonstr averunt lectres legere melius quodqua legunt saepius. Clarias kest etiam pro
                      cessus dynamicus squitur mutaety tionem consum etudium. Investig ationes the demons trave huerunt
                      lectores legere liusry. Investiga tiones demonstr averunt.</p>
                  </div>
                </div>{/* ends: .happening */}
                <div className="happening row">
                  <div className="happening__period col-md-3">
                    <div className="wrapper rounded shadow-sm">
                      <p className="date">20 January</p>
                      <h4 className="year">2004</h4>
                    </div>
                  </div>
                  <div className="happening__desc col-md-8 offset-md-1">
                    <h5 className="happening_title">Start With a Small Service</h5>
                    <p>Investiga tiones demonstr averunt lectres legere melius quodqua legunt saepius. Clarias kest etiam pro
                      cessus dynamicus squitur mutaety tionem consum etudium. Investig ationes the demons trave huerunt
                      lectores legere liusry. Investiga tiones demonstr averunt.</p>
                  </div>
                </div>{/* ends: .happening */}
                <div className="happening row">
                  <div className="happening__period col-md-3">
                    <div className="wrapper rounded shadow-sm">
                      <p className="date">20 January</p>
                      <h4 className="year">2006</h4>
                    </div>
                  </div>
                  <div className="happening__desc col-md-8 offset-md-1">
                    <h5 className="happening_title">First Employees</h5>
                    <p>Investiga tiones demonstr averunt lectres legere melius quodqua legunt saepius. Clarias kest etiam pro
                      cessus dynamicus squitur mutaety tionem consum etudium. Investig ationes the demons trave huerunt
                      lectores legere liusry. Investiga tiones demonstr averunt.</p>
                  </div>
                </div>{/* ends: .happening */}
                <div className="happening row">
                  <div className="happening__period col-md-3">
                    <div className="wrapper rounded shadow-sm">
                      <p className="date">20 January</p>
                      <h4 className="year">2008</h4>
                    </div>
                  </div>
                  <div className="happening__desc col-md-8 offset-md-1">
                    <h5 className="happening_title">United Kingdom Office</h5>
                    <p>Investiga tiones demonstr averunt lectres legere melius quodqua legunt saepius. Clarias kest etiam pro
                      cessus dynamicus squitur mutaety tionem consum etudium. Investig ationes the demons trave huerunt
                      lectores legere liusry. Investiga tiones demonstr averunt.</p>
                  </div>
                </div>{/* ends: .happening */}
              </div>
            </div>
          </div>
        </div>
    </div>
    )
}

import React, { Fragment } from 'react';
import Header from '../layout/header/header-404';
import Footer from '../layout/footer/footer-5';


const About = () => {
    return (
        <Fragment>

            <Header/>
            <Footer />
        </Fragment>
    )
}

export default About;
import React from 'react';
import {NavLink} from 'react-router-dom';
const noAction = e => e.preventDefault();
const CtaSm = () => {
    return (
        <section className="cta-wrapper-sm cta--seven bgimage biz_overlay overlay--primary">
            <div className="bg_image_holder">
                <img src="./assets/img/cta3.png" alt="" />
            </div>
            <div className="container content_above">
                <div className="row d-flex align-items-center">
                    <div className="col-lg-9">
                        <p className="m-0 color-light">Are you ready to start your business?</p>
                    </div>
                    <div className="col-lg-3">
                        <div className="action-btn">
                            <NavLink to="/at_demo" onClick={noAction} className="btn btn-light">Get a quote</NavLink>
                        </div>
                    </div>
                </div>
                {/* <!-- .row --> */}
            </div>
        </section>
    )
}
export default CtaSm;
import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import { NavLink } from 'react-router-dom';


const Dropdowns = () => {
    return (
        <Fragment>

            <Header pageTitle="Dropdowns" />
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Dropdowns on Click</h3>
                        </div>
                        </div>
                    </div>
                </div>
                <section className="dropdowns-wrapper pb-5 mb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 text-center">
                                <div className="dropdown">
                                    <NavLink className="btn btn-primary dropdown-toggle" to="/at_demo" role="button" id="dropdownbtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Dropdown Button
                                        <span className="dropdown-caret la la-angle-down" />
                                    </NavLink>
                                    <div className="dropdown-menu dropdown--btn" aria-labelledby="dropdownbtn">
                                        <NavLink className="dropdown-item" to="/at_demo">Action</NavLink>
                                        <NavLink className="dropdown-item" to="/at_demo">Another action</NavLink>
                                        <NavLink className="dropdown-item" to="/at_demo">Something else here</NavLink>
                                    </div>
                                </div>{/* end: .dropdown */}
                            </div>{/* ends: .col-lg-4 */}
                            <div className="col-lg-4 text-center">
                                <div className="dropdown">
                                    <NavLink className="btn btn-outline-secondary dropdown-toggle" to="/at_demo" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Dropdown Button
                                        <span className="dropdown-caret la la-angle-down" />
                                    </NavLink>
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <NavLink className="dropdown-item" to="/at_demo">Create</NavLink>
                                        <NavLink className="dropdown-item" to="/at_demo">Manage</NavLink>
                                        <NavLink className="dropdown-item" to="/at_demo">Share</NavLink>
                                        <NavLink className="dropdown-item" to="/at_demo">Logout</NavLink>
                                    </div>
                                </div>{/* end: .dropdown */}
                            </div>
                            <div className="col-lg-4 text-center">
                                <div className="dropdown">
                                    <NavLink className="btn btn-secondary dropdown-toggle" to="/at_demo" role="button" id="dropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Dropdown Button
                                        <span className="dropdown-caret la la-angle-down" />
                                    </NavLink>
                                    <div className="dropdown-menu dropdown--text" aria-labelledby="dropdownMenuLink2">
                                        <p className="dropdown-text-item">Investiga tiones demonstr averunt lectores legere me lius quod dequa legunt saepius. Claritas estk etiam pro cessus dynamicus quipo seked quitur mutay tionem consue tudum torum. Mirum est notare quam.</p>
                                    </div>
                                </div>{/* end: .dropdown */}
                            </div>
                        </div>{/* ends: .row */}
                    </div>{/* ends: .container */}
                </section>{/* ends: dropdowns-wrapper */}
            </div>
            <Footer />
        </Fragment>
    )
}

export default Dropdowns;
import $ from "jquery";
import React, { Component } from 'react';
import ClientContent from '../../content/clients/clients';
import OwlCarousel from 'react-owl-carousel2';
import {connect} from 'react-redux';

let rtlCheck = $("body").css("direction");
let falsy = false;
if(rtlCheck === "rtl"){
    falsy = true;
}

const options = {
    items: 5,
    rtl: falsy,
    nav: false,
    rewind: true,
    autoplay: true,
    navContainer: false,
    dots : false,
    responsive: {
                0: {
                    items: 1
                },
                480:{
                    items:2
                },
                768:{
                    items:3
                },
                992:{
                    items:5
                }
    }
}


class Clients1 extends Component {
    render() {
        const {client} = this.props;
        return (

            <div className="row">
                <div className="col-lg-12">
                    <OwlCarousel className="owl-carousel logo-carousel-one"  options={options} >
                    {
                        client.map((value, key) => {
                            return <ClientContent img={value.imgSrc} key={key+1} />
                        })
                    }
                    </OwlCarousel>
                </div>
            </div>

        )
    }
}
const mapStateToProps = (state) => {

    return {
        client : state.client[0].clients
    }
}
export default connect(mapStateToProps)(Clients1)
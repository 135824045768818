import React, {Fragment} from 'react';
import {NavLink} from 'react-router-dom';

const Revolution_27 = (props) => {

    return (

        <Fragment>
             <div id="rev_slider_27_1_wrapper" className="rev_slider_wrapper fullwidthbanner-container" data-alias="slider6" data-source="gallery" style={{margin:'0px auto',background:'transparent',padding:'0px', marginTop:'0px', marginBottom:'0px'}}>
                {/* <!-- START REVOLUTION SLIDER 5.4.8.1 fullwidth mode --> */}
                <div id="rev_slider_27_1" className="rev_slider fullwidthabanner" style={{display:'none'}} data-version="5.4.8.1">
                    <ul>
                        {/* <!-- SLIDE  --> */}
                        <li data-index="rs-66" data-transition="fade" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="300" data-delay="7340" data-rotate="0" data-saveperformance="off" data-title="Slide" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                            {/* <!-- MAIN IMAGE --> */}
                            <img src="./assets/img/youtube.jpg" alt="" data-bgposition="center center" data-bgfit="cover" className="rev-slidebg" data-no-retina />
                            {/* <!-- LAYERS --> */}
                            {/* <!-- BACKGROUND VIDEO LAYER --> */}
                            <div className="rs-background-video-layer" data-forcerewind="on" data-volume="mute" data-ytid="dk9uNWPP7EA" data-videoattributes="version=3&amp;enablejsapi=1&amp;html5=1&amp;hd=1&amp;wmode=opaque&amp;showinfo=0&amp;rel=0; origin=http://localhost:3000;" data-videorate="1" data-videowidth="100%" data-videoheight="100%" data-videocontrols="none" data-videoloop="loopandnoslidestop" data-forcecover="1" data-aspectratio="16:9" data-autoplay="true" data-autoplayonlyfirsttime="false"></div>
                            <div id="rrzm_66" className="rev_row_zone rev_row_zone_middle" style={{zIndex: 5}}>
                                {/* <!-- LAYER NR. 1 --> */}
                                <div className="tp-caption  " id="slide-66-layer-1" data-x="100" data-y="center" data-voffset="" data-width="['auto']" data-height="['auto']" data-type="row" data-columnbreak="3" data-responsive_offset="on" data-responsive="off" data-frames='[{"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 5, whiteSpace: 'nowrap', fontSize: '20px', lineHeight: '22px', fontWeight: 400, color: '#ffffff', letterSpacing: '0px'}}>
                                    {/* <!-- LAYER NR. 2 --> */}
                                    <div className="tp-caption  " id="slide-66-layer-2" data-x="100" data-y="100" data-width="['auto']" data-height="['auto']" data-type="column" data-responsive_offset="on" data-frames='[{"delay":"+0","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-columnwidth="16.67%" data-verticalalign="top" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 6, width: '100%'}}></div>
                                    {/* <!-- LAYER NR. 3 --> */}
                                    <div className="tp-caption  " id="slide-66-layer-3" data-x="100" data-y="100" data-width="['auto']" data-height="['auto']" data-type="column" data-responsive_offset="on" data-responsive="off" data-frames='[{"delay":"+0","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-columnwidth="66.67%" data-verticalalign="top" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textalign="['center','center','center','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 7, width: '100%'}}>
                                        {/* <!-- LAYER NR. 4 --> */}
                                        <div className="tp-caption   tp-resizeme" id="slide-66-layer-5" data-x="" data-y="" data-width="['auto']" data-height="['auto']" data-type="text" data-responsive_offset="on" data-fontsize="['50', '45', '40', '32']" data-lineheight="['64', '55', '43', '38']" data-frames='[{"delay":"+0","speed":1650,"frame":"0","from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 8, whiteSpace: 'normal', fontSize: '50px', lineHeight: '64px', fontWeight: 600, color: '#ffffff', letterSpacing: '0px', display: 'block'}}>
                                            Number One <br />
                                            Business Consultancy
                                        </div>
                                        {/* <!-- LAYER NR. 5 --> */}
                                        <div className="tp-caption   tp-resizeme" id="slide-66-layer-6" data-x="" data-y="" data-width="['auto']" data-height="['auto']" data-type="text" data-responsive_offset="on" data-fontsize="['20', '20', '18', '16']" data-lineheight="['36', '36', '24', '22']" data-frames='[{"delay":"+970","speed":1490,"frame":"0","from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];s:inherit;e:inherit;","to":"o:1;","ease":"Power2.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-margintop="[30,30,30,30]" data-marginright="[0,0,0,0]" data-marginbottom="[40,40,40,40]" data-marginleft="[0,0,0,0]" data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 9, whiteSpace: 'normal', fontSize: '20px', lineHeight: '36px', fontWeight: 400, color: '#ffffff', letterSpacing: '0px', display: 'block'}}>
                                            Ratri Cloud is one of the top business, finance consulting Template.
                                            We help global leaders with their organization's most critical issues.
                                        </div>
                                        {/* <!-- LAYER NR. 6 --> */}
                                        <div className="tp-caption" id="slide-66-layer-7" data-x="" data-y="" data-width="['auto']" data-height="['auto']" data-type="text" data-responsive_offset="on" data-frames='[{"delay":"+2250","speed":450,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 10, display: 'inline-block'}}>
                                            <NavLink to="/about" className="btn btn-secondary">About Us</NavLink></div>
                                        {/* <!-- LAYER NR. 7 --> */}
                                        <div className="tp-caption" id="slide-66-layer-8" data-x="5" data-y="5" data-width="['auto']" data-height="['auto']" data-type="text" data-responsive_offset="on" data-frames='[{"delay":"+2250","speed":450,"frame":"0","from":"z:0;rX:0;rY:0;rZ:0;sX:0.9;sY:0.9;skX:0;skY:0;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[30,30,30,30]" data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 11, display: 'inline-block'}}>
                                            <NavLink to="/service" className="btn hero_btn btn-outline-primary">Our Services</NavLink></div>
                                    </div>
                                    {/* <!-- LAYER NR. 8 --> */}
                                    <div className="tp-caption  " id="slide-66-layer-4" data-x="100" data-y="100" data-width="['auto']" data-height="['auto']" data-type="column" data-responsive_offset="on" data-responsive="off" data-frames='[{"delay":"+0","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-columnwidth="16.67%" data-verticalalign="top" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 12, width: '100%'}}></div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div className="tp-bannertimer tp-bottom" style={{visibility: 'hidden !important'}}></div>
                </div>
            </div>
        </Fragment>

    )
}


export default Revolution_27;


import React, {Fragment} from 'react';
import Navbar from '../navbar/colorNav';
import TopHeader from '../header/topHeader';
import { NavLink } from 'react-router-dom';
import Carousel from '../../container/element/carousel/introarea-9';
const noAction = e => e.preventDefault();

const Content_height = () => {      
    return (
        <Fragment>
            <section className="header header--2">
               <TopHeader />
                <Navbar />                
            </section>
            <div className="intro_area9">
                <div className="intro_area9--contents">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <h1 className="">Best Business <br /> Startup</h1>
                                <p>Deserunt dolore voluptatem assumenda quae possi mus sunt dignissimos tempora officia.</p>
                                <NavLink to="/at_demo" onClick={noAction} className="btn btn-secondary">Learn More</NavLink>
                            </div>
                        </div>
                    </div>
                </div>{/*<!-- ends: intro_area9-contents -->*/}
                <div className="intro_area9--slides">
                    <Carousel />
                </div>{/*<!-- ends: .intro_area9-slides-->*/}
            </div>
        </Fragment>       
                      
    )   
}


export default Content_height;




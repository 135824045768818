import React, {Fragment} from 'react';
import Navbar from '../navbar/colorNav';
import TopHeader from '../header/topHeader';
import { NavLink } from 'react-router-dom';
const noAction = e => e.preventDefault();

const Content_height = () => {      
    return (
        <Fragment>
            <section className="header header--2">
               <TopHeader />
                <Navbar />                
            </section>
            <div className="intro-one">
                <div className="intro-one--contents">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-12">
                                <h1 className="display-3">Make Business with Experienced <span>Partners</span></h1>
                                <p>Deserunt dolore voluptatem assumenda quae possimus sunt dignissimos tempora officia. Lorem
                                    ipsum dolor sit amet consectetur adipisicing dolore.</p>
                                <NavLink to="/at_demo" onClick={noAction} className="btn btn-secondary">Explore More</NavLink>
                            </div>
                        </div>
                    </div>{/*<!-- ends: .container -->*/}
                </div>{/*<!-- ends: .intro-one-contents -->*/}
                <div className="intro-one--img">
                    <img src="./assets/img/slider_3.png" alt="" />
                </div>{/*<!-- ends: .intro-one-img -->*/}
            </div>
        </Fragment>       
                      
    )   
}


export default Content_height;




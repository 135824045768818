import React from 'react';
import {NavLink} from 'react-router-dom';
export const CaseItem  = props => {
    const {imgSrc, title, id} =  props;
    return(
        <div className="portfolio-carousel-single">
            <figure>
                <img src={imgSrc} alt="" />
                <figcaption>
                    <h5>{title}</h5>
                    <span className="color-secondary">Industry</span>
                    <NavLink to={"project-details"+id} className="link"><i className="la la-link"></i></NavLink>
                </figcaption>
            </figure>           
        </div>
    )
}


                    
              
import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';


const Blockquotes = () => {
    return (
        <Fragment>

            <Header pageTitle="Bredcrumb" />
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Breadcrumb - 1</h3>
                        </div>
                    </div>
                </div>
                </div>
                <section className="breadcrumb_area breadcrumb1">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb_wrapper d-flex align-items-center justify-content-between flex-wrap">
                                <h4 className="page_title">Breadcrumbs One</h4>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="# ">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Breadcrumbs One</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>{/* ends: .row */}
                </div>
                </section>{/* ends: .breadcrumb_area */}
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Breadcrumb - 2</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="breadcrumb_area breadcrumb2 bgimage biz_overlay">
                    <div className="bg_image_holder">
                        <img src="./assets/img/breadbg.jpg" alt="" />
                    </div>
                    <div className="container content_above">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="breadcrumb_wrapper d-flex flex-column align-items-center">
                                    <h4 className="page_title">Breadcrumbs Two</h4>
                                    <nav aria-label="breadcrumb">
                                            <ol className="breadcrumb m-bottom-30">
                                            <li className="breadcrumb-item"><a href="# ">Home</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Breadcrumbs Two</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>{/* ends: .row */}
                    </div>
                </section>{/* ends: .breadcrumb_area */}
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Breadcrumb - 3</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="breadcrumb_area breadcrumb1 breadcrumb-colored bg-primary">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb_wrapper d-flex align-items-center justify-content-between flex-wrap">
                                <h4 className="page_title">Breadcrumb Colored</h4>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="# " className="color-light"><span className="la la-home" />Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Breadcrumb Colored</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>{/* ends: .row */}
                </div>
                </section>{/* ends: .breadcrumb_area */}
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Breadcrumb - 4</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="breadcrumb_area breadcrumb1 breadcrumb-gradient bg-gradient">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="breadcrumb_wrapper d-flex align-items-center justify-content-between flex-wrap">
                                    <h4 className="page_title">Breadcrumb Gradient</h4>
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a href="# " className="color-light"><span className="la la-home" />Home</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Breadcrumb Gradient</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>{/* ends: .row */}
                    </div>
                </section>{/* ends: .breadcrumb_area */}
            <Footer />
        </Fragment>
    )
}

export default Blockquotes;
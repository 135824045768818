import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';


const FlipBox = () => {
    return (
        <Fragment>

            <Header pageTitle="Flip Box" />
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Flip Box</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="flip-boxes p-top-120 p-bottom-90">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="flip-card">
                                    <div className="flip-wrapper">
                                        <div className="flip-front">
                                            <div className="front-contents">
                                                <span className="color-primary"><i className="la la-floppy-o" /></span>
                                                <h6>Customer Strategy &amp; Marketing</h6>
                                                <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt saepius claritas est.</p>
                                            </div>
                                            <div className="flip-overlay" />
                                        </div>{/* ends: .flip-front */}
                                        <div className="flip-back">
                                            <div className="back-contents">
                                                <h5 className="color-light">Customer Strategy &amp; Marketing</h5>
                                                <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt saepius claritas est.</p>
                                                <a href='#a' className="btn btn-light btn-sm">See Details</a>
                                            </div>
                                            <div className="flip-overlay2" />
                                        </div>{/* ends: .flip-back */}
                                    </div>{/* ends: .flip-wrapper */}
                                </div>{/* ends: .flip-card */}
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="flip-card">
                                    <div className="flip-wrapper">
                                        <div className="flip-front">
                                            <div className="front-contents">
                                                <span className="color-primary"><i className="la la-check-square" /></span>
                                                <h6>Startup Business &amp; Planning</h6>
                                                <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt saepius claritas est.</p>
                                            </div>
                                            <div className="flip-overlay" />
                                        </div>{/* ends: .flip-front */}
                                        <div className="flip-back">
                                            <div className="back-contents">
                                                <h5 className="color-light">Startup Business &amp; Planning</h5>
                                                <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt saepius claritas est.</p>
                                                <a href='#a' className="btn btn-light btn-sm">See Details</a>
                                            </div>
                                            <div className="flip-overlay2" />
                                        </div>{/* ends: .flip-back */}
                                    </div>{/* ends: .flip-wrapper */}
                                </div>{/* ends: .flip-card */}
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="flip-card">
                                    <div className="flip-wrapper">
                                        <div className="flip-front">
                                            <div className="front-contents">
                                                <span className="color-primary"><i className="la la-bar-chart" /></span>
                                                <h6>Industrial Goods &amp; Services</h6>
                                                <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt saepius claritas est.</p>
                                            </div>
                                            <div className="flip-overlay" />
                                        </div>{/* ends: .flip-front */}
                                        <div className="flip-back">
                                            <div className="back-contents">
                                                <h5 className="color-light">Industrial Goods &amp; Services</h5>
                                                <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt saepius claritas est.</p>
                                                <a href='#a' className="btn btn-light btn-sm">See Details</a>
                                            </div>
                                            <div className="flip-overlay2" />
                                        </div>{/* ends: .flip-back */}
                                    </div>{/* ends: .flip-wrapper */}
                                </div>{/* ends: .flip-card */}
                            </div>
                        </div>
                    </div>
                </section>{/* ends: .flip-boxes */}
            </div>
            <Footer />
        </Fragment>
    )
}

export default FlipBox;
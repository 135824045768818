import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import Carousel1 from '../../container/element/carousel/carousel-1';
import Carousel3 from '../../container/element/carousel/carousel-3';
import CarouselFour from '../../container/element/carousel/carousel-four';
import BlogCarouselOne from '../../container/element/carousel/blog-carousel-one';
import ImageCarousel1 from '../../container/element/carousel/image-carousel-1';
import ImageCarousel2 from '../../container/element/carousel/image-carousel-2';
import LogoCarousel from '../../container/element/carousel/logo-carousel';
import LogoCarouselThree from '../../container/element/carousel/logo-carousel-three';
import TeamCarouselOne from '../../container/element/carousel/team-carousel-one';
import TestimonialCarouselOne from '../../container/testimonial/testimonial-carousel-one';
import IntroCarousel from '../../container/element/carousel/introarea-9';
import AddressCarousel from '../../container/element/carousel/address_carousel';
import TestimonialFour from '../../container/testimonial/testimonial-wraper-4';
import TestimonialSix from '../../container/testimonial/testimonial-6-wraper';
import TwitterFeeds from '../../container/testimonial/twitter-feeds';


const Blockquotes = () => {
    return (
        <Fragment>

            <Header pageTitle="Carousels" />
            {/* Carousel- 1 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Carousel- 1</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="carousel-wrapper">
                <Carousel1 />
            </section>
            {/* Carousel- 1 */}

            {/* Carousel- 2 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Carousel- 2</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="carousel-wrapper">
                <Carousel3 />
            </section>
            {/* Carousel- 2 */}

            {/* Carousel- 3 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Carousel- 3</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="carousel-wrapper">
                <div className="container">
                    <CarouselFour />
                </div>
            </section>
            {/* Carousel- 3 */}

            {/* Carousel- 4 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Carousel- 4</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="carousel-wrapper">
                <BlogCarouselOne />
            </section>
            {/* Carousel- 4 */}

            {/* Carousel- 5 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Carousel- 5</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="carousel-wrapper">
                <div className="container">
                    <ImageCarousel1 />
                </div>
            </section>
            {/* Carousel- 5 */}

            {/* Carousel- 6 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Carousel- 6</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="carousel-wrapper">
                <div className="container">
                    <ImageCarousel2 />
                </div>
            </section>
            {/* Carousel- 6 */}

            {/* Carousel- 7 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Carousel- 7</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="carousel-wrapper">
                <div className="container">
                    <LogoCarousel />
                </div>
            </section>
            {/* Carousel- 7 */}

            {/* Carousel- 8 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Carousel- 8</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="carousel-wrapper">
                <div className="container">
                    <LogoCarouselThree padding="p-top-30 p-bottom-0" />
                </div>
            </section>
            {/* Carousel- 8 */}

            {/* Carousel- 9 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Carousel- 9</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="carousel-wrapper">
                <div className="container">
                    <TeamCarouselOne />
                </div>
            </section>
            {/* Carousel- 9 */}

            {/* Carousel- 10 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Carousel- 10</h3>
                        </div>
                    </div>
                </div>
            </div>
            <TestimonialCarouselOne />
            {/* Carousel- 10 */}

            {/* Carousel- 11 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Carousel- 11</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="carousel-wrapper">
                <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <IntroCarousel />
                    </div>
                </div>
                </div>
            </section>
            {/* Carousel- 11 */}

            {/* Carousel- 12 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Carousel- 12</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="carousel-wrapper">
                <div className="container">
                    <AddressCarousel />
                </div>
            </section>
            {/* Carousel- 12 */}

            {/* Carousel- 13 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Carousel- 13</h3>
                        </div>
                    </div>
                </div>
            </div>
            <TestimonialFour />
            {/* Carousel- 13 */}

            {/* Carousel- 14 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Carousel- 14</h3>
                        </div>
                    </div>
                </div>
            </div>
            <TestimonialSix />
            {/* Carousel- 14 */}

            {/* Carousel- 15 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Carousel- 15</h3>
                        </div>
                    </div>
                </div>
            </div>
            <TwitterFeeds />
            {/* Carousel- 15 */}

            <Footer />
        </Fragment>
    )
}

export default Blockquotes;
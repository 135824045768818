import React from 'react';
import { NavLink } from 'react-router-dom';
const noAction = e => e.preventDefault();
const ContentBlock3 = ()=> {
    return (
        <div className="content-block content--block3">
            <div className="container">
                <div className="row content_block3_wrapper align-items-center">
                    <div className="col-lg-5 col-md-6 margin-md-60">
                        <img src="./assets/img/cb1.jpg" alt="" className="rounded" />
                    </div>{/*<!-- ends: .col-lg-5 -->*/}
                    <div className="col-lg-6 col-md-6 offset-lg-1">
                        <h4>Business Change Transformation</h4>
                        <p>Investig ationes demons trave runt lectores legere lius quod ii legunt saepius claritas nvestig
                            ationes demons trave rungt. Investiga tiones demonstr averunt lectores legere me lius quod.</p>
                        <NavLink to="/at_demo" onClick={noAction} className="btn btn-primary btn--rounded mt-4">Read More</NavLink>
                    </div>{/*<!-- ends: .col-lg-6 -->*/}
                </div>
                <div className="row content_block3_wrapper align-items-center">
                    <div className="col-lg-5 col-md-6 margin-md-60">
                        <img src="./assets/img/cb2.jpg" alt="" className="rounded" />
                    </div>{/*<!-- ends: .col-lg-5 -->*/}
                    <div className="col-lg-6 col-md-6 offset-lg-1">
                        <h4>Strategy Advisory</h4>
                        <p>Investig ationes demons trave runt lectores legere lius quod ii legunt saepius claritas nvestig
                            ationes demons trave rungt. Investiga tiones demonstr averunt lectores legere me lius quod.</p>
                        <NavLink to="/at_demo" onClick={noAction} className="btn btn-primary btn--rounded mt-4">Read More</NavLink>
                    </div>{/*<!-- ends: .col-lg-6 -->*/}
                </div>
                <div className="row content_block3_wrapper align-items-center">
                    <div className="col-lg-5 col-md-6 margin-md-60">
                        <img src="./assets/img/cb6.jpg" alt="" className="rounded" />
                    </div>{/*<!-- ends: .col-lg-5 -->*/}
                    <div className="col-lg-6 col-md-6 offset-lg-1">
                        <h4>Business Change Transformation</h4>
                        <p>Investig ationes demons trave runt lectores legere lius quod ii legunt saepius claritas nvestig
                            ationes demons trave rungt. Investiga tiones demonstr averunt lectores legere me lius quod.</p>
                        <NavLink to="/at_demo" onClick={noAction} className="btn btn-primary btn--rounded mt-4">Read More</NavLink>
                    </div>{/*<!-- ends: .col-lg-6 -->*/}
                </div>
            </div>
        </div>        
    )
}
export default ContentBlock3;
import React from 'react';
import {NavLink} from 'react-router-dom';
import FooterItem from './footerItem';
const noAction = (e) => {
    e.preventDefault();
}

const FooterLight2 = () => {

    return (
        <footer className="footer footer--light">
            <div className="footer__big">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="widget text_widget">
                                <img className="footer_logo" src="./assets/img/logo.png" alt="logo" />
                                <p>Nunc placerat mi id nisi interdum they mtolis. Praesient is pharetra justo ught scel
                                    erisque the mattis lhreo quam nterdum mollisy.</p>
                                <a href="# ">Read More About <span className="la la-chevron-right"></span></a>
                            </div>
                        </div>{/*<!-- ends: .col-lg-3 -->*/}
                        <FooterItem color="black" />

                    </div>
                </div>
            </div>{/*<!-- ends: .footer__big -->*/}
            <div className="footer__small text-center">
                <p>©2019 Ratri Cloud. All rights reserved. Created by <NavLink to="/" onClick={noAction}>AazzTech</NavLink></p>
            </div>{/*<!-- ends: .footer__small -->*/}
        </footer>
    )
}
export default FooterLight2;

import React, {Fragment} from 'react';
import Navbar from '../navbar/colorNav';
import TopHeader from '../header/topHeader';
import { NavLink } from 'react-router-dom';
const noAction = e => e.preventDefault();

const Content_height = () => {      
    return (
        <Fragment>
            <section className="header header--2">
               <TopHeader />
                <Navbar />                
            </section>
            <section className="error-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="error-contents text-center">
                                <figure>
                                    <img src="./assets/img/404.png" alt="" />
                                </figure>
                                <h3>Oops! That page can’t be found.</h3>
                                <p>We can’t find the page your are looking for. You can check out our
                                    <NavLink to="/" onClick={noAction}>Help Center</NavLink> or head back to <NavLink to="/">Homepage</NavLink>.</p>
                                <div className="m-top-40">
                                    <NavLink to="/" className="btn btn-primary btn-lg btn-icon icon-left"><i className="la la-angle-double-left"></i> Back To Homepage</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>       
                      
    )   
}


export default Content_height;




import React, {Fragment} from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
const noAction = (e) => {
    e.preventDefault();
}

const Revolution_hero = (props) => {
    const slider = props.slider;

    return (

        <Fragment>
             <div id="rev_slider_15_1_wrapper" className="rev_slider_wrapper fullwidthbanner-container" data-alias="slider1" data-source="gallery" style={{margin:'0px auto',background:'transparent',padding:'0px',marginTop:'0px',marginBottom:'0px'}}>
                {/* <!-- START REVOLUTION SLIDER 5.4.8.1 fullwidth mode --> */}
                <div id="rev_slider_15_1" className="rev_slider fullwidthabanner dark-overlay" style={{display:'none'}} data-version="5.4.8.1">
                    <ul>
                    {
                        Object.values(slider).map((value, key) => {
                        const {imgSrc, id, title, tagline, tagline2} = value;
                        return (

                            <li data-index={"rs-"+35+id} key={id} data-transition="fade,slotfade-horizontal" data-slotamount="default,default" data-hideafterloop="0" data-hideslideonmobile="off" data-easein="Elastic.easeOut,default" data-easeout="Elastic.easeIn,default" data-masterspeed="300,default" data-delay="6010" data-rotate="0,0" data-saveperformance="off" data-title="Slide" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                                {/* <!-- MAIN IMAGE --> */}
                                <img src={ imgSrc } alt="" data-bgposition="center center" data-kenburns="on" data-duration="3000" data-ease="Power3.easeOut" data-scalestart="130" data-scaleend="100" data-rotatestart="0" data-rotateend="0" data-blurstart="15" data-blurend="0" data-offsetstart="0 0" data-offsetend="0 0" data-bgparallax="off" className="rev-slidebg" data-no-retina />
                                {/* <!-- LAYERS --> */}
                                <div id="rrzm_36" className="rev_row_zone rev_row_zone_middle" style={{zIndex: 5}}>
                                    {/* <!-- LAYER NR. 1 --> */}
                                    <div className="tp-caption  " id="slide-36-layer-2" data-x="" data-y="center" data-voffset="-210" data-width="['auto']" data-height="['auto']" data-type="row" data-columnbreak="3" data-responsive_offset="on" data-responsive="off" data-frames='[{"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+5670","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]"
                                    data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 5, whiteSpace: 'nowrap', fontSize: '20px', lineHeight: '22px', fontWeight: 400, color: '#ffffff', letterSpacing: '0px'}}>
                                        {/* <!-- LAYER NR. 2 --> */}
                                        <div className="tp-caption  " id="slide-36-layer-3" data-x="100" data-y="100" data-width="['auto']" data-height="['auto']" data-type="column" data-responsive_offset="on" data-responsive="off" data-frames='[{"delay":"+0","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+5670","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-columnwidth="75%" data-verticalalign="top" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 6, width: '100%'}}>
                                            {/* <!-- LAYER NR. 3 --> */}
                                            <div className="tp-caption  tp-no-events   tp-resizeme" id="slide-36-layer-5" data-x="" data-y="" data-height="['auto']" data-type="text" data-responsive_offset="on" data-fontsize="['50', '45', '40', '32']" data-lineheight="['64', '55', '43', '38']" data-frames='[{"delay":"+0","split":"chars","splitdelay":0.05,"speed":2000,"split_direction":"forward","frame":"0","from":"x:[105%];z:0;rX:45deg;rY:0deg;rZ:90deg;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"+2570","speed":1000,"frame":"999","to":"opacity:0;","ease":"Power2.easeIn"}]' data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[30,30,30,30]" data-marginleft="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 7, maxWidth: '930px', whiteSpace: 'normal', fontSize: '60px', lineHeight: '70px', fontWeight: 600, color: '#ffffff', letterSpacing: '0px', display: 'block',pointerEvents:'none'}}>
                                               {title.split(' ').slice(0, 4).join(' ')} <br />
                                               {title.split(' ').slice(5).join(' ')}
                                            </div>
                                            {/* <!-- LAYER NR. 4 --> */}
                                            <div className="tp-caption   tp-resizeme" id="slide-36-layer-6" data-x="" data-y="" data-height="['auto']" data-fontsize="['20', '20', '18', '16']" data-lineheight="['36', '36', '24', '22']" data-type="text" data-responsive_offset="on" data-frames='[{"delay":"+2600","speed":1500,"frame":"0","from":"y:50px;opacity:0;","to":"o:1;","ease":"Power3.easeOut"},{"delay":"+1870","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[35,35,35,35]" data-marginleft="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 8, maxWidth: '731px', whiteSpace: 'normal', fontSize: '20px', lineHeight: '36px', fontWeight: 400, color: '#ffffff', letterSpacing: '0px', display: 'block'}}>
                                                {tagline} <br /> {tagline2}
                                            </div>
                                            {/* <!-- LAYER NR. 5 --> */}
                                            <div className="tp-caption" id="slide-36-layer-10" data-x="" data-y="" data-width="['auto']" data-height="['auto']" data-type="text" data-responsive_offset="on" data-frames='[{"delay":"+3600","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+2070","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 9, whiteSpace: 'normal', display: 'inline-block'}}>
                                                <NavLink to="/demo" onClick={noAction} className="btn btn-secondary btn--rounded">Learn More</NavLink></div>
                                            {/* <!-- LAYER NR. 6 --> */}
                                            <div className="tp-caption" id="slide-36-layer-11" data-x="" data-y="" data-width="['auto']" data-height="['auto']" data-type="text" data-responsive_offset="on" data-frames='[{"delay":"+3800","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+1870","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[30,30,30,30]" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 10, whiteSpace: 'normal', display: 'inline-block'}}>
                                                <NavLink to="/demo" onClick={noAction} className="btn btn-outline-light btn--rounded">Learn More</NavLink></div>
                                        </div>
                                        {/* <!-- LAYER NR. 7 --> */}
                                        <div className="tp-caption  " id="slide-36-layer-4" data-x="100" data-y="100" data-width="['auto']" data-height="['auto']" data-type="column" data-responsive_offset="on" data-frames='[{"delay":"+0","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"+5670","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-columnwidth="25%" data-verticalalign="top" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]"  data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 11, width: '100%'}}></div>
                                    </div>
                                </div>
                            </li>
                            )
                        })
                    }
                    </ul>
                    <div className="tp-bannertimer tp-bottom" style={{visibility: 'hidden !important'}}></div>
                </div>
            </div>
            {/* <!-- END REVOLUTION SLIDER --> */}

        </Fragment>

    )
}
const mapStateToProps = state => {
    return {
        slider : state.slider
    }
}

export default connect(mapStateToProps)(Revolution_hero);


import React, {Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';

class Subscribe2 extends Component {
    constructor (props) {
        super (props)
        this.state = {
            email:''            
        }
        this.validator = new SimpleReactValidator(); 
        this.setStateFromInput = this.setStateFromInput.bind(this);       
    }

    setStateFromInput = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    }

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render () {

        const Shipping_Address = (e) => {
            e.preventDefault()
            if (this.validator.allValid()) {
                console.log(this.state)
                var success = true;
                return success;
                        
            } else {
                this.validator.showMessages();              
                this.forceUpdate();              
                var success2 = false;
                return success2;               
            }
        }
        return (
            <section className="subscribe-wrapper subscribe--two">
                <div className="container content_above">
                    <div className="row">
                        <div className="col-lg-12 text-center">
                            <h2>Subscribe To Our Newsletter</h2>
                            <p>Subscribe to get update and information. Don't worry, we won't send spam!</p>
                            <form>
                                <div className="row">
                                    <div className="col-lg-7 m-auto">
                                        <div className="subscribe-form d-flex">
                                            <div className="input-group input-group-light">
                                                <span className="icon-left" id="basic-addon1"><i className="la la-envelope"></i></span>
                                                <input type="text" value={this.state.email} name="email" onChange={this.setStateFromInput} className="form-control form-control-lg" placeholder="Enter your email" aria-label="Username" />
                                            </div>
                                            <button onClick={Shipping_Address} className="btn btn-primary">Subscribe</button>
                                        </div>
                                        <div className="text-danger">{this.validator.message('Email', this.state.email, 'required|email')}</div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>{/*<!-- ends .row -->*/}
                </div>
            </section>
        )
    }
}

export default Subscribe2;
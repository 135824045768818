import React, {Fragment} from 'react';
import {NavLink} from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import {RequestCall} from "../../content/element/form/contact-form";
const noAction = e => e.preventDefault();

const Revolution_17 = (props) => {

    const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 })
    const isBigScreen = useMediaQuery({ minDeviceWidth: 1824 })
    const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })
    const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: 1224 })
    const isPortrait = useMediaQuery({ orientation: 'portrait' })
    const isRetina = useMediaQuery({ minResolution: '2dppx' })

    return (

        <Fragment>
             <div id="rev_slider_17_1_wrapper" className="rev_slider_wrapper fullwidthbanner-container" data-alias="slider3" data-source="gallery" style={{margin: '0px auto', background: 'transparent', padding: '0px', marginTop: '0px', marginBottom: '0px'}}>
                {/* START REVOLUTION SLIDER 5.4.8.1 fullwidth mode */}
                <div id="rev_slider_17_1" className="rev_slider fullwidthabanner" style={{display: 'none'}} data-version="5.4.8.1">
                <ul>
                    {/* SLIDE  */}
                    <li data-index="rs-40" data-transition="fade,slotfade-horizontal" data-slotamount="default,default" data-hideafterloop={0} data-hideslideonmobile="off" data-easein="Elastic.easeOut,default" data-easeout="Elastic.easeIn,default" data-masterspeed="300,default" data-thumb="assets/100x50_b4995-slider_bg1.jpg" data-delay={6010} data-rotate="0,0" data-saveperformance="off" data-title="Slide" data-param1 data-param2 data-param3 data-param4 data-param5 data-param6 data-param7 data-param8 data-param9 data-param10 data-description>
                    {/* MAIN IMAGE */}
                    <img src="./assets/img/slider_bg10.jpg" alt="" data-bgposition="center center" data-kenburns="on" data-duration={3000} data-ease="Power3.easeOut" data-scalestart={130} data-scaleend={100} data-rotatestart={0} data-rotateend={0} data-blurstart={15} data-blurend={0} data-offsetstart="0 0" data-offsetend="0 0" data-bgparallax="off" className="rev-slidebg" data-no-retina />
                    {/* LAYERS */}
                    <div id="rrzm_40" className="rev_row_zone rev_row_zone_middle" style={{zIndex: 5}}>
                        {/* LAYER NR. 1 */}
                        <div className="tp-caption  " id="slide-40-layer-2" data-x data-y="center" data-voffset={-210} data-width="['auto']" data-height="['auto']" data-type="row" data-columnbreak={3} data-responsive_offset="on" data-responsive="off" data-frames="[{&quot;delay&quot;:10,&quot;speed&quot;:300,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 5, whiteSpace: 'nowrap', fontSize: '20px', lineHeight: '22px', fontWeight: 400, color: '#ffffff', letterSpacing: '0px'}}>
                        {/* LAYER NR. 2 */}
                        <div className="tp-caption  " id="slide-40-layer-3" data-x={100} data-y={100} data-width="['auto']" data-height="['auto']" data-type="column" data-responsive_offset="on" data-responsive="off" data-frames="[{&quot;delay&quot;:&quot;+0&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-columnwidth="75%" data-verticalalign="top" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 6, width: '100%'}}>
                            {/* LAYER NR. 3 */}
                            <div className="tp-caption  tp-no-events   tp-resizeme" id="slide-40-layer-5" data-x data-y data-height="['auto']" data-type="text" data-responsive_offset="on" data-fontsize="['50', '45', '40', '32']" data-lineheight="['64', '55', '43', '38']" data-frames="[{&quot;delay&quot;:&quot;+0&quot;,&quot;split&quot;:&quot;chars&quot;,&quot;splitdelay&quot;:0.05,&quot;speed&quot;:2000,&quot;split_direction&quot;:&quot;forward&quot;,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:[105%];z:0;rX:45deg;rY:0deg;rZ:90deg;sX:1;sY:1;skX:0;skY:0;&quot;,&quot;mask&quot;:&quot;x:0px;y:0px;s:inherit;e:inherit;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power4.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power2.easeIn&quot;}]" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[30,30,30,30]" data-marginleft="[0,0,0,0]" data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 7, maxWidth: '930px', whiteSpace: 'normal', fontSize: '60px', lineHeight: '70px', fontWeight: 600, color: '#ffffff', letterSpacing: '0px', display: 'block', pointerEvents: 'none'}}>
                            Business getting bigger <br />
                            and hard to manage?
                            </div>
                            {/* LAYER NR. 4 */}
                            <div className="tp-caption   tp-resizeme" id="slide-40-layer-6" data-x data-y data-height="['auto']" data-type="text" data-responsive_offset="on" data-fontsize="['20', '20', '18', '16']" data-lineheight="['36', '36', '24', '22']" data-frames="[{&quot;delay&quot;:&quot;+2600&quot;,&quot;speed&quot;:1500,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;y:50px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[35,35,35,35]" data-marginleft="[0,0,0,0]" data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 8, maxWidth: '731px', whiteSpace: 'normal', fontSize: '20px', lineHeight: '36px', fontWeight: 400, color: '#ffffff', letterSpacing: '0px', display: 'block'}}>
                            We simplify your business with our software <br /> and premium hardware.
                            </div>
                            {/* LAYER NR. 5 */}
                            <div className="tp-caption" id="slide-40-layer-10" data-x data-y data-width="['auto']" data-height="['auto']" data-type="text" data-responsive_offset="on" data-frames="[{&quot;delay&quot;:&quot;+3600&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 9, whiteSpace: 'normal', display: 'inline-block'}}>
                                {isTabletOrMobile ? <NavLink to="/contact-us"  className="btn btn--rounded btn-secondary">Contact Now!</NavLink> : ''}</div>
                            {/* LAYER NR. 6 */}
                            {/*<div className="tp-caption" id="slide-40-layer-11" data-x data-y data-width="['auto']" data-height="['auto']" data-type="text" data-responsive_offset="on" data-frames="[{&quot;delay&quot;:&quot;+3800&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[30,30,30,30]" data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 10, whiteSpace: 'normal', display: 'inline-block'}}>
                            <NavLink to="/at_demo" onClick={noAction} className="btn btn--rounded btn-outline-light">Learn More</NavLink></div>*/}
                        </div>
                        {/* LAYER NR. 7 */}
                        <div className="tp-caption  " id="slide-40-layer-4" data-x={100} data-y={100} data-width="['auto']" data-height="['auto']" data-type="column" data-responsive_offset="on" data-responsive="off" data-frames="[{&quot;delay&quot;:&quot;+0&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-columnwidth="25%" data-verticalalign="top" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 11, width: '100%'}} />
                        </div>
                    </div>
                    </li>
                </ul>
                <div style={{}} className="tp-static-layers">
                    {/* LAYER NR. 15 */}

                    {/*<RequestCall />*/}
                </div>
                <div className="tp-bannertimer tp-bottom" style={{visibility: 'hidden !important'}} />
                </div>
            </div>
        </Fragment>

    )
}


export default Revolution_17;


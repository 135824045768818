import React from 'react';

const TestimonialSixCon = (props) => {
    const {img, name, subtitle, content} = props;
    return (
        <div className="carousel-single">
            <img src={img} alt="" className="rounded-circle" />
            <h5>{name}</h5>
            <span className="sub-text">{subtitle}</span>
            <p>{content}</p>
        </div> 
        // <!-- end: .carousel-single -->
    )
}
export default TestimonialSixCon;
import React from 'react';
import { NavLink } from 'react-router-dom';
const noAction = e => e.preventDefault();
const ContentAbove = ()=> {
    return (
        <section className="splitted-banner bgimage">
            <div className="split-bg"></div>
            <div className="bg_image_holder">
                <img src="./assets/img/s-banner.jpg" alt="" />
            </div>
            <div className="container content_above">
                <div className="row">
                    <div className="col-lg-7">
                        <h1>Build a rewarding <span>Career</span> with lots of variety and challenge and make a difference</h1>
                        <div className="btns">
                            <NavLink to="/at_demo" onClick={noAction} className="btn btn-primary">Visit Career</NavLink>
                            <NavLink to="/at_demo" onClick={noAction} className="btn btn-outline-light">Open Roles</NavLink>
                        </div>
                    </div>
                </div>{/*<!-- ends .row -->*/}
            </div>
        </section>
    )
}
export default ContentAbove;
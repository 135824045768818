import $ from "jquery";
import React, { Component } from 'react';
import TestimonialsCont from '../../content/testimonial/testimonial-1';
import OwlCarousel from 'react-owl-carousel2';
import {connect} from 'react-redux';

let rtlCheck = $("body").css("direction");
let falsy = false;
if(rtlCheck === "rtl"){
    falsy = true;
}


const options = {
    items: 1,
    rtl: falsy,
    nav: false,
    dots: true,
    dotsClass: "owl-dots dot-circle",
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 1
        },
        1000: {
            items: 1
        }
    }
}

class Testimonials extends Component {
    render() {
        const {testimonial} = this.props

        return (

            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                            <OwlCarousel options={options} className="testimonial-carousel-one owl-carousel">
                            {
                                Object.values(testimonial).map((value, key) => {
                                    return <TestimonialsCont
                                    name={value.name}
                                    subtitle={value.subTitle}
                                    content={value.content}
                                    img={value.imgSrc}
                                    key={key+1}
                                        />
                                })
                            }
                            </OwlCarousel>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {

    return {
        testimonial : state.testimonial
    }
}
export default connect(mapStateToProps)(Testimonials)

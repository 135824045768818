import React from 'react';
import { NavLink } from 'react-router-dom';
const noAction = e => e.preventDefault();

export const IconBoxOne = (props) => {
    return (
        <section className={props.padding ? props.padding : 'p-top-105 p-bottom-55'}>
            <div className="icon-boxes icon-box--one">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="icon-box icon-box-one text-center">
                                <span className="color-secondary"><i className="la la-support"></i></span>
                                <h6 className="color-dark">Business Consulting</h6>
                                <p>Investig ationes demons trave runt lectores legere lius quod legunt saepiu.</p>
                            </div>{/*<!-- ends: .icon-box -->*/}
                        </div>{/*<!-- ends: .col-lg-3 -->*/}
                        <div className="col-lg-3 col-md-6">
                            <div className="icon-box icon-box-one text-center">
                                <span className="color-secondary"><i className="la la-lightbulb-o"></i></span>
                                <h6 className="color-dark">Valuable Ideas</h6>
                                <p>Investig ationes demons trave runt lectores legere lius quod legunt saepiu.</p>
                            </div>{/*<!-- ends: .icon-box -->*/}
                        </div>{/*<!-- ends: .col-lg-3 -->*/}
                        <div className="col-lg-3 col-md-6">
                            <div className="icon-box icon-box-one text-center">
                                <span className="color-secondary"><i className="la la-bar-chart"></i></span>
                                <h6 className="color-dark">Industry Experience</h6>
                                <p>Investig ationes demons trave runt lectores legere lius quod legunt saepiu.</p>
                            </div>{/*<!-- ends: .icon-box -->*/}
                        </div>{/*<!-- ends: .col-lg-3 -->*/}
                        <div className="col-lg-3 col-md-6">
                            <div className="icon-box icon-box-one text-center">
                                <span className="color-secondary"><i className="la la-money"></i></span>
                                <h6 className="color-dark">Budget Friendly</h6>
                                <p>Investig ationes demons trave runt lectores legere lius quod legunt saepiu.</p>
                            </div>{/*<!-- ends: .icon-box -->*/}
                        </div>{/*<!-- ends: .col-lg-3 -->*/}
                    </div>
                </div>
            </div>{/*<!-- ends: .icon-boxes -->*/}
        </section>
    )
}


export const ServiceIconBox = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-4 col-sm-6">

                    <div className="icon-box-five">
                        <span className="icon-rounded-sm"><i className="la la-sun-o"></i></span>
                        <h6>Business Services</h6>
                        <p>Investig ationes demons wtravge vunt lectores legere lokrus quodk legunt saepius claritas est.</p>
                        <NavLink to="/service" className="btn btn-sm btn-outline-secondary">See Details</NavLink>
                    </div>{/*<!-- ends: .icon-box -->*/}

                </div>
                <div className="col-lg-4 col-sm-6">

                    <div className="icon-box-five">
                        <span className="icon-rounded-sm"><i className="la la-cart-plus"></i></span>
                        <h6>Product Consulting</h6>
                        <p>Investig ationes demons wtravge vunt lectores legere lokrus quodk legunt saepius claritas est.</p>
                        <NavLink to="/service" className="btn btn-sm btn-outline-secondary">See Details</NavLink>
                    </div>{/*<!-- ends: .icon-box -->*/}

                </div>
                <div className="col-lg-4 col-sm-6">

                    <div className="icon-box-five">
                        <span className="icon-rounded-sm"><i className="la la-bar-chart"></i></span>
                        <h6>Financial Consulting</h6>
                        <p>Investig ationes demons wtravge vunt lectores legere lokrus quodk legunt saepius claritas est.</p>
                        <NavLink to="/service" className="btn btn-sm btn-outline-secondary">See Details</NavLink>
                    </div>{/*<!-- ends: .icon-box -->*/}

                </div>
                <div className="col-lg-4 col-sm-6">

                    <div className="icon-box-five">
                        <span className="icon-rounded-sm"><i className="la la-money"></i></span>
                        <h6>Investment Planning</h6>
                        <p>Investig ationes demons wtravge vunt lectores legere lokrus quodk legunt saepius claritas est.</p>
                        <NavLink to="/service" className="btn btn-sm btn-outline-secondary">See Details</NavLink>
                    </div>{/*<!-- ends: .icon-box -->*/}

                </div>
                <div className="col-lg-4 col-sm-6">

                    <div className="icon-box-five">
                        <span className="icon-rounded-sm"><i className="la la-line-chart"></i></span>
                        <h6>Business Growth</h6>
                        <p>Investig ationes demons wtravge vunt lectores legere lokrus quodk legunt saepius claritas est.</p>
                        <NavLink to="/service" className="btn btn-sm btn-outline-secondary">See Details</NavLink>
                    </div>{/*<!-- ends: .icon-box -->*/}

                </div>
                <div className="col-lg-4 col-sm-6">

                    <div className="icon-box-five">
                        <span className="icon-rounded-sm"><i className="la la-lightbulb-o"></i></span>
                        <h6>Financial Planning</h6>
                        <p>Investig ationes demons wtravge vunt lectores legere lokrus quodk legunt saepius claritas est.</p>
                        <NavLink to="/service" className="btn btn-sm btn-outline-secondary">See Details</NavLink>
                    </div>{/*<!-- ends: .icon-box -->*/}

                </div>
            </div>
        </div>

    )
}


export const IconBoxFifteen = () => {
    return (
        <section className="p-top-110 p-bottom-80 section-bg">
            <div className="icon-boxes icon-box--fifteen">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="icon-box icon-box-15 text-center">
                                <img src="./assets/img/service7.png" alt="" />
                                <h6>Principal Investing</h6>
                                <p>Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi archi. Totam rem aperiam, eaque ipsa.</p>
                            </div>{/*<!-- ends: .icon-box -->*/}
                        </div>{/*<!-- ends: .col-lg-4 -->*/}
                        <div className="col-lg-4 col-md-6">
                            <div className="icon-box icon-box-15 text-center">
                                <img src="./assets/img/service8.png" alt="" />
                                <h6>Project Development</h6>
                                <p>Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi archi. Totam rem aperiam, eaque ipsa.</p>
                            </div>{/*<!-- ends: .icon-box -->*/}
                        </div>{/*<!-- ends: .col-lg-4 -->*/}
                        <div className="col-lg-4 col-md-6">
                            <div className="icon-box icon-box-15 text-center">
                                <img src="./assets/img/service9.png" alt="" />
                                <h6>Financial Advisory</h6>
                                <p>Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi archi. Totam rem aperiam, eaque ipsa.</p>
                            </div>{/*<!-- ends: .icon-box -->*/}
                        </div>{/*<!-- ends: .col-lg-4 -->*/}
                    </div>{/*<!-- ends: .row -->*/}
                </div>
            </div>{/*<!-- ends: .icon-boxes -->*/}
        </section>

    )
}

export const IconBoxTeen = (props) => {
    return (
        <section className={props.padding ? props.padding : 'p-top-110 p-bottom-80'}>
            <div className="icon-boxes icon-box--ten">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="icon-box-img text-center">
                                <div className="icon"><img src="./assets/img/svg/businessman.svg" alt="" className="svg" /></div>
                                <h6>Business Strategy &amp; Goal</h6>
                                <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt saepius claritas est.</p>
                            </div>{/*<!-- ends: .icon-box -->*/}
                        </div>{/*<!-- ends: .col-lg-4 -->*/}
                        <div className="col-lg-4 col-md-6">
                            <div className="icon-box-img text-center">
                                <div className="icon"><img src="./assets/img/svg/analysis.svg" alt="" className="svg" /></div>
                                <h6>Startup Business &amp; Planning</h6>
                                <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt saepius claritas est.</p>
                            </div>{/*<!-- ends: .icon-box -->*/}
                        </div>{/*<!-- ends: .col-lg-4 -->*/}
                        <div className="col-lg-4 col-md-6">
                            <div className="icon-box-img text-center">
                                <div className="icon"><img src="./assets/img/svg/crowdfunding.svg" alt="" className="svg" /></div>
                                <h6>Industrial Goods &amp; Services</h6>
                                <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt saepius claritas est.</p>
                            </div>{/*<!-- ends: .icon-box -->*/}
                        </div>{/*<!-- ends: .col-lg-4 -->*/}
                    </div>{/*<!-- ends: .row -->*/}
                </div>
            </div>{/*<!-- ends: .icon-boxes -->*/}
        </section>
    )
}
export const IconBoxEleven = () => {
    return (
        <section className="icon-boxes icon-box--eleven">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="icon-box-img2 d-flex">
                            <div className="box-icon">
                                <img src="./assets/img/svg/businessman.svg" alt="" className="svg" />
                            </div>
                            <div className="box-content">
                                <h6>Business Services</h6>
                                <p>Investig ationes demons trave runt lectores legere liusry quod ii legunt saepius claritas.</p>
                            </div>
                        </div>{/*<!-- ends: .icon-box -->*/}
                    </div>{/*<!-- ends: .col-lg-4 -->*/}
                    <div className="col-lg-4 col-md-6">
                        <div className="icon-box-img2 d-flex">
                            <div className="box-icon">
                                <img src="./assets/img/svg/analysis.svg" alt="" className="svg" />
                            </div>
                            <div className="box-content">
                                <h6>Product Consulting</h6>
                                <p>Investig ationes demons trave runt lectores legere liusry quod ii legunt saepius claritas.</p>
                            </div>
                        </div>{/*<!-- ends: .icon-box -->*/}
                    </div>{/*<!-- ends: .col-lg-4 -->*/}
                    <div className="col-lg-4 col-md-6">
                        <div className="icon-box-img2 d-flex">
                            <div className="box-icon">
                                <img src="./assets/img/svg/cash.svg" alt="" className="svg" />
                            </div>
                            <div className="box-content">
                                <h6>Financial Consulting</h6>
                                <p>Investig ationes demons trave runt lectores legere liusry quod ii legunt saepius claritas.</p>
                            </div>
                        </div>{/*<!-- ends: .icon-box -->*/}
                    </div>{/*<!-- ends: .col-lg-4 -->*/}
                    <div className="col-lg-4 col-md-6">
                        <div className="icon-box-img2 d-flex">
                            <div className="box-icon">
                                <img src="./assets/img/svg/crowdfunding.svg" alt="" className="svg" />
                            </div>
                            <div className="box-content">
                                <h6>Investment Planning</h6>
                                <p>Investig ationes demons trave runt lectores legere liusry quod ii legunt saepius claritas.</p>
                            </div>
                        </div>{/*<!-- ends: .icon-box -->*/}
                    </div>{/*<!-- ends: .col-lg-4 -->*/}
                    <div className="col-lg-4 col-md-6">
                        <div className="icon-box-img2 d-flex">
                            <div className="box-icon">
                                <img src="./assets/img/svg/statistics.svg" alt="" className="svg" />
                            </div>
                            <div className="box-content">
                                <h6>Business Growth</h6>
                                <p>Investig ationes demons trave runt lectores legere liusry quod ii legunt saepius claritas.</p>
                            </div>
                        </div>{/*<!-- ends: .icon-box -->*/}
                    </div>{/*<!-- ends: .col-lg-4 -->*/}
                    <div className="col-lg-4 col-md-6">
                        <div className="icon-box-img2 d-flex">
                            <div className="box-icon">
                                <img src="./assets/img/svg/business-and-finance.svg" alt="" className="svg" />
                            </div>
                            <div className="box-content">
                                <h6>Financial Planning</h6>
                                <p>Investig ationes demons trave runt lectores legere liusry quod ii legunt saepius claritas.</p>
                            </div>
                        </div>{/*<!-- ends: .icon-box -->*/}
                    </div>{/*<!-- ends: .col-lg-4 -->*/}
                </div>{/*<!-- ends: .row -->*/}
            </div>
        </section>
    )
}

export const GridIcon = () => {
    return (

    <section className="grid-wrapper section-bg p-top-120 p-bottom-90">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="row logo-grid-two">

                        <div className="col-lg-3 col-md-6">
                            <div className="grid-single shadow-sm border d-flex justify-content-center align-items-center">
                                <img src="./assets/img/cl9.png" alt="" />
                            </div>
                        </div>{/*<!-- ends: .col-lg-3 -->*/}

                        <div className="col-lg-3 col-md-6" >
                            <div className="grid-single shadow-sm border d-flex justify-content-center align-items-center">
                                <img src="./assets/img/cl10.png" alt="" />
                            </div>
                        </div>{/*<!-- ends: .col-lg-3 -->*/}

                        <div className="col-lg-3 col-md-6">
                            <div className="grid-single shadow-sm border d-flex justify-content-center align-items-center">
                                <img src="./assets/img/cl11.png" alt="" />
                            </div>
                        </div>{/*<!-- ends: .col-lg-3 -->*/}
                        <div className="col-lg-3 col-md-6">
                            <div className="grid-single shadow-sm border d-flex justify-content-center align-items-center">
                                <img src="./assets/img/cl12.png" alt="" />
                            </div>
                        </div>{/*<!-- ends: .col-lg-3 -->*/}
                        <div className="col-lg-3 col-md-6">
                            <div className="grid-single shadow-sm border d-flex justify-content-center align-items-center">
                                <img src="./assets/img/cl10.png" alt="" />
                            </div>
                        </div>{/*<!-- ends: .col-lg-3 -->*/}
                        <div className="col-lg-3 col-md-6">
                            <div className="grid-single shadow-sm border d-flex justify-content-center align-items-center">
                                <img src="./assets/img/cl12.png" alt="" />
                            </div>
                        </div>{/*<!-- ends: .col-lg-3 -->*/}
                        <div className="col-lg-3 col-md-6">
                            <div className="grid-single shadow-sm border d-flex justify-content-center align-items-center">
                                <img src="./assets/img/cl11.png" alt="" />
                            </div>
                        </div>{/*<!-- ends: .col-lg-3 -->*/}
                        <div className="col-lg-3 col-md-6">
                            <div className="grid-single shadow-sm border d-flex justify-content-center align-items-center">
                                <img src="./assets/img/cl9.png" alt="" />
                            </div>
                        </div>{/*<!-- ends: .col-lg-3 -->*/}

                    </div>
                </div>{/*<!-- ends: .col-lg-12 -->*/}
            </div>{/*<!-- ends: .row -->*/}
        </div> {/*<!-- ends: .container -->*/}
    </section>
    )
}


export const IconBoxFour = () => {
    return (
        <div className="icon-boxes icon-box--four">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="icon-box-four d-flex">
                            <div className="box-icon">
                                <span className="icon-rounded-sm"><i className="la la-cloud"></i></span>
                            </div>
                            <div className="box-content">
                                <h6>Cloud Based System</h6>
                                <p>You have the ability to access data from home, on holiday, or via the commute to and from work, anywhere and anytime.</p>
                            </div>
                        </div>{/*<!-- ends: .icon-box -->*/}
                    </div>{/*<!-- ends: .col-lg-4 -->*/}
                    <div className="col-lg-4 col-md-6">
                        <div className="icon-box-four d-flex">
                            <div className="box-icon">
                                <span className="icon-rounded-sm"><i className="la la-share-alt"></i></span>
                            </div>
                            <div className="box-content">
                                <h6>Channel Manager Enabled</h6>
                                <p>Tired of managing your bookings, availability and rates at multiple OTAs? Ratri PMS works as one stop center to deal with all that stuff. Hassle free!</p>
                            </div>
                        </div>{/*<!-- ends: .icon-box -->*/}
                    </div>{/*<!-- ends: .col-lg-4 -->*/}
                    <div className="col-lg-4 col-md-6">
                        <div className="icon-box-four d-flex">
                            <div className="box-icon">
                                <span className="icon-rounded-sm"><i className="la la-money"></i></span>
                            </div>
                            <div className="box-content">
                                <h6>Budget Friendly</h6>
                                <p>Ratri PMS is an affordable system with exciting features to cater budget hotels, boutique hotels, resorts, hostels, lodge and homestay. No hidden charges.</p>
                            </div>
                        </div>{/*<!-- ends: .icon-box -->*/}
                    </div>{/*<!-- ends: .col-lg-4 -->*/}
                    <div className="col-lg-4 col-md-6">
                        <div className="icon-box-four d-flex">
                            <div className="box-icon">
                                <span className="icon-rounded-sm"><i className="la la-square-o"></i></span>
                            </div>
                            <div className="box-content">
                                <h6>MyKad Reader Integrated</h6>
                                <p>Cut down your booking and check in process into half by using MyKad Reader. By just one click, your booking/check-in form will be filled automatically.</p>
                            </div>
                        </div>{/*<!-- ends: .icon-box -->*/}
                    </div>{/*<!-- ends: .col-lg-4 -->*/}
                    <div className="col-lg-4 col-md-6">
                        <div className="icon-box-four d-flex">
                            <div className="box-icon">
                                <span className="icon-rounded-sm"><i className="la la-credit-card"></i></span>
                            </div>
                            <div className="box-content">
                                <h6>Key Card Integration</h6>
                                <p>No more struggle with two separate systems during check-in/check-out. Issue your room key card using our Ratri PMS, integrated with major door lock brands. </p>
                            </div>
                        </div>{/*<!-- ends: .icon-box -->*/}
                    </div>{/*<!-- ends: .col-lg-4 -->*/}
                    <div className="col-lg-4 col-md-6">
                        <div className="icon-box-four d-flex">
                            <div className="box-icon">
                                <span className="icon-rounded-sm"><i className="la la-bar-chart-o"></i></span>
                            </div>
                            <div className="box-content">
                                <h6>Reports</h6>
                                <p>Ratri PMS helps hotel managers do their job more effectively by putting comprehensive information at their fingertips.</p>
                            </div>
                        </div>{/*<!-- ends: .icon-box -->*/}
                    </div>{/*<!-- ends: .col-lg-4 -->*/}
                </div>
            </div>
        </div>
    )
}


export const IconBoxThree = (props) => {
    return (
        <section className="section-bg p-top-100 p-bottom-80">
            {
                props.name && (
                    <div className="m-bottom-50">
                        <div className="divider text-center">
                            <h1 className="color-dark">{props.name}</h1>
                            <p className="mx-auto d-none"></p>
                        </div>
                    </div>
                )
            }

            <div className="icon-boxes section-bg icon-box--three">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">

                            <div className="icon-box-three text-center">
                                <span className="icon-rounded-lg"><i className="la la-android"></i></span>
                                <h6 className="color-dark"><NavLink to={"/service2"}>Software</NavLink></h6>
                                <ul className="bullet--list1 m-top-25">
                                    <li className="bullet_list"><strong className="color-dark">Ratri Property Management System (PMS)</strong></li>
                                    <li className="bullet_list"><strong className="color-dark">Ratri Employee & Time Management System (ETMS)</strong></li>
                                </ul>
                               {/* <p>Investig ationes demons trave runt lectores legere liusry quod ii legunt saepius claritas Investig ationes.</p>*/}
                            </div>{/*<!-- ends: .icon-box -->*/}

                        </div>{/*<!-- ends: .col-lg-4 -->*/}
                        <div className="col-lg-4 col-md-6">

                            <div className="icon-box-three text-center">
                                <span className="icon-rounded-lg"><i className="la la-plug"></i></span>
                                <h6 className="color-dark"><NavLink to={"/service-hardware"}>Hardware</NavLink></h6>
                                <ul className="bullet--list1 m-top-25">
                                    <li className="bullet_list"><strong className="color-dark">Door Lock</strong></li>
                                    <li className="bullet_list"><strong className="color-dark">Wifi & CCTV</strong></li>
                                    <li className="bullet_list"><strong className="color-dark">Energy Saving Switch</strong></li>
                                    <li className="bullet_list"><strong className="color-dark">MyKad Reader</strong></li>
                                    <li className="bullet_list"><strong className="color-dark">Door Access </strong></li>
                                    <li className="bullet_list"><strong className="color-dark">Computers & Printers</strong></li>
                                    <li className="bullet_list"><strong className="color-dark">Fingerprint/Digital Punch Card</strong></li>
                                </ul>
                                {/*<p>Investig ationes demons trave runt lectores legere liusry quod ii legunt saepius claritas Investig ationes.</p>*/}
                            </div>{/*<!-- ends: .icon-box -->*/}

                        </div>{/*<!-- ends: .col-lg-4 -->*/}
                        <div className="col-lg-4 col-md-6">

                            <div className="icon-box-three text-center">
                                <span className="icon-rounded-lg"><i className="la la-dashboard"></i></span>
                                <h6 className="color-dark"><NavLink to={"/service-consultation"}>Custom Software Development & Consultation</NavLink></h6>
                                <ul className="bullet--list1 m-top-25">
                                    <li className="bullet_list"><strong className="color-dark">Software Development</strong></li>
                                    <li className="bullet_list"><strong className="color-dark">BI & IOT</strong></li>
                                    <li className="bullet_list"><strong className="color-dark">Cloud Computing</strong></li>
                                    <li className="bullet_list"><strong className="color-dark">Mobile Application</strong></li>
                                    <li className="bullet_list"><strong className="color-dark">Enterprise Solution</strong></li>
                                    <li className="bullet_list"><strong className="color-dark">Analytics Solution</strong></li>
                                </ul>
                                {/*<p>Investig ationes demons trave runt lectores legere liusry quod ii legunt saepius claritas Investig ationes.</p>*/}
                            </div>{/*<!-- ends: .icon-box -->*/}

                        </div>{/*<!-- ends: .col-lg-4 -->*/}
                        {/*<div className="col-lg-4 col-md-6">

                            <div className="icon-box-three text-center">
                                <span className="icon-rounded-lg"><i className="la la-money"></i></span>
                                <h6 className="color-dark">Investment Planning</h6>
                                <p>Investig ationes demons trave runt lectores legere liusry quod ii legunt saepius claritas Investig ationes.</p>
                            </div><!-- ends: .icon-box -->

                        </div><!-- ends: .col-lg-4 -->
                        <div className="col-lg-4 col-md-6">

                            <div className="icon-box-three text-center">
                                <span className="icon-rounded-lg"><i className="la la-line-chart"></i></span>
                                <h6 className="color-dark">Business Growth</h6>
                                <p>Investig ationes demons trave runt lectores legere liusry quod ii legunt saepius claritas Investig ationes.</p>
                            </div><!-- ends: .icon-box -->

                        </div><!-- ends: .col-lg-4 -->
                        <div className="col-lg-4 col-md-6">

                            <div className="icon-box-three text-center">
                                <span className="icon-rounded-lg"><i className="la la-lightbulb-o"></i></span>
                                <h6 className="color-dark">Financial Planning</h6>
                                <p>Investig ationes demons trave runt lectores legere liusry quod ii legunt saepius claritas Investig ationes.</p>
                            </div><!-- ends: .icon-box -->

                        </div><!-- ends: .col-lg-4 -->*/}
                    </div>{/*<!-- ends: .row -->*/}
                </div>
            </div>{/*<!-- ends: .icon-boxes -->*/}

        </section>
    )
}

export const IconBoxEight = () => {
    return (
        <section className="icon-boxes icon-box--eight">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="icon-box-eight text-center text-md-left">
                            <span className="icon-square-sm"><i className="la la-thumbs-up"></i></span>
                            <h6>Valuable Ideas</h6>
                            <p>Investig ationes demons travg vunt lecto res legere lyrus quod legunt saepius clar itasest demons travg vuntwn.</p>
                        </div>{/*<!-- ends: .icon-box -->*/}
                    </div>{/*<!-- ends: .col-lg-4 -->*/}
                    <div className="col-lg-4 col-md-6">
                        <div className="icon-box-eight text-center text-md-left">
                            <span className="icon-square-sm"><i className="la la-bar-chart"></i></span>
                            <h6>Industry Experience</h6>
                            <p>Investig ationes demons travg vunt lecto res legere lyrus quod legunt saepius clar itasest demons travg vuntwn.</p>
                        </div>{/*<!-- ends: .icon-box -->*/}
                    </div>{/*<!-- ends: .col-lg-4 -->*/}
                    <div className="col-lg-4 col-md-6">
                        <div className="icon-box-eight text-center text-md-left">
                            <span className="icon-square-sm"><i className="la la-money"></i></span>
                            <h6>Budget Friendly</h6>
                            <p>Investig ationes demons travg vunt lecto res legere lyrus quod legunt saepius clar itasest demons travg vuntwn.</p>
                        </div>{/*<!-- ends: .icon-box -->*/}
                    </div>{/*<!-- ends: .col-lg-4 -->*/}
                    <div className="col-lg-4 col-md-6">
                        <div className="icon-box-eight text-center text-md-left">
                            <span className="icon-square-sm"><i className="la la-edit"></i></span>
                            <h6>Investment Planning</h6>
                            <p>Investig ationes demons travg vunt lecto res legere lyrus quod legunt saepius clar itasest demons travg vuntwn.</p>
                        </div>{/*<!-- ends: .icon-box -->*/}
                    </div>{/*<!-- ends: .col-lg-4 -->*/}
                    <div className="col-lg-4 col-md-6">
                        <div className="icon-box-eight text-center text-md-left">
                            <span className="icon-square-sm"><i className="la la-area-chart"></i></span>
                            <h6>Business Growth</h6>
                            <p>Investig ationes demons travg vunt lecto res legere lyrus quod legunt saepius clar itasest demons travg vuntwn.</p>
                        </div>{/*<!-- ends: .icon-box -->*/}
                    </div>{/*<!-- ends: .col-lg-4 -->*/}
                    <div className="col-lg-4 col-md-6">
                        <div className="icon-box-eight text-center text-md-left">
                            <span className="icon-square-sm"><i className="la la-level-up"></i></span>
                            <h6>Financial Planning</h6>
                            <p>Investig ationes demons travg vunt lecto res legere lyrus quod legunt saepius clar itasest demons travg vuntwn.</p>
                        </div>{/*<!-- ends: .icon-box -->*/}
                    </div>{/*<!-- ends: .col-lg-4 -->*/}
                </div>{/*<!-- ends: .row -->*/}
            </div>
        </section>

    )
}

export const IconBoxSeven = () => {
    return (
        <section className="p-top-110 p-bottom-80 section-bg">
            <div className="flip-boxes icon-box--seven">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="flip-card">
                                <div className="flip-wrapper">
                                    <div className="flip-front">
                                        <div className="front-contents">
                                            <span className="color-primary"><i className="la la-area-chart"></i></span>
                                            <h6>Customer Strategy &amp; Marketing</h6>
                                            <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt saepius claritas est.</p>
                                        </div>
                                        <div className="flip-overlay"></div>
                                    </div>{/*<!-- ends: .flip-front -->*/}
                                    <div className="flip-back">
                                        <div className="back-contents">
                                            <h5 className="color-light">Customer Strategy &amp; Marketing</h5>
                                            <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt saepius claritas est.</p>
                                            <NavLink to="/" onClick={noAction} className="btn btn-light btn-sm">See Details</NavLink>
                                        </div>
                                        <div className="flip-overlay2"></div>
                                    </div>{/*<!-- ends: .flip-back -->*/}
                                </div>{/*<!-- ends: .flip-wrapper -->*/}
                            </div>{/*<!-- ends: .flip-card -->*/}
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="flip-card">
                                <div className="flip-wrapper">
                                    <div className="flip-front">
                                        <div className="front-contents">
                                            <span className="color-primary"><i className="la la-lightbulb-o"></i></span>
                                            <h6>Startup Business &amp; Planning</h6>
                                            <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt saepius claritas est.</p>
                                        </div>
                                        <div className="flip-overlay"></div>
                                    </div>{/*<!-- ends: .flip-front -->*/}
                                    <div className="flip-back">
                                        <div className="back-contents">
                                            <h5 className="color-light">Startup Business &amp; Planning</h5>
                                            <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt saepius claritas est.</p>
                                            <NavLink to="/" onClick={noAction} className="btn btn-light btn-sm">See Details</NavLink>
                                        </div>
                                        <div className="flip-overlay2"></div>
                                    </div>{/*<!-- ends: .flip-back -->*/}
                                </div>{/*<!-- ends: .flip-wrapper -->*/}
                            </div>{/*<!-- ends: .flip-card -->*/}
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="flip-card">
                                <div className="flip-wrapper">
                                    <div className="flip-front">
                                        <div className="front-contents">
                                            <span className="color-primary"><i className="la la-bar-chart"></i></span>
                                            <h6>Industrial Goods &amp; Services</h6>
                                            <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt saepius claritas est.</p>
                                        </div>
                                        <div className="flip-overlay"></div>
                                    </div>{/*<!-- ends: .flip-front -->*/}
                                    <div className="flip-back">
                                        <div className="back-contents">
                                            <h5 className="color-light">Industrial Goods &amp; Services</h5>
                                            <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt saepius claritas est.</p>
                                            <NavLink to="/" onClick={noAction} className="btn btn-light btn-sm">See Details</NavLink>
                                        </div>
                                        <div className="flip-overlay2"></div>
                                    </div>{/*<!-- ends: .flip-back -->*/}
                                </div>{/*<!-- ends: .flip-wrapper -->*/}
                            </div>{/*<!-- ends: .flip-card -->*/}
                        </div>
                    </div>{/*<!-- ends: .row -->*/}
                </div>
            </div>{/*<!-- ends: .flip-boxes -->*/}
        </section>

    )
}

export const IconBoxSixteen = () => {
    return (
        <div className="icon-boxes icon-box--sixteen col-lg-12">
            <div className="row">
                <div className="col-lg-4 col-md-6">
                    <div className="icon-box icon-box-15 text-center">
                        <img src="./assets/img/service6.png" alt="" />
                        <h6>Business Services</h6>
                        <p>Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi archi. Totam rem aperiam, eaque ipsa.</p>
                    </div>{/*<!-- ends: .icon-box -->*/}
                </div>{/*<!-- ends: .col-lg-4 -->*/}
                <div className="col-lg-4 col-md-6">
                    <div className="icon-box icon-box-15 text-center">
                        <img src="./assets/img/service5.png" alt="" />
                        <h6>Financial Consulting</h6>
                        <p>Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi archi. Totam rem aperiam, eaque ipsa.</p>
                    </div>{/*<!-- ends: .icon-box -->*/}
                </div>{/*<!-- ends: .col-lg-4 -->*/}
                <div className="col-lg-4 col-md-6">
                    <div className="icon-box icon-box-15 text-center">
                        <img src="./assets/img/service4.png" alt="" />
                        <h6>Investment Planning</h6>
                        <p>Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi archi. Totam rem aperiam, eaque ipsa.</p>
                    </div>{/*<!-- ends: .icon-box -->*/}
                </div>{/*<!-- ends: .col-lg-4 -->*/}
            </div>{/*<!-- ends: .row -->*/}
        </div>
    )
}

export const IconBoxTwo = () => {
    return (
        <div className="row">
            <div className="col-md-6 col-sm-6">
                <div className="icon-box icon-box-two text-md-left text-center">
                    <span className="color-primary"><i className="fa fa-compass"></i></span>
                    <h6 className="color-dark m-bottom-15">Our Mission</h6>
                    <p>To be one-stop-shop IT Solutions Provider Company, that provides timely and cost effective solutions to clients, without any barriers. Our mission is to cater clients and their firms with premier services and solutions.</p>
                </div>{/*<!-- ends: .icon-box -->*/}
            </div>{/*<!-- ends: .col-md-6 -->*/}
            <div className="col-md-6 col-sm-6">
                <div className="icon-box icon-box-two text-md-left text-center">
                    <span className="color-primary"><i className="fa fa-eye"></i></span>
                    <h6 className="color-dark m-bottom-15">Our Vision</h6>
                    <p>To create such type values that shall provide motivation for people to work towards being a part of it and will makes all clients and associates proud that they are part of it. We aim to provide online platforms to the constantly growing businesses.</p>
                </div>{/*<!-- ends: .icon-box -->*/}
            </div>{/*<!-- ends: .col-md-6 -->*/}
           {/* <div className="col-md-6 col-sm-6">
                <div className="icon-box icon-box-two text-md-left text-center">
                    <span className="color-primary"><i className="la la-bar-chart"></i></span>
                    <h6 className="color-dark m-bottom-15">Our Vision</h6>
                    <p>To create such type values that shall provide motivation for people to work towards being a part of it and will makes all clients and associates proud that they are part of it. We aim to provide online platforms to the constantly growing businesses.</p>
                </div><!-- ends: .icon-box -->
            </div><!-- ends: .col-md-6 -->*/}
            {/*<div className="col-md-6 col-sm-6">
                <div className="icon-box icon-box-two text-md-left text-center">
                    <span className="color-primary"><i className="la la-money"></i></span>
                    <h6 className="color-dark m-bottom-15">Budget Friendly</h6>
                    <p>Investig ationes demons trave runt lectores legere lius quod ii legunt saepius claritas est.</p>
                </div><!-- ends: .icon-box -->
            </div><!-- ends: .col-md-6 -->*/}
        </div>
    )
}

export const IconBoxThirteen = () => {
    return (
        <div className="icon-boxes icon-box--thirteen">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="icon-box text-center cardify icon-box-13">
                            <span className="color-secondary"><i className="la la-sun-o" /></span>
                            <h6>Client Focused</h6>
                            <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt saepius claritas est vunt the lectores legere quodk.</p>
                        </div>{/* ends: .icon-box */}
                    </div>{/* ends: .col-lg-4 */}
                    <div className="col-lg-4 col-md-6">
                        <div className="icon-box text-center cardify icon-box-13">
                            <span className="color-secondary"><i className="la la-crosshairs" /></span>
                            <h6>Leadership</h6>
                            <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt saepius claritas est vunt the lectores legere quodk.</p>
                        </div>{/* ends: .icon-box */}
                    </div>{/* ends: .col-lg-4 */}
                    <div className="col-lg-4 col-md-6">
                        <div className="icon-box text-center cardify icon-box-13">
                            <span className="color-secondary"><i className="la la-thumbs-o-up" /></span>
                            <h6>Execution Excellence</h6>
                            <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt saepius claritas est vunt the lectores legere quodk.</p>
                        </div>{/* ends: .icon-box */}
                    </div>{/* ends: .col-lg-4 */}
                    <div className="col-lg-4 col-md-6">
                        <div className="icon-box text-center cardify icon-box-13">
                            <span className="color-secondary"><i className="la la-plug" /></span>
                            <h6>Aspiration</h6>
                            <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt saepius claritas est vunt the lectores legere quodk.</p>
                        </div>{/* ends: .icon-box */}
                    </div>{/* ends: .col-lg-4 */}
                    <div className="col-lg-4 col-md-6">
                        <div className="icon-box text-center cardify icon-box-13">
                            <span className="color-secondary"><i className="la la-microphone" /></span>
                            <h6>Stewardship</h6>
                            <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt saepius claritas est vunt the lectores legere quodk.</p>
                        </div>{/* ends: .icon-box */}
                    </div>{/* ends: .col-lg-4 */}
                    <div className="col-lg-4 col-md-6">
                        <div className="icon-box text-center cardify icon-box-13">
                            <span className="color-secondary"><i className="la la-headphones" /></span>
                            <h6>Results</h6>
                            <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt saepius claritas est vunt the lectores legere quodk.</p>
                        </div>{/* ends: .icon-box */}
                    </div>{/* ends: .col-lg-4 */}
                </div>{/* ends: .row */}
            </div>
        </div>
    )
}

export const LogoBox = () => {
    return (
    <section className="grid-wrapper logo-details-wrapper section-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="grid-single logo-details text-center">
                <div className="logo">
                  <img src="./assets/img/cl9.png" alt="" />
                </div>
                <h5>ABeam Consulting</h5>
                <span className="sub-title color-secondary">www.abeam.com</span>
                <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt lectores legere quodk.</p>
              </div>{/* end: .grid-single */}
            </div>{/* ends: .col-lg-4 */}
            <div className="col-lg-4 col-md-6">
              <div className="grid-single logo-details text-center">
                <div className="logo">
                  <img src="./assets/img/cl12.png" alt="" />
                </div>
                <h5>Groupo ASSA</h5>
                <span className="sub-title color-secondary">www.grupoassa.com</span>
                <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt lectores legere quodk.</p>
              </div>{/* end: .grid-single */}
            </div>{/* ends: .col-lg-4 */}
            <div className="col-lg-4 col-md-6">
              <div className="grid-single logo-details text-center">
                <div className="logo">
                  <img src="./assets/img/cl13.png" alt="" />
                </div>
                <h5>West Monore Partners</h5>
                <span className="sub-title color-secondary">www.arete.com.tr</span>
                <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt lectores legere quodk.</p>
              </div>{/* end: .grid-single */}
            </div>{/* ends: .col-lg-4 */}
            <div className="col-lg-4 col-md-6">
              <div className="grid-single logo-details text-center">
                <div className="logo">
                  <img src="./assets/img/cl9.png" alt="" />
                </div>
                <h5>ABeam Consulting</h5>
                <span className="sub-title color-secondary">www.abeam.com</span>
                <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt lectores legere quodk.</p>
              </div>{/* end: .grid-single */}
            </div>{/* ends: .col-lg-4 */}
            <div className="col-lg-4 col-md-6">
              <div className="grid-single logo-details text-center">
                <div className="logo">
                  <img src="./assets/img/cl12.png" alt="" />
                </div>
                <h5>Groupo ASSA</h5>
                <span className="sub-title color-secondary">www.grupoassa.com</span>
                <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt lectores legere quodk.</p>
              </div>{/* end: .grid-single */}
            </div>{/* ends: .col-lg-4 */}
            <div className="col-lg-4 col-md-6">
              <div className="grid-single logo-details text-center">
                <div className="logo">
                  <img src="./assets/img/cl13.png" alt="" />
                </div>
                <h5>West Monore Partners</h5>
                <span className="sub-title color-secondary">www.arete.com.tr</span>
                <p>Investig ationes demons travge vunt lectores legere lyrus quodk legunt lectores legere quodk.</p>
              </div>{/* end: .grid-single */}
            </div>{/* ends: .col-lg-4 */}
          </div>{/* ends: .row */}
        </div>{/* ends: .container */}
      </section>
    )
}

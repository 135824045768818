import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import { ImageBlock } from '../../content/element/addressBlock';
import Map1 from '../../content/element/map/map-1';
import { IconBlock } from '../../content/element/addressBlock'
import Map3 from '../../content/element/map';
import { ContactForm } from '../../content/element/form/contact-form';
import { ContactForm5 } from '../../content/element/form/contact-form';
import { Tab3 } from '../../content/element/tab/tab-2';
import Map2 from '../../content/element/map/map-2';
import { GetInTouch } from '../../content/element/form/contact-form';

const ContactBlock = () => {
    return (
        <Fragment>

            <Header pageTitle="Contact Block" />
            {/* Contact Block 1 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Contact - 1</h3>
                        </div>
                    </div>
                </div>
            </div>
            <ImageBlock />
            <Map1 />
            <ContactForm5 />
            {/* Contact Block 1 */}

            {/* Contact Block 2 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Contact - 2</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="contact--four">
                <IconBlock />
                <div className="container p-top-100 p-bottom-110">
                    <div className="row">
                        <div className="col-lg-6">
                            <ContactForm />
                        </div>{/*<!-- ends: .col-lg-6 -->*/}
                        <div className="col-lg-6">
                            <Map3 />
                        </div>{/*<!-- ends: .col-lg-6 -->*/}
                    </div>
                </div>
            </section>{/*<!-- ends: .contact--four -->*/}
            {/* Contact Block 2 */}

            {/* Contact Block 3 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Contact - 3</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="p-top-0 p-bottom-105">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="m-bottom-50">
                                <div className="divider section-title-simple">
                                    <h2>Our Offices</h2>
                                    <p className="ml-auto mr-auto m-top-5">Investiga tiones demonstr averunt lectres legere me lius quod qua legunt saepius estre etiam cessus.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Tab3 />
            </section>
            <section className="google_map">
                <div className="map" id="map1">
                    <Map1 />
                </div>
            </section>
            <ContactForm5 />
            {/* Contact Block 3 */}

            {/* Contact Block 4 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Contact - 4</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="contact-split d-flex align-items-center">
                <Map2 />
                <GetInTouch />
            </section>
            {/* Contact Block 4 */}

            <Footer />
        </Fragment>
    )
}

export default ContactBlock;
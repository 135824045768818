import React, {Fragment} from 'react';
import $ from 'jquery';
import {NavLink} from 'react-router-dom';
import { connect } from 'react-redux';
import { Cart } from '../../../Store/action/cartActions';
import { toast, ToastContainer } from 'react-toastify';

$('body').on('click', '.search_trigger', function (e) {
    $(this).toggleClass('la-search la-close');
    $(this).parent('.search_module').children('.search_area').toggleClass('active');
});
const noAction = e => e.preventDefault();
class NavbarItem extends React.Component {
    _isMounted = false;
    state = {
        product: this.props.cart.product
    }
    componentDidMount() {
        this._isMounted = true;
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {

        setInterval(() => {
            var match = JSON.stringify(this.state);
            var match2 = localStorage.getItem('cart');
            if(match2 !== match) {
                console.log('ok');
                if(this._isMounted) {
                    this.setState({
                        product: JSON.parse(match2).product
                    }, () => this.props.addToCart(this.state))
                }
            }
        }, 100)

        //cart item viwe on notification popup
        var total_price = 0;
        Object.values(this.state.product).map((value, key) => {
            return total_price += parseInt( value[0].price);
        })

        //cart item remove
        const remove = (e) => {
            e.preventDefault();
            const cartCount = e.currentTarget.getAttribute('data')
            const code_item = Object.values(this.state.product).filter((item, key) => {
                return key !== parseInt(cartCount);
            });
            this.setState(
                { product: [...code_item] },
                () => this.props.addToCart(this.state)
            )
            toast.error("Cart Item deleted!");
        }
        // cart item controle panel end

        return (
            <Fragment>
                <ToastContainer />
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent22" aria-controls="navbarSupportedContent22" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="la la-bars"></span>
                </button>
                <div className="collapse navbar-collapse order-md-1 justify-content-start" id="navbarSupportedContent22">
                    <div className="m-right-15">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <NavLink className="nav-link"  to="/">Home
                                    {/*<span className="sr-only">(current)</span>*/}
                                </NavLink>
                               {/* <div className="mega-menu d-lg-flex flex-wrap flex-column flex-lg-row">
                                    <ul>
                                        <li>
                                            <NavLink to="/">Home Page 1</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/index2">Home Page 2</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/index3">Home Page 3</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/index4">Home Page 4</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/index5">Home Page 5</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/index6">Home Page 6</NavLink>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <NavLink to="/index7">Home Page 7</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/index8">Home Page 8</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/index9">Home Page 9</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/index10">Home Page 10</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/index11">Home Page 11</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/index12">Home Page 12</NavLink>
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <NavLink to="/index13">Home Page 13</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/index14">Home Page 14</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/index15">Home Page 15</NavLink>
                                        </li>
                                    </ul>

                                </div>*/}
                                {/* <!-- end: .mega-menu --> */}
                            </li>
                            <li className="nav-item has_mega-lg dropdown">
                                <a className="nav-link" href="# ">Our Products</a>
                                <div className="mega-menu mega-menu-lg d-lg-flex flex-wrap">
                                    <ul>
                                        <li>
                                            <h6>Product & Services</h6>
                                        </li>
                                        {/*<li>
                                            <NavLink to="/service">Service One</NavLink>
                                        </li>*/}
                                        <li>
                                            <NavLink to="/">Software</NavLink>
                                        </li>
                                       {/* <li>
                                            <NavLink to="/service3">Service Three</NavLink>
                                        </li>*/}
                                        <li>
                                            <NavLink to="/service-hardware">Hardware & Services</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/service-consultation">Custom Software Development & Consultation</NavLink>
                                        </li>
                                        {/*<li>
                                            <NavLink to="/service-icon">Services With Icon</NavLink>
                                        </li>*/}
                                    </ul>
                                    {/*<ul>
                                        <li>
                                            <h6>Projects</h6>
                                        </li>
                                        <li>
                                            <NavLink to="/project-grid-1">Project Grid 1 Column</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/project-grid-2">Project Grid 2 Column</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/project-grid-3">Project Grid 3 Column</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/project-grid-2-filter">Project Grid 2 Filter</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/project-grid-filter">Project Grid 3 Filter</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/project-masonry">Project masonry</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/project-details1">Project Single 1</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/project-2-details1">Project Single 2</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/project-3-details1">Project Single 3</NavLink>
                                        </li>
                                    </ul>*/}
                                   {/* <ul>
                                        <li>
                                            <h6>Team</h6>
                                        </li>
                                        <li>
                                            <NavLink to="/team-grid">Team Grid</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/team-list">Team list</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="team-details4">Team Single</NavLink>
                                        </li>
                                        <li>
                                            <h6>Events</h6>
                                        </li>
                                        <li>
                                            <NavLink to="/event-grid">Event Grid</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/event-list">Event List</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/event-details1">Event Details</NavLink>
                                        </li>
                                    </ul>*/}
                                    {/*<ul>
                                        <li>
                                            <h6>Others</h6>
                                        </li>
                                        <li>
                                            <NavLink to="/notfound">Page 404</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/faqs">Faqs</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/login">Login</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/register">Register</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/pricing">Pricing</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/testimonial">Testimonial</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/working-process">Working Process</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/careers">Careers</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/career-single">Career Single</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/apply">Apply Form</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/coming-soon">Coming Soon</NavLink>
                                        </li>
                                    </ul>*/}
                                </div>
                                {/*<!-- end: .mega-menu -->*/}
                            </li>
                            {/*<li className="nav-item has_mega-lg dropdown">
                                <a className="nav-link" href="/">Elements</a>
                                <div className="mega-menu mega-menu-lg d-lg-flex flex-wrap">
                                    <ul>
                                        <li>
                                            <NavLink to="/alerts">Alerts</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/accordion">Accordion</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/blockquotes">Blockquotes</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/box-shadow">Box Shadow</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/breadcrumb">Breadcrumbs</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/buttons">Buttons</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/call-to-action">Call To Actions</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/cards">Cards</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/carousels">Carousels</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/client">Clients</NavLink>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <NavLink to="/colors">Colors</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/contact-blocks">Contact Blocks</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/contact-forms">Contact Forms</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/content-blocks">Content Blocks</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/counter">Counters</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/dividers">Dividers</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/dropdowns">Dropdowns</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/embed-videos">Embed Videos</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/filters">Filters</NavLink>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <NavLink to="/flip-box">Flip Box</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/footers">Footers</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/form-elements">Form Elements</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/gallery">Gallery</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/graphs">Graphs</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/headers">Header Style</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/icon-box">Icon Box</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/lists">Lists</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/maps">Maps</NavLink>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <NavLink to="/modal">Modals</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/pagination">Pagination</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/parallax">Parallax</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/pricing-tables">Pricing Tables</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/processes">Processes</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/progress-bar">Progress Bar</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/range-slider">Range Slider</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/ratings">Ratings</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/sliders">Sliders</NavLink>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <NavLink to="/splitted-banners">Splitted Banners</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/subscribe">Subscribe</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/typography">Typography</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/tables">Tables</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/tabs">Tabs</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/teams">Teams</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/testimonial-styles">Testimonial Styles</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/timelines">Timelines</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/twitter-feed">Twitter Feed</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/video-background">Video Background</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/videos">Videos</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li>*/}
                            {/*<li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="# " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">About</a>
                                <div className="dropdown-menu">
                                    <NavLink className="dropdown-item" to="/about">About One</NavLink>
                                    <NavLink className="dropdown-item" to="/about-two">About Two</NavLink>
                                </div>
                            </li>*/}

                            <li className="nav-item"> {/*<li className="nav-item dropdown">*/}
                                <NavLink className="nav-link"  to="/contact-us">Contact
                                    {/*<span className="sr-only">(current)</span>*/}
                                </NavLink>
                                {/*<a className="nav-link dropdown-toggle" href="# " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" to="/contact-us">Contact</a>
                                <div className="dropdown-menu">
                                    <NavLink className="dropdown-item" to="/contact-1">Contact One</NavLink>
                                    <NavLink className="dropdown-item" to="/contact-2">Contact Two</NavLink>
                                    <NavLink className="dropdown-item" to="/contact-3">Contact Three</NavLink>
                                    <NavLink className="dropdown-item" to="/contact-us">Contact Four</NavLink>
                                </div>*/}
                            </li>
                            <li className="nav-item"> {/*<li className="nav-item dropdown">*/}
                                <NavLink className="nav-link"  to="/pricing">Pricing
                                    {/*<span className="sr-only">(current)</span>*/}
                                </NavLink>
                            </li>
                            <li className="nav-item"> {/*<li className="nav-item dropdown">*/}
                                <NavLink className="nav-link"  to="/about-us">About
                                    {/*<span className="sr-only">(current)</span>*/}
                                </NavLink>
                            </li>
                            {/*<li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="# " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Blog</a>
                                <div className="dropdown-menu">
                                    <NavLink className="dropdown-item" to="/blog-right">Blog Right Sidebar</NavLink>
                                    <NavLink className="dropdown-item" to="/blog-left">Blog Left Sidebar</NavLink>
                                    <NavLink className="dropdown-item" to="/blog-masonry">Blog Masonry One</NavLink>
                                    <NavLink className="dropdown-item" to="/blog-masonry2">Blog Masonry Two</NavLink>
                                    <NavLink className="dropdown-item" to="/blog-grid">Blog Grid</NavLink>
                                    <NavLink className="dropdown-item" to="/blog-full-width">Blog Full Width</NavLink>
                                    <hr className="dropdown-divider p-bottom-10" />

                                    <NavLink className="dropdown-item" to="/news-details4">Blog Details Standard</NavLink>
                                    <NavLink className="dropdown-item" to="/blog-details-audio4">Blog Details Audio</NavLink>
                                    <NavLink className="dropdown-item" to="/blog-details-gallery4">Blog Details Gallery</NavLink>
                                    <NavLink className="dropdown-item" to="/blog-details-video4">Blog Details Video</NavLink>
                                    <NavLink className="dropdown-item" to="/blog-details-slider4">Blog Details Slider</NavLink>
                                    <NavLink className="dropdown-item" to="/blog-details-link4">Blog Details Link</NavLink>
                                    <NavLink className="dropdown-item" to="/blog-details-full4">Blog Details Full Width</NavLink>

                                </div>
                            </li>*/}
                           {/* <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="# " data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Shop</a>
                                <div className="dropdown-menu">
                                    <NavLink className="dropdown-item" to="/shop-home">Shop Home</NavLink>
                                    <NavLink className="dropdown-item" to="/shop-products">Shop Products</NavLink>
                                    <NavLink className="dropdown-item" to="/single-product1">Product Single</NavLink>
                                    <NavLink className="dropdown-item" to="/shopping-cart">Shopping Cart</NavLink>
                                    <NavLink className="dropdown-item" to="/checkout">Checkout</NavLink>
                                    <NavLink className="dropdown-item" to="/checkout-confirm">Checkout Confirm</NavLink>
                                </div>
                            </li>*/}
                            {/* <!-- end: .navbar-nav --> */}
                        </ul>
                    </div>
                </div>

                {/*<div className="nav_right_content m-left-30 d-flex align-items-center order-2 order-sm-2">
                    <div className="nav_right_module cart_module">
                        <div className="cart__icon">
                            <span className="la la-shopping-cart"></span>
                            <span className="cart_count">{this.state.product.length}</span>
                        </div>
                        <div className="cart__items shadow-lg-2">
                             <Cart_Item /> comment this line!

                            {
                                Object.values(this.state.product).map((value, key) => {
                                    const {imgSrc, title, price, id} = value[0]

                                    return (
                                        <div className="items" key={key+1}>
                                            <div className="item_thumb">
                                                <img src={imgSrc} alt=""/>
                                            </div>
                                            <div className="item_info">
                                                <NavLink to={"/single-product"+id}>{title}</NavLink>
                                                <span className="color-primary">{"$"+price}</span>
                                            </div>
                                            <a href="# " className="item_remove" onClick={remove} data={key} id={id}>
                                                <span className="la la-close"></span>
                                            </a>
                                        </div>
                                    )

                                })
                            }

                            <div className="cart_info text-md-right">
                                <p>Subtotal:
                                    <span className="color-primary">{'$'+total_price}</span>
                                </p>
                                <NavLink className="btn btn-outline-secondary btn-sm" to="/shopping-cart">View Cart</NavLink>
                                <NavLink className="btn btn-primary btn-sm" to="/checkout">Checkout</NavLink>
                            </div>

                        </div>
                    </div>
                     <!-- end .cart_module -->
                    <div className="nav_right_module search_module">
                        <span className="la la-search search_trigger"></span>
                        <div className="search_area">
                            <form action="/">
                                <div className="input-group input-group-light">
                                    <span className="icon-left" id="basic-addon78">
                                        <i className="la la-search"></i>
                                    </span>
                                    <input type="text" className="form-control search_field" placeholder="Type words and hit enter..." />
                                </div>
                            </form>
                        </div>
                    </div>
                     <!-- end ./search_module -->
                </div>*/}

            </Fragment>

        )
    }
}

const mapStateToProps = state => {
    return {
        product : state.product,
        cart : state.cart
    }
}

const mapDispatchToProp = dispatch => {
    return {
        addToCart : (prod) => dispatch(Cart(prod))
    }
}

export default connect(mapStateToProps, mapDispatchToProp)(NavbarItem);

import React, {Fragment} from 'react';
import {NavLink} from 'react-router-dom';

const noAction = (e) => {
    e.preventDefault();
}

const RevolutionSlider2 = () => {
    
    
    return (
    
        <Fragment>
             <div id="rev_slider_36_1_wrapper" className="rev_slider_wrapper fullwidthbanner-container" data-alias="slider9" data-source="gallery" style={{margin:'0px auto',background:'transparent', padding:'0px', marginTop:'0px',marginBottom:'0px',}}>
                    {/* <!-- START REVOLUTION SLIDER 5.4.8.1 fullwidth mode --> */}
                    <div id="rev_slider_36_1" className="rev_slider without_overlay fullwidthabanner" style={{display:'none'}} data-version="5.4.8.1">
                        <ul>
                            {/* <!-- SLIDE  --> */}
                            <li data-index="rs-72" data-transition="fade" data-slotamount="default" data-hideafterloop="0" data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed="300" data-delay="8970" data-rotate="0" data-saveperformance="off" data-title="Slide" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                                {/* <!-- MAIN IMAGE --> */}
                                <img src="./assets/img/transparent.png" data-bgcolor='' alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="off" className="rev-slidebg" data-no-retina />
                                {/* <!-- LAYERS --> */}
                                <div id="rrzm_72" className="rev_row_zone rev_row_zone_middle" style={{zIndex: 5}}>
                                    {/* <!-- LAYER NR. 1 --> */}
                                    <div className="tp-caption  " id="slide-72-layer-1" data-x="" data-y="center" data-voffset="" data-width="['auto']" data-height="['auto']" data-type="row" data-columnbreak="3" data-responsive_offset="on" data-responsive="off" data-frames='[{"delay":10,"speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]"  data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 5, whiteSpace: 'nowrap', fontSize: '17px', lineHeight: '22px', fontWeight: 400, color: '#ffffff', letterSpacing: '0px'}}>
                                        {/* <!-- LAYER NR. 2 --> */}
                                        <div className="tp-caption  " id="slide-72-layer-2" data-x="100" data-y="100" data-width="['auto']" data-height="['auto']" data-type="column" data-responsive_offset="on" data-responsive="off" data-frames='[{"delay":"+0","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-columnwidth="50%" data-verticalalign="top" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]"  data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 6, width: '100%'}}>
                                            {/* <!-- LAYER NR. 3 --> */}
                                            <h1 className="tp-caption   tp-resizeme" id="slide-72-layer-4" data-x="" data-y="" data-width="['auto']" data-height="['auto']" data-type="text" data-responsive_offset="on" data-fontsize="['48', '44', '40', '38']" data-lineheight="['60', '50', '48', '44']" data-frames='[{"delay":"+10","split":"chars","splitdelay":0.05,"speed":1040,"split_direction":"forward","frame":"0","from":"x:[105%];z:0;rX:45deg;rY:0deg;rZ:90deg;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;s:inherit;e:inherit;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]' data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 7, whiteSpace: 'normal', fontSize: '48px', lineHeight: '60px', fontWeight: 600, color: '#fff', letterSpacing: '0px', display: 'block'}}>
                                                Make Business <br />
                                                with Experienced <br />
                                                <span style={{color: '#00c18d'}}>Partners</span></h1>
                                            {/* <!-- LAYER NR. 4 --> */}
                                            <div className="tp-caption   tp-resizeme" id="slide-72-layer-5" data-x="" data-y="" data-width="['auto']" data-height="['auto']" data-type="text" data-responsive_offset="on" data-fontsize="['17', '17', '17', '16']" data-lineheight="['32', '32', '32', '22']" data-frames='[{"delay":"+1550","speed":1420,"sfxcolor":"#ffffff","sfx_effect":"blockfromtop","frame":"0","from":"z:0;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-margintop="[35,35,35,35]" data-marginright="[0,0,0,0]" data-marginbottom="[35,35,35,35]" data-marginleft="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 8, whiteSpace: 'normal', fontSize: '17px', lineHeight: '32px', fontWeight: 400, color: 'rgba(255,255,255,0.7)', letterSpacing: '0px',display: 'block'}}>
                                                Deserunt dolore voluptatem assumenda quae possimus sunt dignissimos tempora officia.
                                                Lorem ipsum dolor sit amet
                                                consectetur adipisicing dolore.
                                            </div>
                                            {/* <!-- LAYER NR. 6 --> */}
                                            <div className="tp-caption" id="slide-72-layer-6" data-x="" data-y="" data-width="['auto']" data-height="['auto']" data-type="text" data-responsive_offset="on" data-frames='[{"delay":"+3520","speed":560,"frame":"0","from":"x:200px;skX:-85px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 9, display: 'inline-block'}}>
                                                <NavLink to='/demo' onClick={noAction} className="btn btn-secondary">Explore More</NavLink>
                                            </div>
                                        </div>
                                        {/* <!-- LAYER NR. 8 --> */}
                                        <div className="tp-caption  " id="slide-72-layer-3" data-x="100" data-y="100" data-width="['auto']" data-height="['auto']" data-visibility="['on', 'off', 'off', 'off']" data-type="column" data-responsive_offset="on" data-responsive="off" data-frames='[{"delay":"+0","speed":300,"frame":"0","from":"opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]' data-columnwidth="50%" data-verticalalign="top" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]"  data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 7, width: '100%'}}></div>
                                    </div>
                                </div>
                                {/* <!-- LAYER NR. 9 --> */}
                                <div className="tp-caption   tp-resizeme rs-parallaxlevel-1" id="slide-72-layer-7" data-x="right" data-hoffset="-180" data-y="center" data-voffset="0" data-width="['none','none','none','none']" data-height="['none','none','none','none']" data-visibility="['on','on','on','off']" data-type="image" data-responsive_offset="on" data-frames='[{"delay":4230,"speed":1500,"frame":"0","from":"x:[175%];y:0px;z:0;rX:0;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:1;","mask":"x:[-100%];y:0;s:inherit;e:inherit;","to":"o:1;","ease":"Power3.easeOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","mask":"x:0;y:0;s:inherit;e:inherit;","ease":"Power3.easeInOut"}]'  data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 1}}><img src="./assets/img/slider_2.png" alt="" data-ww="800px" data-hh="664px" data-no-retina /></div>
                            </li>
                        </ul>
                        <div className="tp-bannertimer tp-bottom" style={{'visibility': 'hidden !important'}}></div>
                    </div>
                </div>{/*<!-- END REVOLUTION SLIDER -->*/}
                
        </Fragment>       
                      
    )   
}


export default RevolutionSlider2;
import React from 'react';
import {NavLink} from 'react-router-dom';
import FooterItem from './footerItem';
const noAction = (e) => {
    e.preventDefault();
}

const Footer5 = () => {
    return (
        <footer className="footer5 footer--bw">
            <div className="footer__big">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="widget text_widget">
                                <img className="footer_logo" src="./assets/img/logo-white.png" alt="logo" />
                                <p>Our primary mission is our customer satisfaction. We always available for assistance.</p>
                                {/*<NavLink to="/" onClick={noAction}>Read More About <span className="la la-chevron-right"></span></NavLink>*/}
                            </div>{/*<!-- ends: .widget -->*/}
                        </div>{/*<!-- ends: .col-lg-3 -->*/}
                        <FooterItem color="black"/>
                    </div>
                </div>
            </div>

            <div className="footer__small text-center">
                <p>©2020 MoonoSys Sdn.Bhd. All rights reserved. Powered by <NavLink onClick={noAction} to="/">Ratri Cloud Team</NavLink></p>
            </div>{/*<!-- ends: .footer__small -->*/}
        </footer>
    )
}
export default Footer5;

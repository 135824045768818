import $ from "jquery";
import React, { Component } from 'react'
import Content from '../../content/team/team-skild';
import OwlCarousel from 'react-owl-carousel2';
import {connect} from 'react-redux';

let rtlCheck = $("body").css("direction");
let falsy = false;
if(rtlCheck === "rtl"){
    falsy = true;
}
const options = {
    items: 4,
    margin: 30,
    rtl: falsy,
    nav: false,
    dots: true,
    dotsClass: "owl-dots dot-circle",
    responsive: {
        0: {
            items: 1
        },
        600:{
            items:2
        },
        992:{
            items:4
        }
    }
};
class Team_wrap extends Component {
    render() {
        const { team } = this.props;

        return (
            <section className={this.props.padding ? this.props.padding : "p-top-100 p-bottom-105"}>
                <div className="m-bottom-50">
                    <div className="divider text-center">
                        <h1>Skilled Team</h1>
                        <p className="mx-auto">Investiga tiones demonstr averunt lectres legere me lius quod qua legunt saepius Clarias estre etiam dynamicus quality legunt.</p>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <OwlCarousel options={options} className="team-carousel-two owl-carousel">
                            {
                                Object.values(team).slice(14, 19).map((value, key) => {
                                    const {imgSrc, id, name, speach, designation} = value;
                                    return (
                                        <Content img={imgSrc}
                                            key={key}
                                            id={id}
                                            name={name}
                                            designation={designation}
                                            speach={speach}
                                        />
                                    )
                                })
                            }
                            </OwlCarousel>{/*<!-- ends: .team-carousel -->*/}
                        </div>{/*<!-- ends: .col-lg-8 -->*/}
                    </div>
                </div>
            </section>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        team : state.team
    }
}
export default connect(mapStateToProps)(Team_wrap);
import React, { Component, Fragment } from 'react'
import { NavLink } from 'react-router-dom';
import { BlockquoteOne } from '../../content/element/blockquote/blockquote';
import { TimelineTwo } from '../../content/element/timeline/timeline';
const noAction = (e) => {
    e.preventDefault();
}
class TeamDetails extends Component {
    render() {
        const {content} = this.props;
        return (
            <Fragment>
               <section className="team-profile p-top-120 p-bottom-80">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 margin-md-60">
                                <img src={content.imgSrc} alt="" className="rounded" />
                            </div>{/*<!-- ends: .col-lg-4 -->*/}
                            <div className="col-lg-8">
                                <div className="team-details m-left-50">
                                    <div className="m-bottom-30">
                                        <h4 className="color-primary">{content.name}</h4>
                                        <span>{content.designation}</span>
                                        <span>Regional Leader Switzerland, Italy, Austria</span>
                                        <span className="m-top-15 d-flex">
                                            <strong className="color-dark">Expertise:</strong>
                                            <span className="color-secondary m-left-10">Digital & Strategy</span>
                                        </span>
                                        <div className="m-top-25">
                                            <div className="social-basic ">
                                                <ul className="d-flex justify-content-start ">
                                                    <li><NavLink to="/" onClick={noAction} className="facebook"><span className="fab fa-facebook-f"></span></NavLink></li>
                                                    <li><NavLink to="/" onClick={noAction} className="twitter"><span className="fab fa-twitter"></span></NavLink></li>
                                                    <li><NavLink to="/" onClick={noAction} className="linkedin"><span className="fab fa-linkedin-in"></span></NavLink></li>
                                                    <li><NavLink to="/" onClick={noAction} className="gplus"><span className="fab fa-google-plus-g"></span></NavLink></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <p>{content.speach}</p>
                                </div>
                            </div>{/*<!-- ends: .col-lg-8 -->*/}
                        </div>
                    </div>
                </section>{/*<!-- ends: section -->*/}
                <BlockquoteOne />
                <section className="team-timeline m-bottom-120">
                    <div className="container">
                        <TimelineTwo />
                    </div>
                </section>
            </Fragment>
        )
    }
}
export default TeamDetails;
import React from 'react';
import {NavLink} from 'react-router-dom';
const noAction = (e) => {
    e.preventDefault();
}
const Team_Wrap = (props) => {
    const {img, name, speach, designation} = props;
    return (
        <div className="carousel-single">
            <div className="team-img">
                <img src={img} alt="" />
            </div>
            <div className="team-desc">
                <h5>{name}</h5>
                <span>{designation}</span>
                <p>{speach}</p>
                <div className="social social--small social--gray ">
                    <ul className="d-flex flex-wrap">
                        <li><NavLink to="/" onClick={noAction} className="facebook"><span className="fab fa-facebook-f"></span></NavLink></li>
                        <li><NavLink to="/" onClick={noAction} className="twitter"><span className="fab fa-twitter"></span></NavLink></li>
                        <li><NavLink to="/" onClick={noAction} className="linkedin"><span className="fab fa-linkedin-in"></span></NavLink></li>
                        <li><NavLink to="/" onClick={noAction} className="gplus"><span className="fab fa-google-plus-g"></span></NavLink></li>
                    </ul>
                </div>{/*<!-- ends: .social -->*/}
            </div>
        </div>
    )
}
export default Team_Wrap; 
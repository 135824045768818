import React from 'react';
import { NavLink } from 'react-router-dom';
const noAction = e => e.preventDefault();
const ContentAbove = ()=> {
    return (
        <section className="bgimage bg-secondary p-top-100 p-bottom-80">
            <div className="bg_image_holder">
                <img src="./assets/img/map2.png" alt="" />
            </div>
            <div className="container content_above">
                <div className="m-bottom-50">
                    <div className="divider text-center">
                        <h1 className="color-light p-bottom-10">3 Resons to Choose Us</h1>
                        <p className="color-light m-auto mb-0">Investiga tiones demonstr averunt lectres legere me lius quod qua legunt saepius estre etiam cessus.</p>
                    </div>
                </div>
                <div className="card-style-twelve">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="card card-twelve">
                                    <div className="card-body">
                                        <span className="step">01</span>
                                        <h6>Business Consulting</h6>
                                        <p>Investig atones demon travg vunt lecto kres legere lrus quod legunt saepius clar itasest demons tavg vuntwn sharing quod training.</p>
                                        <NavLink to="/at_demo" onClick={noAction} className="btn btn-outline-secondary">Read More</NavLink>
                                    </div>
                                </div>{/*<!-- End: .card -->*/}
                            </div>{/*<!-- ends: .col-lg-4 -->*/}
                            <div className="col-lg-4 col-md-6">
                                <div className="card card-twelve">
                                    <div className="card-body">
                                        <span className="step">02</span>
                                        <h6>Market Analysis</h6>
                                        <p>Investig atones demon travg vunt lecto kres legere lrus quod legunt saepius clar itasest demons tavg vuntwn sharing quod training.</p>
                                        <NavLink to="/at_demo" onClick={noAction} className="btn btn-outline-secondary">Read More</NavLink>
                                    </div>
                                </div>{/*<!-- End: .card -->*/}
                            </div>{/*<!-- ends: .col-lg-4 -->*/}
                            <div className="col-lg-4 col-md-6">
                                <div className="card card-twelve">
                                    <div className="card-body">
                                        <span className="step">03</span>
                                        <h6>Online Consutling</h6>
                                        <p>Investig atones demon travg vunt lecto kres legere lrus quod legunt saepius clar itasest demons tavg vuntwn sharing quod training.</p>
                                        <NavLink to="/at_demo" onClick={noAction} className="btn btn-outline-secondary">Read More</NavLink>
                                    </div>
                                </div>{/*<!-- End: .card -->*/}
                            </div>{/*<!-- ends: .col-lg-4 -->*/}
                        </div>{/*<!-- ends: .row -->*/}
                    </div>
                </div>{/*<!-- ends: .card-style-twelve -->*/}
            </div>
        </section>
    )
}
export default ContentAbove;
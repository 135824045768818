import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import LogoCarousel from '../../container/element/carousel/logo-carousel';
import LogoCarouselThree from '../../container/element/carousel/logo-carousel-three';
import ClientHelf from '../../container/clients/bg-half-secondary';
import Clients1 from '../../container/clients/clients1';
import { CountUp2 as Counter } from '../../content/element/counter/count';
import { LogoBox, GridIcon } from '../../content/element/iconBox/iconBox';


const Clients = () => {
    return (
        <Fragment>

            <Header pageTitle="Clients" />
            {/* Clients- 1 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Clients- 1</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="carousel-wrapper pb-5">
                <div className="container">
                    <LogoCarousel />
                </div>
            </section>
            {/* Clients- 1 */}

            {/* Clients- 2 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Clients- 2</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="carousel-wrapper">
                <div className="container">
                    <LogoCarouselThree padding="p-top-30 p-bottom-0" />
                </div>
            </section>
            {/* Clients- 2 */}

            {/* Clients- 3 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Clients- 3</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="carousel-wrapper pb-5">
                <div className="container">
                    <div className="row">
                        <ClientHelf bg="bg" />
                    </div>
                </div>
            </section>
            {/* Clients- 3 */}

            {/* Clients- 4 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Clients- 4</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="carousel-wrapper pb-5">
                <div className="container">
                    <Clients1 />
                </div>
            </section>
            {/* Clients- 4 */}

            {/* Clients- 5 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Clients- 5</h3>
                        </div>
                    </div>
                </div>
            </div>
            <section className="carousel-wrapper pb-5">
                <Counter />
            </section>
            {/* Clients- 5 */}

            {/* Clients- 6 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Clients- 6</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-top-100 p-bottom-10 section-bg">
                <LogoBox />
            </div>
            {/* Clients- 6 */}

            {/* Clients- 7 */}
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Clients- 7</h3>
                        </div>
                    </div>
                </div>
            </div>
            <GridIcon />
            {/* Clients- 7 */}

            <Footer />
        </Fragment>
    )
}

export default Clients;
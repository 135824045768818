import $ from "jquery";
import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel2';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
const noAction = e => e.preventDefault();

let rtlCheck = $("body").css("direction");
let falsy = false;
if(rtlCheck === "rtl"){
    falsy = true;
}
const options = {
    items: 1,
    rtl: falsy,
    nav: true,
    navText: ['<i class="la la-angle-left"></i>', '<i class="la la-angle-right"></i>'],
    navContainerClass: "nav-circle",
    dots: false,
    navElement: 'button',
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 1
        },
        1000: {
            items: 1
        }
    }
};
class Slider extends Component {
    render() {
        const {project} = this.props;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <OwlCarousel options={options} className="carousel-three owl-carousel">
                            {
                                Object.values(project).slice(8, 10).map((value, key) => {
                                    return (

                                        <div className="carousel-single" key={key}>
                                            <div className="card card-shadow card-six">
                                                <figure>
                                                    <img src={value.imgSrc} alt="" />
                                                </figure>
                                                <div className="card-body">
                                                    <h5><NavLink to="/" onClick={noAction} >Custom Investing Strategies</NavLink></h5>
                                                    <p className="card-text">Investig ationes demons trave runt lectores legere liushgfy quod legunt saeph claritas nvestig ationes demons trave rungt. Investiga tiones demons averu.</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })

                            }
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        project : state.project
    }
}
export default connect(mapStateToProps)(Slider);
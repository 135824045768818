import React, {Component} from 'react';
import SimpleReactValidator from 'simple-react-validator';
import {toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';
import {NavLink} from "react-router-dom";
export   class ContactForm5 extends Component {
    constructor (props) {
        super (props)
        this.state = {
            email:'',
            name: '',
            phone: '',
            message: ''
        }
        this.validator = new SimpleReactValidator();
        this.setStateFromInput = this.setStateFromInput.bind(this);
    }

    setStateFromInput = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    }

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render () {
        const Shipping_Address = (e) => {
            e.preventDefault()
            if (this.validator.allValid()) {
                console.log(this.state)
                var success = true;
                return success;

            } else {
                this.validator.showMessages();
                this.forceUpdate();
                var success2 = false;
                return success2;
            }
        }
        return (
            <section className={this.props.padding ? this.props.padding : "p-top-100 p-bottom-110"}>
                <section className="form-wrapper contact--from5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="divider text-center m-bottom-50">
                                    <h1 className="color-dark m-0">Request A Call Back</h1>
                                </div>
                                <div className="form-wrapper">
                                    <form action="#">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 m-bottom-30">
                                                <input type="text" value={this.state.name} onChange={this.setStateFromInput} placeholder="Your Name" name="name" className="form-control" />
                                                <div className="text-danger">{this.validator.message('Name', this.state.name, 'required|string')}</div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 m-bottom-30">
                                                <input type="text" placeholder="Phone Number" value={this.state.phone} onChange={this.setStateFromInput} name="phone" className="form-control" />
                                                <div className="text-danger">{this.validator.message('Phone', this.state.phone, 'required|phone')}</div>
                                            </div>
                                            <div className="col-lg-4 m-bottom-30">
                                                <input type="email" placeholder="Email" value={this.state.email} onChange={this.setStateFromInput} name="email" className="form-control" />
                                                <div className="text-danger">{this.validator.message('Email', this.state.email, 'required|email')}</div>
                                            </div>
                                            <div className="col-lg-12 m-bottom-20">
                                                <textarea className="form-control" value={this.state.message} onChange={this.setStateFromInput} name="message" rows="7" placeholder="Message" required></textarea>
                                            <div className="text-danger">{this.validator.message('Message', this.state.message, 'required|string')}</div>
                                            </div>
                                            <div className="col-lg-12 text-center m-top-30">
                                                <button onClick={Shipping_Address} className="btn btn-primary">Request Now</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>{/*<!-- end: .form-wrapper -->*/}
                            </div>
                        </div>
                    </div>
                </section>{/*<!-- ends: .form-wrapper -->*/}
            </section>
        )
    }
}


export class ContactForm extends Component {
    constructor (props) {
        super (props)
        this.state = {
            email:'',
            name: '',
            phone: '',
            message: ''
        }
        this.validator = new SimpleReactValidator();
        this.setStateFromInput = this.setStateFromInput.bind(this);
    }

    setStateFromInput = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    }

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render () {
        const Shipping_Address = (e) => {
            e.preventDefault()
            if (this.validator.allValid()) {
                console.log(this.state)
                var success = true;
                return success;

            } else {
                this.validator.showMessages();
                this.forceUpdate();
                var success2 = false;
                return success2;
            }
        }
    return (
        <div className="form-wrapper">
            <div className="m-bottom-10">
                <div className="divider divider-simple text-left">
                    <h3>Let&#39;s Get In Touch</h3>
                </div>
            </div>
            <p className="m-bottom-30">Investiga tiones demonstr averunt lectres legere me lius quod ii qua legunt saepius larias est etiam pro cessus.</p>
            <form action="#">
                <input type="text" name="name" value={this.state.name} onChange={this.setStateFromInput} className="form-control form-outline mb-4" placeholder="Name" />
                <div className="text-danger">{this.validator.message('Name', this.state.name, 'required|string')}</div>
                <input type="email" name="email" value={this.state.email} onChange={this.setStateFromInput} className="form-control form-outline mb-4" placeholder="Email" />
                <div className="text-danger">{this.validator.message('Email', this.state.name, 'required|email')}</div>
                <textarea name="message" value={this.state.message} onChange={this.setStateFromInput} className="form-control form-outline mb-4" placeholder="Messages" required></textarea>
                <div className="text-danger">{this.validator.message('Message', this.state.message, 'required|string')}</div>
                <button onClick={Shipping_Address} className="btn btn-primary">Submit Now</button>
            </form>
        </div>
    )
}
}

export class RequestCall extends Component {
    constructor (props) {
        super (props)
        this.state = {
            name: '',
            company: '',
            phone: '',
            email: '',
            request: '',
            isVerified: false
        }
        this.validator = new SimpleReactValidator();
        this.setStateFromInput = this.setStateFromInput.bind(this);
    }

    handleVerify = (response) => {
        if (response) {
            this.setState({ isVerified: true });
        }
    }

    setStateFromInput = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    }

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    cancelCourse (){
        document.getElementById("name").reset();
       /* document.getElementsByName("company").reset();
        document.getElementsByName("phone").reset();
        document.getElementsByName("request").reset();*/
    }

    render () {

        const Shipping_AddressX = (e) => {
            e.preventDefault()
            if (this.validator.allValid()) {
                console.log(this.state);

                const {name, company, phone, email, request} = this.state;

                const bd = "<p>A new Call Request from:</p>\n" +
                    "<p>Name: <b>" + name + "</b></p>\n" +
                    "<p>Company: <b>" + company + "</b></p>\n" +
                    "<p>Phone: <b>" + phone + "</b></p>\n" +
                    "<p>Email: <b>" + email + "</b></p>\n" +
                    "<p>Request: <b>" + request + "</b></p>\n" +
                    "<p>Please contact this potential customer as soon as possible.</p>\n" +
                    "<p>Thanks.</p>\n" +
                    "<p>&nbsp;</p>\n" +
                    "<p>BR,</p>\n" +
                    "<p>Ratri Cloud Team</p>";
                let sendDetails ={
                    body:bd,
                    subject: "Call Request"
                }

                console.log('jsonBuild', JSON.stringify(sendDetails))

                fetch('/api/enquiry',{
                    /*fetch(' http://ratricloud.com/api/enquiry',{*/
                    headers: {
                        "Content-Type":"application/json"
                    },
                    method:'POST',
                    body: JSON.stringify(sendDetails)

                }).then(response => {
                    if (response.ok) {
                        //done
                        toast.success('Thank you for contacting us! We will get back to you soon', {position:toast.POSITION.TOP_CENTER});

                        this.setState({
                            name: '',
                            company: '',
                            phone: '',
                            email: '',
                            request: ''
                        });

                        let success = true;
                        return success;
                    }
                    else
                    {
                        let success2 = false;
                        return success2;
                    }

                })// JavaScript Document



                //let success = true;
                //return success;

            } else {
                this.validator.showMessages();
                this.forceUpdate();
                let success2 = false;
                return success2;
            }
        }

        return (
            <div className="tp-caption   tp-static-layer" id="slider-17-layer-1" data-x="right" data-hoffset="" data-y="center" data-voffset="" data-width="['auto']" data-height="['auto']" data-type="text" data-responsive_offset="on" data-responsive="on" data-startslide={0} data-endslide={1} data-visibility="['on', 'on', 'off', 'off']" data-frames="[{&quot;delay&quot;:0,&quot;speed&quot;:300,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 5, whiteSpace: 'nowrap', fontSize: '20px', lineHeight: '22px', fontWeight: 400, color: '#ffffff', letterSpacing: '0px'}}>
                <div className="form-wrapper cardify card--rounded">
                    <h4 className="color-dark text-center">Request A Call Back</h4>
                    <form action="#">
                        {/*<input type="text" value={this.state.name} onChange={this.setStateFromInput} name="name" id="name" placeholder="Your Name" className="form-control fc--rounded mb-4" />
                        <div className="text-danger">{this.validator.message('name', this.state.name, 'required|string')}</div>

                        <input type="text" value={this.state.company} onChange={this.setStateFromInput} name="company" placeholder="Company Name" className="form-control fc--rounded mb-4" />
                        <div className="text-danger">{this.validator.message('company', this.state.company, 'required|string')}</div>

                        <input type="text" value={this.state.phone} onChange={this.setStateFromInput} name="phone" placeholder="Phone Number" className="form-control fc--rounded mb-4" />
                        <div className="text-danger">{this.validator.message('phone', this.state.phone, 'required|string')}</div>

                        <input type="email" value={this.state.email} onChange={this.setStateFromInput} name="email" className="form-control fc--rounded mb-4" placeholder="Email" />
                        <div className="text-danger">{this.validator.message('email', this.state.email, 'required|string')}</div>

                        <div className="form-group mb-4">
                            <div className="select-basic">
                                <select className="form-control fc--rounded" value={this.state.request} onChange={this.setStateFromInput} name="request">
                                    <option>Request</option>
                                    <option>Software: Property Management System</option>
                                    <option>Software: Employee & Time Management System</option>
                                    <option>Software: Customized Software Development</option>
                                    <option>Software: Mobile App Development</option>
                                    <option>Software: IT Consultations</option>
                                    <option>Hardware: Door Lock</option>
                                    <option>Hardware: Wifi & CCTV</option>
                                    <option>Hardware: Energy Saving Switch</option>
                                    <option>Hardware: MyKad Reader</option>
                                    <option>Hardware: Door Access</option>
                                    <option>Hardware: Computers & Printers</option>
                                    <option>Hardware: Fingerprint/Digital Punch Card</option>

                                </select>
                            </div>
                            <div className="text-danger">{this.validator.message('request', this.state.request, 'required|string')}</div>
                        </div>*/}

                        {/*<ReCAPTCHA
                            sitekey="6LeJN1QoAAAAAEq6JtAVFDs40bADUxYJNLPxq5Mu"
                            onChange={this.handleVerify}
                        />*/}

                        <NavLink className="nav-link"  to="/contact-us">
                            <button className="btn btn-primary btn--rounded btn-block">Contact Us</button>
                        </NavLink>

                        {/*<button onClick={Shipping_AddressX} className="btn btn-primary btn--rounded btn-block">Request Now</button>*/}


                    </form>

                </div>
            </div>
        )
    }
}

export class AskQuestion extends Component {
    constructor (props) {
        super (props)
        this.state = {
            name: '',
            company: '',
            email:'',
            question: '',
            isVerified: false
        }
        this.validator = new SimpleReactValidator();
        this.setStateFromInput = this.setStateFromInput.bind(this);
    }

    handleVerify = (response) => {
        if (response) {
            this.setState({ isVerified: true });
        }
    }

    setStateFromInput = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    }

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render () {
        const Shipping_AddressY = (e) => {
            e.preventDefault()
            if (this.validator.allValid()) {
                console.log(this.state);

                const {name, company, email, question} = this.state;

                const bd = "<p>A new Contact Message from:</p>\n" +
                    "<p>Name: <b>" + name + "</b></p>\n" +
                    "<p>Company: <b>" + company + "</b></p>\n" +
                    "<p>Email: <b>" + email + "</b></p>\n" +
                    "<p>Question: <b>" + question + "</b></p>\n" +
                    "<p>Please reply this potential customer as soon as possible.</p>\n" +
                    "<p>Thanks.</p>\n" +
                    "<p>&nbsp;</p>\n" +
                    "<p>BR,</p>\n" +
                    "<p>Ratri Cloud Team</p>";
                let sendDetails ={
                    body:bd,
                    subject: "Customer Question"
                }

                console.log('jsonBuild', JSON.stringify(sendDetails))

                fetch('/api/enquiry',{
                    /*fetch(' http://ratricloud.com/api/enquiry',{*/
                    headers: {
                        "Content-Type":"application/json"
                    },
                    method:'POST',
                    body: JSON.stringify(sendDetails)

                }).then(response => {
                    if (response.ok) {
                        //done
                        toast.success('Thank you for contacting us! We will get back to you soon', {position:toast.POSITION.TOP_CENTER});

                        this.setState({
                            email:'',
                            name: '',
                            company: '',
                            question: ''
                        });


                        let success = true;
                        return success;
                    }
                    else
                    {
                        let success2 = false;
                        return success2;
                    }

                })// JavaScript Document

                //var success = true;
                //return success;

            } else {
                this.validator.showMessages();
                this.forceUpdate();
                let success2 = false;
                return success2;
            }
        }

        return (
            <div className={this.props.padding ? this.props.padding : "col-lg-4 offset-lg-1"}>
                <div className="form-box">
                    <h4 className="color-dark">Have Any Question?</h4>
                    <p className="m-0">Do not hesitate to ask us, we are ready keep you updated.</p>
                    <form action="#" className="m-top-30">
                        <input type="text" value={this.state.name} onChange={this.setStateFromInput} name="name" className="form-control mb-4" placeholder="Your Name" />
                        <div className="text-danger">{this.validator.message('name', this.state.name, 'required|string')}</div>

                        <input type="text" value={this.state.company} onChange={this.setStateFromInput} name="company" className="form-control mb-4" placeholder="Company Name" />
                        <div className="text-danger">{this.validator.message('company', this.state.company, 'required|string')}</div>

                        <input type="email" value={this.state.email} onChange={this.setStateFromInput} name="email" className="form-control mb-4" placeholder="Your Email" />
                        <div className="text-danger">{this.validator.message('email', this.state.email, 'required|string')}</div>

                        <input type="text" value={this.state.question} onChange={this.setStateFromInput} name="question" className="form-control mb-4" placeholder="Your Question" />
                        <div className="text-danger">{this.validator.message('question', this.state.question, 'required|string')}</div>

                        <ReCAPTCHA
                            sitekey="6LeJN1QoAAAAAEq6JtAVFDs40bADUxYJNLPxq5Mu"
                            onChange={this.handleVerify}
                        />
                        {this.state.isVerified &&
                        <>
                            <p>Verification successful!</p>
                            <button onClick={Shipping_AddressY} className="btn btn-primary mt-3">Ask Now</button>
                        </>
                        }

                    </form>
                </div>
            </div>
        )
    }
}

export class GetInTouch extends Component {
        constructor (props) {
            super (props)
            this.state = {
                email:'',
                name: '',
                company: '',
                phone: '',
                message: '',
                isVerified: false
            }
            this.validator = new SimpleReactValidator();
            this.setStateFromInput = this.setStateFromInput.bind(this);
        }

    handleVerify = (response) => {
        if (response) {
            this.setState({ isVerified: true });
        }
    }

        setStateFromInput = (event) => {
            var obj = {};
            obj[event.target.name] = event.target.value;
            this.setState(obj);
        }

        _isMounted = false;

        componentDidMount() {
            this._isMounted = true;
        }

        componentWillUnmount() {
            this._isMounted = false;
        }

        render () {
            const Shipping_Address = (e) => {
                e.preventDefault()
                if (this.validator.allValid()) {
                    console.log(this.state);

                    const {name, company, phone, email, message} = this.state;

                    const bd = "<p>A new Contact Message from:</p>\n" +
                        "<p>Name: <b>" + name + "</b></p>\n" +
                        "<p>Company <b>:" + company + "</b></p>\n" +
                        "<p>Phone <b>:" + phone + "</b></p>\n" +
                        "<p>Email <b>:" + email + "</b></p>\n" +
                        "<p>Message <b>:" + message + "</b></p>\n" +
                        "<p>Please reply this potential customer or take necessary action as soon as possible.</p>\n" +
                        "<p>Thanks.</p>\n" +
                        "<p>&nbsp;</p>\n" +
                        "<p>BR,</p>\n" +
                        "<p>Ratri Cloud Team</p>";
                    let sendDetails ={
                        body:bd,
                        subject: "Customer Contact Message"
                    }

                    console.log('jsonBuild', JSON.stringify(sendDetails))

                    fetch('/api/enquiry',{
                    /*fetch(' http://ratricloud.com/api/enquiry',{*/
                        headers: {
                            "Content-Type":"application/json"
                        },
                        method:'POST',
                        body: JSON.stringify(sendDetails)

                    }).then(response => {
                        if (response.ok) {
                            //done
                            toast.success('Thank you for contacting us! We will get back to you soon', {position:toast.POSITION.TOP_CENTER});

                            this.setState({
                                email:'',
                                name: '',
                                company: '',
                                phone: '',
                                message: ''
                            });

                            let success = true;
                            return success;
                        }
                        else
                        {
                            let success2 = false;
                            return success2;
                        }

                    })// JavaScript Document

                    //var success = true;
                    //return success;

                } else {
                    this.validator.showMessages();
                    this.forceUpdate();
                    var success2 = false;
                    return success2;
                }
            }

    return (
        <div className="container">
            <div className="row">
                <div className={this.props.classes ? this.props.classes : "col-lg-5 offset-lg-7"}>
                    <div className="form-wrapper">
                        <div className="m-bottom-10">
                            <div className="divider divider-simple text-left">
                                <h3>Let&#39;s Get In Touch</h3>
                            </div>
                        </div>
                        <p className="m-bottom-30">Thank you for your interest in MoonoSys. We’d love to hear from you!</p>
                        <form action="#">
                            <input type="text" value={this.state.name} onChange={this.setStateFromInput} name="name" className="form-control form-outline mb-4" placeholder="Name" />
                            <div className="text-danger">{this.validator.message('Name', this.state.name, 'required|string')}</div>

                            <input type="text" value={this.state.company} onChange={this.setStateFromInput} name="company" className="form-control form-outline mb-4" placeholder="Company Name" />
                            <div className="text-danger">{this.validator.message('Company', this.state.company, 'required|string')}</div>


                            <input type="email" value={this.state.email} onChange={this.setStateFromInput} name="email" className="form-control form-outline mb-4" placeholder="Email" />
                            <div className="text-danger">{this.validator.message('Email', this.state.email, 'required|email')}</div>

                            <input type="text" value={this.state.phone} onChange={this.setStateFromInput} name="phone" className="form-control form-outline mb-4" placeholder="Phone Number" />
                            <div className="text-danger">{this.validator.message('Phone', this.state.phone, 'required|phone')}</div>

                            <textarea value={this.state.message} onChange={this.setStateFromInput} className="form-control form-outline mb-4" name="message" placeholder="Messages"></textarea>
                            <div className="text-danger">{this.validator.message('Message', this.state.message, 'required|string')}</div>

                            <ReCAPTCHA
                                sitekey="6LeJN1QoAAAAAEq6JtAVFDs40bADUxYJNLPxq5Mu"
                                onChange={this.handleVerify}
                            />
                            {this.state.isVerified &&
                            <>
                                <p>Verification successful!</p>
                                <button onClick={Shipping_Address} className="btn btn-primary">Submit Now</button>
                            </>
                            }


                        </form>
                    </div>{/*<!-- end: .form-wrapper -->*/}
                </div>
            </div>
        </div>
    )
    }
}

export class ContactForm6 extends Component {
    constructor (props) {
        super (props)
        this.state = {
            plan:'',
            name: '',
            message: ''
        }
        this.validator = new SimpleReactValidator();
        this.setStateFromInput = this.setStateFromInput.bind(this);
    }

    setStateFromInput = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    }

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render () {
        const Shipping_Address = (e) => {
            e.preventDefault()
            if (this.validator.allValid()) {
                console.log(this.state);
                var success = true;
                return success;

            } else {
                this.validator.showMessages();
                this.forceUpdate();
                var success2 = false;
                return success2;
            }
        }
    return (
        <section className="form-wrapper section-bg py-5 bgimage bg-secondary contact--from6">
        <div className="bg_image_holder">
            <img src="./assets/img/contact-bg.png" alt="" />
        </div>
        <div className="container">
            <div className="row">
                <div className="col-lg-12 py-5">
                    <div className="mb-5 color-light">
                        <div className="divider text-center">
                            <h1 className="color-light">Request A Call Back</h1>
                        </div>
                    </div>
                    <div className="form-wrapper">
                        <form action="#">
                            <div className="row">
                                <div className="col-md-4 m-bottom-20">
                                    <input type="text" value={this.state.name} onChange={this.setStateFromInput} name="name" placeholder="Your Name" className="form-control border-0" required />
                                    <div className="text-danger">{this.validator.message('Name', this.state.name, 'required|string')}</div>
                                </div>
                                <div className="col-md-4 m-bottom-20">
                                    <input type="text" value={this.state.phone} onChange={this.setStateFromInput} name="phone" placeholder="Phone Number" className="form-control border-0" required />
                                    <div className="text-danger">{this.validator.message('Phone', this.state.phone, 'required|phone')}</div>
                                </div>
                                <div className="col-md-4 m-bottom-20">
                                    <div className="form-group">
                                        <div className="select-basic">
                                            <select className="form-control border-0" value={this.state.plan} onChange={this.setStateFromInput} name="plan">
                                                <option>Business Planning</option>
                                                <option>Option 1</option>
                                                <option>Option 2</option>
                                                <option>Option 3</option>
                                                <option>Option 4</option>
                                            </select>
                                        </div>
                                    </div>
                                <div className="text-danger">{this.validator.message('Business Plan', this.state.plan, 'required|string')}</div>
                                </div>
                                <div className="col-lg-12 text-center m-top-0">
                                    <button onClick={Shipping_Address} className="btn btn-primary">Request Now</button>
                                </div>

                            </div>
                        </form>
                    </div>{/*<!-- end: .form-wrapper -->*/}
                </div>
            </div>
        </div>
    </section>
    )
    }
}

export const ContactForm3 = () => {
    return (
        <section className="form-wrapper section-bg contact--from3 p-top-100 p-bottom-110">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="m-bottom-65">
                        <div className="divider text-center">
                            <h1 className="color-dark">Request A Call Back</h1>
                            <p className="mx-auto d-none"></p>
                        </div>
                    </div>
                    <div className="form-wrapper">
                        <form action="#">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 m-bottom-20">
                                    <input type="text" placeholder="Your Name" className="form-control fc--rounded border-0" required />
                                </div>
                                <div className="col-lg-4 col-md-6 m-bottom-20">
                                    <input type="text" placeholder="Phone Number" className="form-control fc--rounded border-0" required />
                                </div>
                                <div className="col-lg-4 m-bottom-20">
                                    <div className="form-group">
                                        <div className="select-basic">
                                            <select className="form-control fc--rounded border-0">
                                                <option>Business Planning</option>
                                                <option>Option 1</option>
                                                <option>Option 2</option>
                                                <option>Option 3</option>
                                                <option>Option 4</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 text-center m-top-30">
                                    <button className="btn btn-primary btn--rounded">Request Now</button>
                                </div>
                            </div>
                        </form>
                    </div>{/*<!-- end: .form-wrapper -->*/}
                </div>
            </div>
        </div>
    </section>
    )
}

export const FormBox = (props) => {
    return (
        <div className={props.padding ? props.padding : "col-lg-4 offset-lg-1"}>
            <div className="form-box">
                <h4 className="color-dark">Have Any Question?</h4>
                <p className="m-0">Do not hesitate to ask us, we are ready keep you updated.</p>
                <form action="#" className="m-top-30">
                    <input type="text" className="form-control mb-4" placeholder="Your Name" />
                    <input type="text" className="form-control mb-4" placeholder="Company Name" />
                    <input type="email" className="form-control mb-4" placeholder="Your Email" />
                    <input type="text" className="form-control mb-4" placeholder="Your Question" />
                    <button className="btn btn-primary mt-3">Ask Now</button>
                </form>
            </div>
        </div>
    )
}

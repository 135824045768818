import React from 'react';
import {NavLink} from 'react-router-dom';
const noAction = e => e.preventDefault();
const Cta8 = () => {
    return (
        <section className="cta-wrapper cta--eight gradient-overlay">
            <div className="container content_above">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 text-center">
                        <h1 className="color-light m-bottom-50">Looking for a first-class business service and solution?</h1>
                        <NavLink to="/contact-us" className="btn btn-primary">Contact Us!</NavLink>
                    </div>
                </div>{/*<!-- ends: .cta-wrapper -->*/}
            </div>
        </section>
    )
}
export default Cta8;

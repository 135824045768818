import React, { Fragment } from 'react';
import { Map, GoogleApiWrapper, Marker  } from 'google-maps-react';

var map2Style = [
    {
        "featureType": "all",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "saturation": 36
            },
            {
                "color": "#333333"
            },
            {
                "lightness": 40
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ffffff"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#fefefe"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#fefefe"
            },
            {
                "lightness": 17
            },
            {
                "weight": 1.2
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#dce1dc"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f5f5f5"
            },
            {
                "lightness": 21
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#dedede"
            },
            {
                "lightness": 21
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 29
            },
            {
                "weight": 0.2
            }
        ]
    },
    {
        "featureType": "road.highway.controlled_access",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#cdcdcd"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 18
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#dadada"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f2f2f2"
            },
            {
                "lightness": 19
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#d1d1d1"
            },
            {
                "lightness": 17
            }
        ]
    }
];
const mapStyles = {
    width: '100%',
    height: '100%',
  };


const Map2 = (props) => {
    return (
        <Fragment>
                <div className="g-maps" style={props.styles}>
                    <div className="map" id="map2">
                        {/*<Map
                            google={props.google}
                            zoom={13}
                            styles={map2Style}
                            style={mapStyles}
                            initialCenter={{ lat: 3.156522, lng: 101.705380 }}
                        >
                            <Marker position={{ lat: 3.156522, lng: 101.705380}} icon= './assets/img/mpc.png' />
                        </Map>*/}
                        <img src="/assets/img/rsz_tower.png" alt=""  style={{height:'100%', width:'100%'}} />
                    </div>

                    <div className="map-top">
                        <ul className="icon-list--three">
                            <li className="icon-list2 d-flex">
                                <div className="icon"><i className="la la-headphones"></i></div>
                                <div className="contents">
                                    <h6>+6 03-9212 2805</h6>
                                    <span className="sub-text">Mon-Sat 8am - 6pm</span>
                                </div>
                            </li>
                            <li className="icon-list2 d-flex">
                                <div className="icon"><i className="la la-phone"></i></div>
                                <div className="contents">
                                    <h6>+6 016-9370093</h6>
                                    <span className="sub-text">Mon-Sat 8am - 6pm</span>
                                </div>
                            </li>
                            <li className="icon-list2 d-flex">
                                <div className="icon"><i className="la la-envelope"></i></div>
                                <div className="contents">
                                    <h6>enquiry@ratricloud.com</h6>
                                    <span className="sub-text">Free enquiry</span>
                                </div>
                            </li>
                            <li className="icon-list2 d-flex">
                                <div className="icon"><i className="la la-map-marker"></i></div>
                                <div className="contents">
                                    <h6>Kuala Lumpur, Malaysia</h6>
                                    <span className="sub-text">1-23-5, Menara Bangkok Bank Berjaya Central Park, Jalan Ampang, 50450 Kuala Lumpur</span>
                                </div>
                            </li>
                        </ul>
                    </div>

                </div>

        </Fragment>
    )
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyCbPACjpZWZhjbNI93DCS9kiF_ifYK0DaE'
  })(Map2);

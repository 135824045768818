import React from 'react';
const ContentBlockService = ()=> {
    return (
        <section className="p-top-50 p-bottom-110">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 margin-md-60">
                        <div className="m-bottom-30">
                            <div className="divider text-left">
                            <h2>Ratri Property Management System</h2>
                            <p className="mx-auto" />
                            </div>
                        </div>
                        <p>Ratri Property Management System (PMS) consist of features that gives best value for budget hotels, boutique hotels, resorts, hostels, lodges and home stay. Our friendly PMS is easy to use, with minimal user training and experience.</p>
                        <p>Features that makes our PMS extraordinary:</p>
                        <ul className="bullet--list1 m-top-25">
                            <li className="bullet_list"><strong className="color-dark">Cloud Based system -</strong> Access your property information and data from anywhere or anytime.</li>
                            <li className="bullet_list"><strong className="color-dark">Channel Manager integration -</strong> Receive bookings and Control your availability and rates from PMS only.</li>
                            <li className="bullet_list"><strong className="color-dark">Key Card integration -</strong> Issue your key card straight from the PMS during check-in.</li>
                            <li className="bullet_list"><strong className="color-dark">Wifi integration -</strong> Provide WIFI access information to customers from PMS</li>
                        </ul>
                    </div>{/* ends: .col-lg-6 */}
                    <div className="col-lg-6">
                        <div className="cardify text-box bg-gradient m-top-15">
                            <p>Cloud based system specifically designed for  budget hotels, boutique hotels, resorts, hostels, lodge and home stay!</p>
                            <p>Not only saves you time but helps you complete the operations efficiently and increase productivity with our PMS + Channel Manager + hardware.</p>
                            <figure>
                                <img src="./assets/img/c12-graphic.png" alt="" />
                            </figure>
                        </div>
                    </div>{/* ends: .col-lg-6 */}
                </div>
            </div>
        </section>
    )
}
export default ContentBlockService;

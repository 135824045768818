import React from 'react';

const TopHeader2 = () => {
    return (
        <div className="top_bar top--bar4 d-flex align-items-center">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="d-flex justify-content-sm-center justify-content-lg-between">
                            <div className="brand_logo">
                                <img src="./assets/img/logo.png" alt="logo" />
                            </div>
                            <div className="infos d-flex">
                                <div className="infos--single d-flex">
                                    <span className="la la-headphones"></span>
                                    <div className="info">
                                        <p>800 567.890.576</p>
                                        <small>Mon-Sat 8am - 18pm</small>
                                    </div>
                                </div>
                                <div className="infos--single d-flex">
                                    <span className="la la-envelope-o"></span>
                                    <div className="info">
                                        <p>support@email.com</p>
                                        <small>Free enquiry</small>
                                    </div>
                                </div>
                                <div className="infos--single d-flex">
                                    <span className="la la-map-marker"></span>
                                    <div className="info">
                                        <p>Melbourne, Australia</p>
                                        <small>95 South Park Avenue</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>   
    )
}

export default TopHeader2;
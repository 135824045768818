import React, {Fragment} from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
const noAction = (e) => {
    e.preventDefault();
}

const Revolution_hero = (props) => {
    const slider = props.slider;
    
    return (

        <Fragment>
          <div id="rev_slider_31_1_wrapper" className="rev_slider_wrapper fullwidthbanner-container" data-alias="slider8" data-source="gallery" style={{margin: '0px auto', background: 'transparent', padding: '0px', marginTop: '0px', marginBottom: '0px'}}>
            {/* START REVOLUTION SLIDER 5.4.8.1 fullwidth mode */}
            <div id="rev_slider_31_1" className="rev_slider fullwidthabanner" style={{display: 'none'}} data-version="5.4.8.1">
              <ul>
                
                {
                    
                    Object.values(slider).map((value, key) => {
                        const {imgSrc, id, title, tagline} = value;
                        return (

                        <li data-index={"rs-"+70+id} key={id} data-transition="fade" data-slotamount="default" data-hideafterloop={0} data-hideslideonmobile="off" data-easein="default" data-easeout="default" data-masterspeed={300} data-thumb={imgSrc} data-rotate={0} data-saveperformance="off" data-title="Business Policy" data-param1 data-param2 data-param3 data-param4 data-param5 data-param6 data-param7 data-param8 data-param9 data-param10 data-description>
                        {/* MAIN IMAGE */}
                        <img src={imgSrc} alt="" data-bgposition="center center" data-kenburns="on" data-duration={3000} data-ease="Linear.easeNone" data-scalestart={130} data-scaleend={100} data-rotatestart={0} data-rotateend={0} data-blurstart={0} data-blurend={0} data-offsetstart="0 0" data-offsetend="0 0" className="rev-slidebg" data-no-retina />
                        {/* LAYERS */}
                        <div id="rrzm_70" className="rev_row_zone rev_row_zone_middle" style={{zIndex: 5}}>
                            {/* LAYER NR. 1 */}
                            <div className="tp-caption  " id="slide-70-layer-3" data-x={100} data-y="center" data-voffset data-width="['auto']" data-height="['auto']" data-type="row" data-columnbreak={3} data-responsive_offset="on" data-responsive="off" data-frames="[{&quot;delay&quot;:10,&quot;speed&quot;:300,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 5, whiteSpace: 'nowrap', fontSize: '20px', lineHeight: '22px', fontWeight: 400, color: '#ffffff', letterSpacing: '0px'}}>
                            {/* LAYER NR. 2 */}
                            <div className="tp-caption  " id="slide-70-layer-4" data-x={100} data-y={100} data-width="['auto']" data-height="['auto']" data-type="column" data-responsive_offset="on" data-frames="[{&quot;delay&quot;:&quot;+0&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-columnwidth="16.67%" data-verticalalign="top" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 6, width: '100%'}} />
                            {/* LAYER NR. 3 */}
                            <div className="tp-caption  " id="slide-70-layer-5" data-x={100} data-y={100} data-width="['auto']" data-height="['auto']" data-type="column" data-responsive_offset="on" data-responsive="off" data-frames="[{&quot;delay&quot;:&quot;+0&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-columnwidth="66.67%" data-verticalalign="top" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textalign="['center','center','center','center']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 7, width: '100%'}}>
                                {/* LAYER NR. 4 */}
                                <div className="tp-caption   tp-resizeme" id="slide-70-layer-2" data-x data-y data-width="['auto']" data-height="['auto']" data-type="text" data-responsive_offset="on" data-fontsize="['50', '45', '40', '32']" data-lineheight="['64', '55', '43', '38']" data-frames="[{&quot;delay&quot;:&quot;+0&quot;,&quot;split&quot;:&quot;chars&quot;,&quot;splitdelay&quot;:0.1,&quot;speed&quot;:500,&quot;split_direction&quot;:&quot;forward&quot;,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;sX:0.8;sY:0.8;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power4.easeOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;auto:auto;&quot;,&quot;ease&quot;:&quot;Power2.easeIn&quot;}]" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[25,25,25,25]" data-marginleft="[0,0,0,0]" data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 8, whiteSpace: 'normal', fontSize: '50px', lineHeight: '64px', fontWeight: 600, color: '#ffffff', letterSpacing: '0px', display: 'block'}}>
                                {title}
                                </div>
                                {/* LAYER NR. 5 */}
                                <p className="tp-caption   tp-resizeme" id="slide-70-layer-7" data-x data-y data-width="['auto']" data-height="['auto']" data-type="text" data-responsive_offset="on" data-fontsize="['20', '20', '18', '16']" data-lineheight="['36', '36', '24', '22']" data-frames="[{&quot;delay&quot;:&quot;+3730&quot;,&quot;speed&quot;:1000,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:200px;skX:-85px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power2.easeOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[40,40,40,40]" data-marginleft="[0,0,0,0]" data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 9, whiteSpace: 'normal', fontSize: '20px', lineHeight: '36px', fontWeight: 400, color: '#ffffff', letterSpacing: '0px', display: 'block'}}>
                                {tagline}</p>
                                {/* LAYER NR. 6 */}
                                <div className="tp-caption" id="slide-70-layer-8" data-x data-y data-width="['auto']" data-height="['auto']" data-type="text" data-responsive_offset="on" data-frames="[{&quot;delay&quot;:&quot;+4830&quot;,&quot;speed&quot;:580,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 10, whiteSpace: 'normal', display: 'inline-block'}}>
                                <NavLink to="/at_demo" onClick={noAction} className="btn btn-secondary">Explore More</NavLink></div>
                            </div>
                            {/* LAYER NR. 7 */}
                            <div className="tp-caption  " id="slide-70-layer-6" data-x={100} data-y={100} data-width="['auto']" data-height="['auto']" data-type="column" data-responsive_offset="on" data-frames="[{&quot;delay&quot;:&quot;+0&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-columnwidth="16.67%" data-verticalalign="top" data-margintop="[0,0,0,0]" data-marginright="[0,0,0,0]" data-marginbottom="[0,0,0,0]" data-marginleft="[0,0,0,0]" data-textalign="['inherit','inherit','inherit','inherit']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{zIndex: 11, width: '100%'}} />
                            </div>
                        </div>
                        </li>
                        )
                    })
                }
              </ul>
              <div className="tp-bannertimer tp-bottom" style={{visibility: 'hidden !important'}} />
            </div>
          </div>{/* END REVOLUTION SLIDER */}
        </Fragment>
      );   
}
const mapStateToProps = state => {
    return {
        slider : state.slider
    }
}

export default connect(mapStateToProps)(Revolution_hero);


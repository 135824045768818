import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import Cta1 from '../../content/cta/cta-1';

const Parallax = (props) => {
      return (
        <Fragment>

            <Header pageTitle="Parallax" />
            <Cta1 />
            <Footer />
        </Fragment>
      );
}

export default Parallax
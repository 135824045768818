import React, { Fragment, Component } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import TwitterFeeds from '../../container/testimonial/twitter-feeds';
import Testimonial2 from '../../container/testimonial/testimonial-2';
import Testimonial3 from '../../container/testimonial/testimonial-wraper-4';
import Testimonial4 from '../../container/testimonial/testimonial-5';
import Testimonial5 from '../../container/testimonial/testimonial-6';
import Testimonial6 from '../../container/testimonial/testimonial-carousel-one';
import Testimonial7 from '../../container/testimonial/testimonials-3';


class TestimonialStyle extends Component {
    render() {
      return (
        <Fragment>

            <Header pageTitle="Testimonial Styles" />

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Testimonial - 1</h3>
                        </div>
                    </div>
                </div>
            </div>
            <TwitterFeeds />
            {/* Testimonial - 1 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Testimonial - 2</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Testimonial2 />
            {/* Testimonial - 2 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Testimonial - 3</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Testimonial3 />
            {/* Testimonial - 3 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Testimonial - 4</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Testimonial4 />
            {/* Testimonial - 4 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Testimonial - 5</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Testimonial5 />
            {/* Testimonial - 5 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Testimonial - 6</h3>
                        </div>
                    </div>
                </div>
            </div>
            <Testimonial6 />
            {/* Testimonial - 6 */}

            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="showcase showcase--title1">
                            <h3>Testimonial - 7</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="m-bottom-65">
                <Testimonial7 />
            </div>
            {/* Testimonial - 7 */}

            <Footer />
        </Fragment>
      )
    }
}
export default TestimonialStyle

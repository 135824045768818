import React from 'react';
import {NavLink} from 'react-router-dom';
const noAction = (e) => {
    e.preventDefault();
}
const Team_Wrap = (props) => {
    const {img, name, designation, id} = props;
    return (
        <div className="carousel-single">
            <div className="card card--team2">
                <figure>
                    <img src={img} alt="" />
                    <figcaption>
                        <ul className="team-social d-flex justify-content-center">
                            <li><NavLink to="/" onClick={noAction}><i className="fab fa-google-plus-g"></i></NavLink></li>
                            <li><NavLink to="/" onClick={noAction}><i className="fab fa-facebook-f"></i></NavLink></li>
                            <li><NavLink to="/" onClick={noAction}><i className="fab fa-twitter"></i></NavLink></li>
                            <li><NavLink to="/" onClick={noAction}><i className="fab fa-linkedin-in"></i></NavLink></li>
                        </ul>
                        <NavLink to={"/team-details"+id} className="btn btn-sm btn-outline-light">View Profile</NavLink>
                    </figcaption>
                </figure>
                <div className="card-body bg-primary">
                    <a href="team-single.html">
                        <h6>{name}</h6>
                    </a>
                    <span>{designation}</span>
                </div>
            </div>{/*<!-- End: .card -->*/}
        </div>
    )
}
export default Team_Wrap; 
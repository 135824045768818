import React, {Fragment} from 'react';
import Navbar from '../navbar/colorNav';
import TopHeader from '../header/topHeader';
const noAction = e => e.preventDefault();
const Content_height = () => {      
    return (
        <Fragment>
            <section className="header header--2">
               <TopHeader />
                <Navbar />                
            </section>
            {/* <!-- ends: .intro-area --> */}
            <div className="intro-area-four">
                <div className="img-left"><img src="./assets/img/s_left.png" alt="" /></div>
                <div className="img-right"><img src="./assets/img/s_right.png" alt="" /></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 col-md-10 offset-md-1">
                            <div className="intro-area-four--contents">
                                <h1>Build a better <br /> <span>Business Idea</span></h1>
                                <p>Deserunt dolore voluptatem assumenda quae possimus sunt <br /> dignissimos tempora officia lorem ipsum dolor.</p>
                                <form action="/" className="intro-form">
                                    <div className="input-group input-group-light">
                                        <span className="icon-left" id="basic-addon7"><i className="la la-envelope"></i></span>
                                        <input type="text" className="form-control" placeholder="Enter your email" aria-label="Username" />
                                    </div>
                                    <div><button onClick={noAction} type="submit" className="btn btn-primary">Get Started</button></div>
                                </form>
                                <img src="./assets/img/s_bottom.png" alt="" />
                            </div>
                        </div>{/*<!-- ends: .col -->*/}
                    </div>
                </div>
            </div>{/*<!-- ends: .intro-area-four -->*/}
        </Fragment>       
                      
    )   
}


export default Content_height;




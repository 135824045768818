import React, {Fragment} from 'react';
import {NavLink} from 'react-router-dom';
const noAction = (e) => {
    e.preventDefault();
}
const FooterItem = (props) => {
    return (
        <Fragment>
            <div className="col-lg-3 col-md-6 col-sm-6 d-flex justify-content-lg-center">
                <div className="widget widget--links">
                    <h4 className={ props.color !== "black" ? "widget__title2" : "widget__title" }>Company</h4>
                    <ul className="links">
                        {/*<li><NavLink to="/about" >About Us</NavLink></li>*/}
                        <li><NavLink to="/contact-us" >Contact Us</NavLink></li>
                        {/*<li><NavLink to="/" >Service</NavLink></li>
                        <li><NavLink to="/careers" >Careers</NavLink></li>
                        <li><NavLink to="/team-list" >Our Team</NavLink></li>
                        <li><NavLink to="/notfound" >Page 404</NavLink></li>*/}
                    </ul>
                </div>{/*<!-- ends: .widget -->*/}
            </div>{/*<!-- ends: .col-lg-3 -->*/}
            <div className="col-lg-3 col-md-6 col-sm-6 d-flex justify-content-lg-center">
                <div className="widget widget--links">
                    <h4 className={ props.color !== "black" ? "widget__title2" : "widget__title" }>Services</h4>
                    <ul className="links">
                        <li><NavLink to="/service2" >Software</NavLink></li>
                        <li><NavLink to="/service-hardware" >Hardware</NavLink></li>
                        {/*<li><NavLink to="/" onClick={noAction}>Management</NavLink></li>
                        <li><NavLink to="/" onClick={noAction}>Accounting</NavLink></li>
                        <li><NavLink to="/" onClick={noAction}>Training</NavLink></li>*/}
                        <li><NavLink to="/service-consultation" >Consultation</NavLink></li>
                    </ul>
                </div>{/*<!-- ends: .widget -->*/}
            </div>{/*<!-- ends: .col-lg-3 -->*/}
            {
                props.color !== "black" ? (
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="widget widget--links">
                            <h4 className={ props.color !== "black" ? "widget__title2" : "widget__title" }>Useful Links</h4>
                            <ul className="links">
                                <li><NavLink to="/blog-grid">Blog</NavLink></li>
                                <li><NavLink to="/testimonial">Client Area</NavLink></li>
                                <li><NavLink to="/register">Register</NavLink></li>
                                <li><NavLink to="/faqs">FAQ's</NavLink></li>
                                <li><NavLink to="/pricing">Pricing</NavLink></li>
                                <li><NavLink to="/event-list">Events</NavLink></li>
                            </ul>
                        </div>{/*<!-- ends: .widget -->*/}
                    </div>
                ) : props.news === 'white' ? (
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="widget twit--widget">
                            <h4 className="widget__title">Latest Tweets</h4>
                            <div className="twit_wrapper d-flex">
                                <span className="fab fa-twitter"></span>
                                <div>
                                    <p>Praesent is pharetra justo ught scel erihy sque the mattis lhreo justo quam.</p>
                                    <NavLink to="/demo" onClick={noAction} >https://t.co/rI1M2JlyZG</NavLink>
                                    <NavLink to="/demo" onClick={noAction} >#business #consulting #finace</NavLink>
                                    <div className="user">
                                        <span className="name">@Aazztech</span>
                                        <span>April 28, 2019</span>
                                    </div>
                                </div>
                            </div>
                        </div>{/*<!-- ends: .widget -->*/}
                    </div>
                )
                : (
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <div className="widget subcribe--widget">
                            <h4 className="widget__title">Newsletter</h4>
                            <p>Subscribe to get update and information. Don't worry, we won't send spam!</p>
                            <form className="subscribe_form">
                                <div className="input_with_embed">
                                    <input type="text" className="form-control-lg input--rounded border-0" placeholder="Enter email" />
                                    <div className="embed_icon">
                                        <span className="la la-envelope"></span>
                                    </div>
                                </div>
                            </form>
                            <div className="widget__social">
                                <div className="social  ">
                                    <ul className="d-flex flex-wrap">
                                        <li><a href="https://www.facebook.com/Ratri-Cloud-100615298388092/" target="_blank" className="facebook"><span className="fab fa-facebook-f"></span></a></li>
                                        {/*<li><NavLink to="/" onClick={noAction} className="twitter"><span className="fab fa-twitter"></span></NavLink></li>*/}
                                        <li><a href="https://www.instagram.com/ratri.cloud/" target="_blank" className="instagram"><span className="fab fa-instagram"></span></a></li>
                                        {/*<li><NavLink to="/" onClick={noAction} className="linkedin"><span className="fab fa-linkedin-in"></span></NavLink></li>*/}
                                        <li><NavLink to="/" onClick={noAction} className="gplus"><span className="fab fa-google-plus-g"></span></NavLink></li>
                                    </ul>
                                </div>{/*<!-- ends: .social -->*/}
                            </div>
                        </div>{/*<!-- ends: .widget -->*/}
                    </div>
                )
            }

        </Fragment>
    )
}

export default FooterItem;

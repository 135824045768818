import React, { Fragment } from 'react';
import Header from '../../layout/header/element-header';
import Footer from '../../layout/footer/footer-5';
import { NavLink } from 'react-router-dom';

const Maps = (props) => {

      return (
        <Fragment>

            <Header pageTitle="Modal" />
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="showcase showcase--title1">
                                <h3>Bootstrap Modals</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="p-bottom-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <ul className="modals-btn d-flex flex-wrap">
                                <li className="m-right-30 m-bottom-30"><NavLink to="/demo" className="btn btn-primary" data-toggle="modal" data-target="#modal-static">Static Modal</NavLink></li>
                                <li className="m-right-30 m-bottom-30"><NavLink to="/demo" className="btn btn-secondary" data-toggle="modal" data-target="#modal-lg">Large Modal</NavLink></li>
                                <li className="m-right-30 m-bottom-30"><NavLink to="/demo" className="btn btn-success" data-toggle="modal" data-target="#modal-sm">Small Modal</NavLink></li>
                                <li className="m-bottom-30"><NavLink to="/demo" className="btn btn-info" data-toggle="modal" data-target="#modal-center">Centered Modal</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
                </section>{/* ends: section */}
                <div className="modal fade" id="modal-static" tabIndex={-1} role="dialog" aria-labelledby="modal-staticLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="modal-staticLabel">Modal title</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Modal body text goes here</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary">Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="modal-lg" tabIndex={-1} role="dialog" aria-labelledby="modal-lgLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modal-lgLabel">Modal title</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>Modal body text goes here</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Save changes</button>
                        </div>
                    </div>
                </div>
                </div>{/* ends .modal */}
                <div className="modal fade" id="modal-sm" tabIndex={-1} role="dialog" aria-labelledby="modal-smLabel" aria-hidden="true">
                    <div className="modal-dialog modal-sm">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="modal-smLabel">Modal title</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Modal body text goes here</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary btn-sm">Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>{/* ends .modal */}
                <div className="modal fade" id="modal-center" tabIndex={-1} role="dialog" aria-labelledby="modal-centerLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="modal-centerLabel">Modal title</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>Modal body text goes here</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary">Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>{/* ends .modal */}
            </div>
            <Footer />
        </Fragment>
      );
}

export default Maps